import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

// ---------------------------------------
//        Goal Setting Form Imports
// ---------------------------------------
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
// Date-Pickers
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { deepPurple } from "@mui/material/colors";
import FlagCircleOutlinedIcon from "@mui/icons-material/FlagCircleOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutlined";

// Text-Area
// import TextField from "@mui/material/TextField";

function EmployeeView() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Grid item flex={8} sx={{ padding: "50px 50px" }}>
      <Stack direction="row" spacing={8}>
        <Box>
          <Typography variant="h5">My Appraisal</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Appraisal details for the current financial year
          </Typography>
        </Box>
        <FormControl
          sx={{
            width: 150,
          }}
          size="small"
        >
          <InputLabel id="demo-simple-select-label">Year</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            autoWidth
            onChange={handleChange}
          >
            <MenuItem value={10}>2023-2024</MenuItem>
            <MenuItem value={20}>2022-2023</MenuItem>
            <MenuItem value={30}>2021-2022</MenuItem>
          </Select>
        </FormControl>
        <Link component={RouterLink} to="/appraisal">
          <Button variant="contained">HR View</Button>
        </Link>
      </Stack>
      <Divider
        sx={{
          mb: 2,
        }}
      ></Divider>
      <Link
        sx={{
          textDecoration: "none",
        }}
        component={RouterLink}
        to={"/appraisal/employee-annual-process"}
      >
        <Paper
          sx={{
            py: 3,
            px: 2,
          }}
        >
          <Stack
            direction="row"
            spacing={8}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            sx={{
              width: "92%",
              mb: 3,
            }}
          >
            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                Appraisal Type
              </Typography>
              <Typography variant="body">Annual</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                Effective Start Date
              </Typography>
              <Typography variant="body">Apr 1, 2023</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                Effective End Date
              </Typography>
              <Typography variant="body">Mar 31, 2024</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                Role
              </Typography>
              <Typography variant="body">Developer</Typography>
            </Box>
          </Stack>
          <Stack
            direction="row"
            spacing={8}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            sx={{
              width: "92%",
              mb: 5,
            }}
          >
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Avatar
                sx={{
                  bgcolor: deepPurple[500],
                  width: 34,
                  height: 34,
                  fontSize: "16px",
                }}
              >
                Ti
              </Avatar>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  Manager
                </Typography>
                <Typography variant="body">Tiru</Typography>
              </Box>
            </Stack>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Avatar
                sx={{
                  bgcolor: deepPurple[500],
                  width: 34,
                  height: 34,
                  fontSize: "16px",
                }}
              >
                Ka
              </Avatar>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  Reviewer
                </Typography>
                <Typography variant="body">Karthik</Typography>
              </Box>
            </Stack>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Avatar
                sx={{
                  bgcolor: deepPurple[500],
                  width: 34,
                  height: 34,
                  fontSize: "16px",
                }}
              >
                Jo
              </Avatar>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  HR
                </Typography>
                <Typography variant="body">Jyothi</Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              mt: 8,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "30px",
              }}
            >
              <Paper
                variant="outlined"
                sx={{
                  p: 1,
                }}
              >
                <Stack direction="row" spacing={1}>
                  <IconButton
                    sx={{
                      height: 42,
                      width: 42,
                    }}
                  >
                    <FlagCircleOutlinedIcon
                      sx={{
                        fontSize: 26,
                      }}
                    />
                  </IconButton>
                  <Stack>
                    <Typography variant="h6">8</Typography>
                    <Typography variant="body" color="textSecondary">
                      Goal(s)
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      <ChatBubbleOutlinedIcon sx={{ fontSize: "12px" }} />
                      18 Comments
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
              <Paper
                variant="outlined"
                sx={{
                  p: 1,
                }}
              >
                <Stack direction="row" spacing={1}>
                  <IconButton
                    sx={{
                      height: 42,
                      width: 42,
                    }}
                  >
                    <PersonAddAltOutlinedIcon
                      sx={{
                        fontSize: 24,
                      }}
                    />
                  </IconButton>
                  <Stack>
                    <Typography variant="h6">7</Typography>
                    <Typography variant="body" color="textSecondary">
                      Attribute(s)
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      <ChatBubbleOutlinedIcon sx={{ fontSize: "12px" }} />
                      14 Comments
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Box>
            <Box>
              <Button variant="contained">VIEW GOAL SHEET</Button>
            </Box>
          </Stack>
        </Paper>
      </Link>
      {/* Annual Process Closed */}
      <div
        style={{
          marginBottom: "30px",
          height: "30px",
        }}
      ></div>
    </Grid>
  );
}

export default EmployeeView;

// HR View
