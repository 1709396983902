import React from "react";
import { Box, Button, Typography, Modal, Stack } from "@mui/material";

const ProjectDeleteModal = ({
  tableRowToEdit,
  openDeleteModal,
  handleSubmit,
  // setTableRowToEdit,
  handleDeleteModalClose,
}) => {
  return (
    <Modal
      open={openDeleteModal}
      onClose={handleDeleteModalClose}
      aria-labelledby="project-modal-title"
      aria-describedby="project-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "35%",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
          maxHeight: 500,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          fontWeight={"bold"}
        >
          Delete
        </Typography>
        <Typography>Are you sure want to delete?</Typography>
        <form onSubmit={(e) => handleSubmit(e, tableRowToEdit.id)}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <Button onClick={handleDeleteModalClose} variant="outlined">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#ff5630",
              }}
            >
              Delete
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default ProjectDeleteModal;
