import React, { useEffect, useState, useContext } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { certificationsBaseUrl } from "../../../apiCalls/apiCalls";
import BasicModal from "./components/BasicModal";
import { TokenContext } from "../../protectedLayout";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ToastContainer, toast } from "react-toastify";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </Box>
      <Box>
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
}

function AddAndDeleteButtons({ handleOpen, deleteCertifications }) {
  return (
    <Grid >
    <Button variant="contained" onClick={handleOpen} sx={{ mr: 2 }}>
      {" "}
      Add <AddOutlinedIcon fontSize="small" />
    </Button>
    <Button variant="contained" onClick={deleteCertifications}>
      {" "}
      Delete <DeleteOutlinedIcon fontSize="small" />
    </Button>
  </Grid>
  );
}

function Certifications() {
  axios.defaults.withCredentials = true;
  const token = useContext(TokenContext);
  const [employeesData, setEmployeesData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const handleOpen = () => setOpen(true);

  const getRowId = (row) => row._id;
  const columns = [
    {
      field: "employeeId",
      headerName: "Employee Id",
      sortable: false,
      editable: false,
      minWidth: 190,
      maxWidth: 190,
      cellClassName: "employee-id-cell",
    },
    {
      field: "employeeName",
      headerName: "Employee Name",
      sortable: false,
      editable: false,
      minWidth: 190,
      maxWidth: 190,
      cellClassName: "employee-name-cell",
    },
    {
      field: "certificationName",
      headerName: "Certification Name",
      sortable: false,
      editable: false,
      minWidth: 190,
      maxWidth: 190,
      cellClassName: "certification-name-cell",
    },
    {
      field: "certificationId",
      headerName: "Certification ID",
      sortable: false,
      editable: false,
      minWidth: 190,
      maxWidth: 190,
      cellClassName: "certification-id-cell",
    },
    {
      field: "issuedBy",
      headerName: "Issued By",
      sortable: false,
      editable: false,
      minWidth: 235,
      maxWidth: 235,
      cellClassName: "issued-by-cell",
    },
  ];

  const deleteCertifications = async (ids) => {
     // Replace with your actual token
    try {
      const response = await axios.delete(
        `${certificationsBaseUrl}/deleteAllCertification`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
             'Content-Type': 'application/json'
          },
          data: {
            ids : selectionModel
          }
        }
      );
      toast.success("deleted successfully")
      setEmployeesData(response.data.certifications);
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error deleting certifications:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${certificationsBaseUrl}/getAllCertfications`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEmployeesData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
 
   console.log(selectionModel)
  return (
    <Grid
      flex={8}
      container
      sx={{
        p: 1,
        flexDirection: "column",
      }}
    >
      <BasicModal
        open={open}
        setOpen={setOpen}
        setEmployeesData={setEmployeesData}
      />
      <Grid
        item
        container
        sx={{
          pl: 3,
          pr: 4,
          justifyContent: "space-between",
          flexDirection: "row",
          // alignItems: "center",
        }}
      >
        <Typography variant="h5">Certifications</Typography>
        <AddAndDeleteButtons handleOpen={handleOpen} deleteCertifications={deleteCertifications}/>
      </Grid>

      {employeesData.length > 0 ? (
        <Grid sx={{ marginTop: "3vh", width: "100%", pl: 3, pr: 4 }}>
          <DataGrid
            rows={employeesData}
            getRowId={getRowId}
            columns={columns}
            disableColumnResize={true}
            checkboxSelection
            onRowSelectionModelChange={(selectionModel) => {
              setSelectionModel(selectionModel);
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            sx={{
              backgroundColor: "#fff",
            }}
            pageSizeOptions={[5]}
            slots={{ toolbar: CustomToolbar }}
            disableRowSelectionOnClick
          />
        </Grid>
      ) : (
        <Grid
          container
          item
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "550",
            padding: "10px",
          }}
        >
          <Typography variant="body" sx={{ width: "200px" }}>
            No Certifications yet
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default Certifications;
