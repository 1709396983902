import React, { useState } from "react";
import "./HiringTracker.css";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link } from "react-router-dom";

function HiringTracker() {
  const [columns] = useState([
    { field: "id", headerName: "S.No.", width: 70 },
    { field: "fName", headerName: "First Name", width: 180 },
    { field: "lName", headerName: "Last Name", width: 180 },
    { field: "phoneNumber", headerName: "Phone Number", width: 225 },
    { field: "email", headerName: "Email", width: 225 },
    {
      field: "actions",
      headerName: "Actions",
      width: 118,
      renderCell: (params) => (
        <Link to={`/reports/candidate-details/${params.row.id}`}>
          <Stack direction={"row"} gap={0.2} alignItems={"center"}>
            more info
            <IconButton
              aria-label="edit"
              size="small"
              sx={{
                height: "23px",
                width: "23px",
              }}
              // onClick={(e) => handleOpenEditModal(params.row)}
            >
              <ArrowOutwardIcon sx={{ fontSize: "19px" }} />
            </IconButton>
          </Stack>
        </Link>
      ),
    },
  ]);

  const [rows] = useState([
    {
      id: 1,
      fName: "Srinivas",
      lName: "Ch",
      phoneNumber: "999999999",
      email: "Srinivas@gmail.com",
    },
    {
      id: 2,
      fName: "Sreeshta",
      lName: "T",
      phoneNumber: "989989999",
      email: "Sreeshta@gmail.com",
    },
    {
      id: 3,
      fName: "Vineeth",
      lName: "S",
      phoneNumber: "993997999",
      email: "Vineeth@gmail.com",
    },
    {
      id: 4,
      fName: "Jagadeesh",
      lName: "Talari",
      phoneNumber: "889999977",
      email: "Jagadeesh@gmail.com",
    },
    {
      id: 5,
      fName: "Praveen",
      lName: "N.A.",
      phoneNumber: "919293999",
      email: "Praveen@gmail.com",
    },
    {
      id: 6,
      fName: "Pranay",
      lName: "N.A.",
      phoneNumber: "919295809",
      email: "Pranay@gmail.com",
    },
  ]);
  return (
    <Grid
      flex={8}
      item
      container
      xs={10}
      sx={{ p: 1, flexDirection: "column", my: 6, px: 5 }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 3,
        }}
      >
        Hiring Tracker
      </Typography>

      <DataTable rows={rows} columns={columns} />
    </Grid>
  );
}

//  -----------------------------------------------
//                    Data grid table
//  -----------------------------------------------

function DataTable({ rows, columns }) {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnResize={true}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        style={{
          width: 980,
          backgroundColor: "#fff",
        }}
        // checkboxSelection
      />
    </div>
  );
}

export default HiringTracker;
