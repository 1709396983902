import axios from "axios";
import Cookies from "js-cookie";
const host = process.env.REACT_APP_ORGX_HOST;

const feedbackApiUrl = `${host}/api/v1/employee/feedback`;
const loginApiUrl = `${host}/api/v1/employee/login`;
const forgetPassUrl = `${host}/api/v1/employee/sendEmail`;
const randomQuoteUrl = `${host}/api/v1/employee/getquote`;
const submitEmployeeDetailsUrl = `${host}/api/v1/employee/upload`;
const postRecruitmentUrl = `${host}/api/v1/employee/postrecruit`;
const getRecruitmentUrl = `${host}/api/v1/employee/recruit-details`;
const getAllEmployeesDataUrl = `${host}/api/v1/employee`;
const getEmployeeDetailsUrl = `${host}/api/v1/employee/`;
const getFeedbackUrl = `${host}/api/v1/employee/feedback/data`;

const getJobSeekerUrl = `${host}/api/v1/employee/recruit/`;
const updateRecruitUrl = `${host}/api/v1/employee/updaterecruit/`;
const createBlogApiUrl = `${host}/api/v1/employee/blogs/`;
const saveBlogsApiUrl = `${host}/api/v1/employee/blogs/savedblogs/`;
const publishBlogApiUrl = `${host}/api/v1/employee/publishBlog`;
const likesApiUrl = `${host}/api/v1/employee/blog/likes`;
const saveBLogApiUrl = `${host}/api/v1/employee/saveblog`;
const getSavedBlogsApiUrl = `${host}/api/v1/employee/usersaved`;
const commentsApiUrl = `${host}/api/v1/employee/comments`;
const getBlogsApiUrl = `${host}/api/v1/employee/blogs/filter/?category=`;
const getBlogViewUrl = `${host}/api/v1/employee/blogs/`;
const createJobOpening = `${host}/api/jobPosting/`;

export const postJobOpening = async (jobPosting) => {
  return await axios.post(createJobOpening, jobPosting);
};

// URLS
export const jobApplicantBaseUrl = `${host}/api/jobApplicant/`;
export const jobApplicantPublicBaseUrl = `${host}/api/jobApplicant`;
export const jobPostingBaseUrl = `${host}/api/jobPosting/`;
export const recruitmentDetailsBaseUrl = `${host}/api/recruitmentDetails/`;
export const getJobApplicantswithJobName = `${host}/api/jobApplicant/completeApplicantDetails`;
export const scheduleInterviewsBaseUrl = `${host}/api/scheduleInterview/`;
export const CandidateInterviewRoundFeedback = `${host}/api/scheduleInterview/candidate-details`;

export const masterDataBaseUrl = `${host}/api/masterData`;
export const certificationsBaseUrl = `${host}/api/certifications`;
export const fetchAllEmployeeDetials = `${host}/api/v1/employee/`;
export const projectsBaseUrl = `${host}/api/projects`;
export const resumeBaseUrl = `${host}/api/resume`

export const submitEmployeeDetails = async (employeeDetails) => {
  return await axios.post(submitEmployeeDetailsUrl, employeeDetails);
};
export const postFeedbackApi = async (data) => {
  return await axios.post(feedbackApiUrl, data);
};
export const sendOtpApi = async (email) => {
  return await axios.post(forgetPassUrl, { email });
};
export const loginValidation = async (loginDetails) => {
  return await axios.post(loginApiUrl, loginDetails);
};
export const getRandomQuote = async () => {
  return await axios.get(randomQuoteUrl);
};
export const postRecruitmentDetails = async (recruitmentObj) => {
  return await axios.post(postRecruitmentUrl, recruitmentObj);
};
export const getRecruitmentDetails = async () => {
  return await axios.get(getRecruitmentUrl);
};
export const getFeedbackDetails = async () => {
  return await axios.get(getFeedbackUrl);
};
export const getAllEmployeesData = async () => {
  return await axios.get(getAllEmployeesDataUrl);
};
export const getEmployeeDetails = async (id) => {
  return await axios.get(`${getEmployeeDetailsUrl}${id}`);
};
export const getJobSeekerDetails = async (id) => {
  return await axios.get(`${getJobSeekerUrl}${id}`);
};
export const submitRecruiterFeedback = async (id, recruitFeedback) => {
  return await axios.put(`${updateRecruitUrl}${id}`, recruitFeedback);
};

/* BLOGS API */

export const createBlogApi = async (blogDetails) => {
  console.log(blogDetails, "in api call");
  const token = Cookies.get("jwtToken");
  const options = {
    method: "post",
    url: createBlogApiUrl,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: blogDetails,
  };
  return await axios(options);
};
export const getBlogsApi = async (category) => {
  return await axios.get(`${getBlogsApiUrl}${category}`);
};
export const getBlogViewApi = async (id) => {
  return await axios.get(`${getBlogViewUrl}${id}`);
};
export const saveBlogsApi = async (saveDetails) => {
  return await axios.post(saveBlogsApiUrl, saveDetails);
};

//COMMENTS API
export const commentsApi = async (commentObject) => {
  const token = Cookies.get("jwtToken");
  const config = {
    method: "post",
    url: commentsApiUrl,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: commentObject,
  };
  return await axios(config);
};

//PUBLISHING BLOG
export const publishBlogApi = async (content) => {
  return await axios.post(publishBlogApiUrl, content);
};

// LIKES API
export const likesApi = async (id) => {
  return await axios.put(likesApiUrl, id);
};

//GET SAVED BLOGS API
export const getSavedBlogsApi = async () => {
  const token = Cookies.get("jwtToken");
  const config = {
    method: "get",
    url: getSavedBlogsApiUrl,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios(config);
};

//SAVING A BLOG API
export const saveBlogApi = async (_id) => {
  const token = Cookies.get("jwtToken");
  const config = {
    method: "post",
    url: saveBLogApiUrl,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { _id },
  };
  return await axios(config);
};

//REMOVE BLOG FROM SAVED ARRAY API
export const removeSaveBlogApi = async (_id) => {
  const token = Cookies.get("jwtToken");
  const config = {
    method: "put",
    url: saveBLogApiUrl,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { _id },
  };
  return await axios(config);
};
