import React from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  Drawer,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Rating,
  Button,
  IconButton,
  Stack,
  Divider,
  Chip,
  Modal,
} from "@mui/material";
function CandidateDetails({ data }) {
  return (
    <Box p={3} width={"100%"}>
      <Typography sx={{ fontWeight: 600 }}>PERSONAL DETAILS</Typography>

      {/* First Name */}
      <Grid container rowSpacing={3} mt={2}>
        <Grid item xs={4}>
          <Stack>
            <Typography sx={{ color: "grey" }}>First Name</Typography>
            <Typography sx={{ fontWeight: 600 }}>{data.firstName}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Typography sx={{ color: "grey" }}>Middle Name</Typography>
            <Typography sx={{ fontWeight: 600 }}>N/A</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Typography sx={{ color: "grey" }}>Last Name</Typography>
            <Typography sx={{ fontWeight: 600 }}>{data.lastName}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Typography sx={{ color: "grey" }}>Gender</Typography>
            <Typography sx={{ fontWeight: 600 }}>
              {data.gender ? data.gender : "N/A"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Typography sx={{ color: "grey" }}>Email</Typography>
            <Typography sx={{ fontWeight: 600 }}>{data.email}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Typography sx={{ color: "grey" }}>Phone Number</Typography>
            <Typography sx={{ fontWeight: 600 }}>{data.phoneNumber}</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack>
            <Typography sx={{ color: "grey" }}>Technical Skills</Typography>
            {/* Apply flexWrap to allow chips to wrap */}
            <Stack direction={"row"} flexWrap="wrap" gap={2} mt={2}>
              {data?.technicalSkills.length > 0 ? (
                data?.technicalSkills.map((e, data) => (
                  <Chip
                    key={data}
                    label={e}
                    sx={{ borderRadius: 0, fontWeight: 600 }}
                  ></Chip>
                ))
              ) : (
                <Typography sx={{ fontWeight: 600 }}>N/A</Typography>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CandidateDetails;
