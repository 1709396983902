import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Box,
  Stack,
  TextField,
  Typography,
  FormLabel,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  jobPostingBaseUrl,
  jobApplicantPublicBaseUrl,
  resumeBaseUrl,
  jobApplicantBaseUrl,
} from "../../apiCalls/apiCalls";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DoneIcon from "@mui/icons-material/Done";
import ApplicationResult from "./ApplicationResult";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TokenContext } from "../protectedLayout";
// axios.defaults.withCredentials = true;
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const ApplyForm = () => {
  // const token = useContext(TokenContext);
  const navigate = useNavigate();
  let { id: jobId } = useParams();
  const [capchaValue, setCapchaValue] = useState("");
  // let [jobTitle, setJobTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileConfirmed, setFileConfirmed] = useState(false);
  // const [resume, setResume] = useState(null);
  const [resumeError, setResumeError] = useState("");
  const [applySuccess, setApplySuccess] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const pdfUrl =
    "https://orgxuat.blob.core.windows.net/org-x-blob-container/JohnNameResume_1719566295363.pdf?sv=2022-11-02&ss=bfqt&srt=o&sp=rwdlacupiytfx&se=2024-07-04T15:21:49Z&st=2024-06-27T07:21:49Z&spr=https&sig=8oKz5Igt8emtNDaax%2B5txFmbOHtWtVTqmRRTMcHDK94%3D";

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setFileConfirmed(false);
  };

  const handleUpload = () => {
    if (selectedFile) {
      // onFileSelect(selectedFile);
      console.log(selectedFile);
      setFileName(selectedFile.name);
      setFileConfirmed(true);
      setResumeError(false);
    } else {
      alert("Please select a file");
    }
  };

  // For Technical skills
  // const handleTechnicalDelete = (skillToDelete) => {
  //   setTSkillTempSelect((prevSkills) =>
  //     prevSkills.filter((skill) => skill !== skillToDelete)
  //   );
  //   // setErrorMessage("");
  // };
  // const handleTechSkillSelect = (event, newValue) => {
  //   // setBtnState(false);

  //   setTSkillTempSelect(newValue);
  // };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  // const handleCapchaModalClose = () => {
  //   if (capchaValue) {
  //     setOpenCapchaModal(false);
  //   }
  // };

  function onCapchaClick(value) {
    console.log("Captcha value:", value);
    setCapchaValue(value);
    // if (value) {
    //   setOpenCapchaModal(false);
    // }
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  async function handleFormSubmit(values) {
    setDisableSubmit(true);
    // console.log(values);
    if (!selectedFile || !fileConfirmed) {
      setResumeError(true);
      return false;
    }

    // const base64Resume = await convertToBase64(selectedFile);
    let applicant = {};
    applicant.firstName = capitalizeFirstLetter(values.firstName);
    applicant.lastName = capitalizeFirstLetter(values.lastName);
    // applicant["resume"] = base64Resume;
    applicant["jobPostingId"] = jobId;
    applicant["source"] = "selfApply";
    applicant["status"] = "yetToInitiate";
    applicant["phoneNumber"] = values.phoneNumber;
    applicant["email"] = values.email;

    if (values.gender) {
      applicant["gender"] = values.gender;
    }

    let formData = new FormData();
    formData.append("resume", selectedFile);

    try {
      const response = await axios.post(
        `${jobApplicantPublicBaseUrl}?public=true`,
        applicant
      );

      // if applicant created successfully
      if (response.status === 201) {
        // upload resume
        const metadataRes = await axios.post(
          `${resumeBaseUrl}/${response.data._id}?public=true`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Content-Disposition": `attachment; filename=${fileName}`,
              "x-file-caption": "resume", // Optional header for caption
            },
          }
        );

        // if resume uploaded successfully
        if (metadataRes.status === 201) {
          console.log("metadata", metadataRes.data);
          // update user details with resume
          const updatedRes = await axios.put(
            `${jobApplicantBaseUrl}${response.data._id}?public=true`,
            {
              resume: metadataRes.data,
            }
          );
          console.log("updated", updatedRes.data);
        }
      }

      console.log("Posted successfully", response);

      setApplySuccess("success");
      setDisableSubmit(false);
    } catch (error) {
      console.log("Application failed", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Applicant already exists"
      ) {
        console.log("Application failed", error);
        setApplySuccess("alreadyExists");
      } else {
        setApplySuccess("fail");
        setDisableSubmit(false);
      }
    }
  }

  // fetch data of this job
  const fetchJobData = async () => {
    try {
      const res = await axios.get(`${jobPostingBaseUrl}${jobId}?public=true`);
      if (res.data === null) {
        navigate("/not-found");
      }
    } catch (error) {
      console.error("Error fetching job data: ", error);
    }
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First Name is required")
      .max(20, "max 20 characters allowed")
      .matches(/^[A-Za-z]+(?:\s[A-Za-z]+)*$/, "Only alphabets allowed"),
    lastName: Yup.string()
      .required("Last Name is required")
      .max(20, "max 20 characters allowed")
      .matches(/^[A-Za-z]+(?:\s[A-Za-z]+)*$/, "Only alphabets allowed"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email"
      ),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .matches(/^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/, "Invalid phone number"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      gender: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      handleFormSubmit(values);
    },
  });

  const labelStyling = {
    "& .MuiFormLabel-asterisk": {
      color: "#cd4949",
    },
    fontSize: "12px",
    fontWeight: "bold",
    color: "black",
  };

  const textFieldStyling = {
    fontSize: "12.5px",
    mt: 0.5,
  };

  useEffect(() => {
    setSelectedFile(null);
    setFileConfirmed(false);
    fetchJobData();
  }, []);

  return (
    <>
      {/* {jobTitle && ( */}
      <Box width="100%" sx={{ px: 4, py: 2 }}>
        {!applySuccess ? (
          <form onSubmit={formik.handleSubmit}>
            <Stack
              direction={"row"}
              gap={2}
              alignItems={"center"}
              px={15}
              mt={7}
            >
              <IconButton
                aria-label="back"
                size="small"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon sx={{ fontSize: "20px" }} />
              </IconButton>
              <Typography
                paragraph={true}
                // gutterBottom={false}
                // marginBottom={2}
                sx={{
                  fontSize: 28,
                  fontWeight: 600,
                }}
              >
                {/* {jobTitle} &gt; Apply */}
                Apply
              </Typography>
            </Stack>
            <Stack gap={2} py={3} px={20}>
              {/* FN */}
              <Box>
                <FormLabel required htmlFor="firstName" sx={labelStyling}>
                  FIRST NAME
                </FormLabel>
                <TextField
                  fullWidth
                  autoComplete="given-name"
                  id="firstName"
                  InputProps={{
                    sx: textFieldStyling,
                    placeholder: "First Name",
                  }}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Box>
              {/* LN */}
              <Box>
                <FormLabel required htmlFor="lastName" sx={labelStyling}>
                  LAST NAME
                </FormLabel>
                <TextField
                  fullWidth
                  autoComplete="family-name"
                  id="lastName"
                  InputProps={{
                    sx: textFieldStyling,
                    placeholder: "Last Name",
                  }}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Box>
              {/* EMAIL */}
              <Box>
                <FormLabel required htmlFor="email" sx={labelStyling}>
                  EMAIL
                </FormLabel>
                <TextField
                  fullWidth
                  autoComplete="email"
                  id="email"
                  name="email"
                  InputProps={{
                    sx: textFieldStyling,
                    placeholder: "Email",
                  }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              {/* PN */}
              <Box>
                <FormLabel required htmlFor="phoneNumber" sx={labelStyling}>
                  PHONE NUMBER
                </FormLabel>
                <TextField
                  fullWidth
                  autoComplete="tel-local"
                  id="phoneNumber"
                  name="phoneNumber"
                  InputProps={{
                    sx: textFieldStyling,
                    placeholder: "Phone Number",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography fontSize={"12.5px"}>+91</Typography>
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
              </Box>
              {/* GENDER */}
              <Box>
                {/* <FormLabel
                  htmlFor="gender"
                  sx={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
                >
                  GENDER
                </FormLabel> */}
                <FormControl fullWidth mt={0.5}>
                  <InputLabel
                    id="gender-label"
                    size="small"
                    sx={{
                      ".MuiInputLabel-sizeSmall": {
                        fontSize: "5px",
                      },
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    labelId="gender-label"
                    // fontSize="12.5px"
                    id="gender"
                    name="gender"
                    size="small"
                    sx={{
                      fontSize: "14px",
                      // mt: 0.5,
                      p: 0.8,
                    }}
                    value={formik.values.gender}
                    label="Gender"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    helperText={formik.touched.gender && formik.errors.gender}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {/* RESUME */}
              <Stack my={2}>
                <Stack direction={"row"} gap={3}>
                  {selectedFile === null || !fileConfirmed ? (
                    <Box>
                      <input
                        type="file"
                        accept=".pdf"
                        style={{ display: "none" }}
                        name="resume"
                        id="fileInput"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="fileInput">
                        <Button
                          variant="outlined"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload Resume
                        </Button>
                      </label>
                    </Box>
                  ) : (
                    <Button
                      variant="outlined"
                      component="span"
                      endIcon={<DoneIcon />}
                    >
                      Resume Uploaded
                    </Button>
                  )}
                  {selectedFile !== null && fileConfirmed ? (
                    <Button
                      variant="contained"
                      sx={{
                        width: "fit-content",
                        fontSize: "11px",
                        p: 1,
                      }}
                      onClick={() => {
                        setFileConfirmed(false);
                        setSelectedFile(false);
                        setFileName("");
                        setResumeError(true);
                      }}
                      disabled={!selectedFile}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        width: "fit-content",
                        fontSize: "11px",
                        p: 1,
                      }}
                      onClick={handleUpload}
                      disabled={!selectedFile}
                    >
                      Confirm
                    </Button>
                  )}
                </Stack>
                {fileName && (
                  <Typography
                    variant="caption"
                    component={"span"}
                    display="block"
                    mt={1}
                    sx={{ color: "green" }}
                  >
                    {fileName}
                  </Typography>
                )}
                {resumeError && (
                  <Typography
                    variant="caption"
                    component={"span"}
                    display="block"
                    mt={1}
                    sx={{ color: "#cd4949" }}
                  >
                    Please upload your resume
                  </Typography>
                )}
              </Stack>
              <ReCAPTCHA
                sitekey="6LfNB88pAAAAAPdpc05xsEbz6TTR3TS4MQwM-fsU"
                onChange={onCapchaClick}
              />
              <Button
                variant="contained"
                sx={{
                  width: "fit-content",
                }}
                type="submit"
                disabled={
                  !capchaValue ||
                  !formik.values.firstName ||
                  !formik.values.lastName ||
                  !formik.values.phoneNumber ||
                  !formik.values.email ||
                  !(selectedFile && fileConfirmed) ||
                  disableSubmit
                }
                onClick={() => {
                  if (!selectedFile || !fileConfirmed) {
                    setResumeError(true);
                  }
                }}
              >
                APPLY
              </Button>
            </Stack>
          </form>
        ) : (
          <ApplicationResult success={applySuccess} jobId={jobId} />
        )}
      </Box>
    </>
  );
};

export default ApplyForm;
