import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import CardItem from "../card/card";
import SideBar from "../sidebar/sidebar";
import Cookies from "js-cookie";

const Dashboard = () => {
  const [dashboardCards, setDashBoardCards] = useState([
    // {
    //   heading: "Recent Openings",
    //   image: null,
    //   cardText: "Check out recent openings from the company",
    //   path: "/recruitment/job-openings",
    //   show: false,
    // },
    {
      heading: "Events",
      image: null,
      cardText: "Check out all events happening in the company",
      path: "",
      show: false,
    },
    // {
    //   heading: "Blogs",
    //   image: null,
    //   cardText: "Visit Aapmor blogs",
    //   path: "/blogs",
    //   show: false,
    // },
    {
      heading: "Policies",
      image: null,
      cardText: "Click to view all policies",
      path: "",
      show: false,
    },
    // {
    //   heading: "Careers",
    //   image: null,
    //   cardText: "Click to view all Jobs",
    //   path: "/careers/job-openings",
    //   show: false,
    // },
  ]);

  useEffect(() => {
    const access = JSON.parse(Cookies.get("access"));

    if (access) {
      const userAccessCookie = JSON.parse(Cookies.get("access"));
      if (userAccessCookie) {
        const updatedCards = dashboardCards.map((card) => {
          switch (card.heading) {
            // case "Recent Openings":
            //   return {
            //     ...card,
            //     show: userAccessCookie.includes("Orgx_Recent_Openings_View"),
            //   };
            case "Events":
              return {
                ...card,
                show: userAccessCookie.includes("Orgx_Events_View"),
              };
            // case "Blogs":
            //   return {
            //     ...card,
            //     show: userAccessCookie.includes("Orgx_Blogs_View_And_Write"),
            //   };
            case "Policies":
              return {
                ...card,
                show: userAccessCookie.includes("Orgx_Policies_View"),
              };
            // case "Careers":
            //   return {
            //     ...card,
            //     show: userAccessCookie.includes("Careers_View"),
            //   };
            default:
              return card;
          }
        });
        setDashBoardCards(updatedCards);
      }
    }
  }, []);

  return (
    <Grid flex={8} item container sx={{ p: 1, flexDirection: "column" }}>
      <Grid item>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
            p: 2,
          }}
        >
          {dashboardCards.map(
            (eachCard) =>
              eachCard.show && (
                <CardItem cardDetails={eachCard} key={eachCard.heading} />
              )
          )}
        </Box>
      </Grid>
    </Grid>
    // </Grid>
  );
};

export default Dashboard;
