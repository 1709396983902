import React from "react";
import AddIcon from "@mui/icons-material/Add";

//
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import FlagCircleOutlinedIcon from "@mui/icons-material/FlagCircleOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutlined";
//

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
//

// Accordions section for Annual Process Goals

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

//
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { GoGoal } from "react-icons/go";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

function EmpAnnualProcess() {
  return (
    <Stack
      direction={"row"}
      gap={"20px"}
      sx={{
        mx: 6,
        my: 3,
        mb: 5,
      }}
    >
      <div>
        <div
          style={{
            height: "30px",
          }}
        ></div>
        <AnnualProcess />
      </div>
    </Stack>
  );
}

export default EmpAnnualProcess;

function AnnualProcess() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={8}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography variant="h5">Annual | Process Closed</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            2023-2024 | Jagadeesh Talari
          </Typography>
        </Box>

        <Button variant="outlined">Download Goals & Attributes</Button>
      </Stack>
      <Divider
        sx={{
          mb: 2,
        }}
      ></Divider>
      {/*  Ratings Stack */}
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          mt: 8,
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "30px",
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              p: 1,
              bgcolor: "transparent",
            }}
          >
            <Stack direction="row" spacing={1}>
              <IconButton
                sx={{
                  height: 42,
                  width: 42,
                }}
              >
                <FlagCircleOutlinedIcon
                  sx={{
                    fontSize: 26,
                  }}
                />
              </IconButton>
              <Stack>
                <Typography variant="h6">8</Typography>
                <Typography variant="body" color="textSecondary">
                  Goal(s)
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  <ChatBubbleOutlinedIcon sx={{ fontSize: "12px" }} />
                  18 Comments
                </Typography>
              </Stack>
            </Stack>
          </Paper>
          <Paper
            variant="outlined"
            sx={{
              p: 1,
              bgcolor: "transparent",
            }}
          >
            <Stack direction="row" spacing={1}>
              <IconButton
                sx={{
                  height: 42,
                  width: 42,
                }}
              >
                <PersonAddAltOutlinedIcon
                  sx={{
                    fontSize: 24,
                  }}
                />
              </IconButton>
              <Stack>
                <Typography variant="h6">7</Typography>
                <Typography variant="body" color="textSecondary">
                  Attribute(s)
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  <ChatBubbleOutlinedIcon sx={{ fontSize: "12px" }} />
                  14 Comments
                </Typography>
              </Stack>
            </Stack>
          </Paper>
          <Paper
            variant="outlined"
            sx={{
              p: 1,
              bgcolor: "transparent",
            }}
          >
            <Stack direction="row" spacing={1}>
              {/* <IconButton
                sx={{
                  height: 42,
                  width: 42,
                }}
              >
                <PersonAddAltOutlinedIcon
                  sx={{
                    fontSize: 24,
                  }}
                />
              </IconButton> */}
              <Stack justifyContent={"center"} alignItems={"center"}>
                <Typography variant="h4">4.65</Typography>
                <Typography variant="body2" color="textSecondary">
                  Overall Rating
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Stack>
      {/* Tabs Section - With Respective Content*/}
      <BasicTabs />

      {/* <Paper
        sx={{
          py: 3,
          px: 2,
        }}
      >
        <Stack
          direction="row"
          spacing={8}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          sx={{
            width: "92%",
            mb: 3,
          }}
        >
          <Box>
            <Typography variant="subtitle2" color="textSecondary">
              Appraisal Type
            </Typography>
            <Typography variant="body">Annual</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" color="textSecondary">
              Effective Start Date
            </Typography>
            <Typography variant="body">Apr 1, 2023</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" color="textSecondary">
              Effective End Date
            </Typography>
            <Typography variant="body">Mar 31, 2024</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" color="textSecondary">
              Role
            </Typography>
            <Typography variant="body">Developer</Typography>
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={8}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          sx={{
            width: "92%",
            mb: 5,
          }}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Avatar
              sx={{
                bgcolor: deepPurple[500],
                width: 34,
                height: 34,
                fontSize: "16px",
              }}
            >
              Ti
            </Avatar>
            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                Manager
              </Typography>
              <Typography variant="body">Tiru</Typography>
            </Box>
          </Stack>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Avatar
              sx={{
                bgcolor: deepPurple[500],
                width: 34,
                height: 34,
                fontSize: "16px",
              }}
            >
              Ka
            </Avatar>
            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                Reviewer
              </Typography>
              <Typography variant="body">Karthik</Typography>
            </Box>
          </Stack>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Avatar
              sx={{
                bgcolor: deepPurple[500],
                width: 34,
                height: 34,
                fontSize: "16px",
              }}
            >
              Jo
            </Avatar>
            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                HR
              </Typography>
              <Typography variant="body">Jyothi</Typography>
            </Box>
          </Stack>
        </Stack>
      </Paper> */}
    </>
  );
}

// Accordions section for Annual Process Goals

const candidateGoals = [
  {
    id: 1,
    categoryHeading: "Develop Code",
    catgorySubHeading: "Develop code as per requirement",
    categoryTarget: "Develop code as per requirement",
    categorySetBy: "Karthik",
    categoryRating: 5,
    categoryComments: [
      {
        commentor: "Karthik",
        commentedAt: "time",
        comment:
          "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
      },
      {
        commentor: "Jyothi",
        commentedAt: "time",
        comment:
          "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
      },
    ],
  },
  {
    id: 2,
    categoryHeading: "Develop Code",
    catgorySubHeading: "Develop code as per requirement",
    categoryTarget: "Develop code as per requirement",
    categorySetBy: "Karthik",
    categoryRating: 4.5,
    categoryComments: [
      {
        commentor: "Karthik",
        commentedAt: "time",
        comment:
          "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
      },
      {
        commentor: "Jyothi",
        commentedAt: "time",
        comment:
          "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
      },
    ],
  },
];

const newCandidateGoals = {
  Q1: [
    {
      id: "Q1-goals-1",
      categoryHeading: "Develop Code",
      catgorySubHeading: "Develop code as per requirement",
      categoryTarget: "Develop code as per requirement",
      categorySetBy: "Karthik",
      categoryRating: 5,
      categoryComments: [
        {
          commentor: "Karthik",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
      ],
    },
    {
      id: "Q1-goals-2",
      categoryHeading: "Develop Code",
      catgorySubHeading: "Develop code as per requirement",
      categoryTarget: "Develop code as per requirement",
      categorySetBy: "Karthik",
      categoryRating: 4.5,
      categoryComments: [
        {
          commentor: "Karthik",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
      ],
    },
  ],
  Q2: [
    {
      id: "Q2-goals-1",
      categoryHeading: "Second Quater Goal 1",
      catgorySubHeading: "Develop code as per requirement",
      categoryTarget: "Develop code as per requirement",
      categorySetBy: "Karthik",
      categoryRating: 5,
      categoryComments: [
        {
          commentor: "Karthik",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
      ],
    },
    {
      id: "Q2-goals-2",
      categoryHeading: "Second Quater Goal 2",
      catgorySubHeading: "Develop code as per requirement",
      categoryTarget: "Develop code as per requirement",
      categorySetBy: "Karthik",
      categoryRating: 4.5,
      categoryComments: [
        {
          commentor: "Karthik",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
      ],
    },
  ],
};

const candidateAttributes = [
  {
    id: 1,
    categoryHeading: "Analytical Ability",
    catgorySubHeading:
      "Makes systematic and rational judgments based on relevant information, and supports the same with facts. Identifies potential issues/opportunities in complex situations and foresees implications of proposed solutions.",
    categoryTarget: "Develop code as per requirement",
    categoryRating: 4,
    categoryComments: [
      {
        commentor: "Karthik",
        commentedAt: "time",
        comment:
          "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
      },
      {
        commentor: "Jyothi",
        commentedAt: "time",
        comment:
          "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
      },
      {
        commentor: "Jyothi",
        commentedAt: "time",
        comment:
          "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
      },
      {
        commentor: "Jyothi",
        commentedAt: "time",
        comment:
          "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
      },
    ],
  },
  {
    id: 2,
    categoryHeading: "Develop Code",
    catgorySubHeading: "Develop code as per requirement",
    categoryTarget: "Develop code as per requirement",
    categorySetBy: "Karthik",
    categoryRating: 4.5,
    categoryComments: [
      {
        commentor: "Karthik",
        commentedAt: "time",
        comment:
          "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
      },
      {
        commentor: "Jyothi",
        commentedAt: "time",
        comment:
          "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
      },
    ],
  },
];

const newCandidateAttributes = {
  Q1: [
    {
      id: "Q1-attr-1",
      categoryHeading: "Analytical Ability",
      catgorySubHeading:
        "Makes systematic and rational judgments based on relevant information, and supports the same with facts. Identifies potential issues/opportunities in complex situations and foresees implications of proposed solutions.",
      categoryTarget: "Develop code as per requirement",
      categoryRating: 4,
      categoryComments: [
        {
          commentor: "Karthik",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
      ],
    },
    {
      id: "Q1-attr-2",
      categoryHeading: "Develop Code",
      catgorySubHeading: "Develop code as per requirement",
      categoryTarget: "Develop code as per requirement",
      categorySetBy: "Karthik",
      categoryRating: 4.5,
      categoryComments: [
        {
          commentor: "Karthik",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
      ],
    },
  ],
  Q2: [
    {
      id: "Q2-attr-1",
      categoryHeading: "Second Quarter Analytical Ability",
      catgorySubHeading:
        "Makes systematic and rational judgments based on relevant information, and supports the same with facts. Identifies potential issues/opportunities in complex situations and foresees implications of proposed solutions.",
      categoryTarget: "Develop code as per requirement",
      categoryRating: 4,
      categoryComments: [
        {
          commentor: "Karthik",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
      ],
    },
    {
      id: "Q2-attr-2",
      categoryHeading: "Second Quarter Develop Code",
      catgorySubHeading: "Develop code as per requirement",
      categoryTarget: "Develop code as per requirement",
      categorySetBy: "Karthik",
      categoryRating: 4.5,
      categoryComments: [
        {
          commentor: "Karthik",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
        {
          commentor: "Jyothi",
          commentedAt: "time",
          comment:
            "Continue to excel and maintain the impressive standard of work you have been consistently delivering.",
        },
      ],
    },
  ],
};

function ControlledAccordions({ candidateCategoryDetails }) {
  const [expanded, setExpanded] = React.useState(false);
  const [commentsShow, setCommentsShow] = React.useState(false);
  const [newComment, setNewComment] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {
        // candidateCategoryDetails.map( e )
        candidateCategoryDetails.map((catDetails, i) => {
          return (
            <Accordion
              key={catDetails.id}
              expanded={expanded === "panel" + i + 1}
              onChange={handleChange("panel" + i + 1)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${i + 1}bh-content`}
                id={`panel${i + 1}bh-header`}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {catDetails.categoryHeading}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {catDetails?.catgorySubHeading}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction="row"
                  spacing={8}
                  justifyContent={"space-between"}
                  flexWrap={"wrap"}
                  sx={{
                    width: "92%",
                    mb: 5,
                  }}
                >
                  {catDetails.categoryTarget && (
                    <Stack direction={"row"} spacing={1}>
                      <GoGoal
                        style={{
                          fontSize: "30px",
                          color: "red",
                        }}
                      />

                      <Box
                        sx={{
                          maxWidth: "250px",
                        }}
                      >
                        <Typography variant="subtitle2" color="textSecondary">
                          Target
                        </Typography>
                        <Typography variant="body">
                          {catDetails.categoryTarget}
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  {catDetails.categorySetBy && (
                    <Stack direction={"row"} spacing={1}>
                      <Avatar
                        sx={{
                          bgcolor: deepPurple[500],
                          width: 34,
                          height: 34,
                          fontSize: "16px",
                        }}
                      >
                        Ka
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">
                          SET BY
                        </Typography>
                        <Typography variant="body">
                          {catDetails.categorySetBy}
                        </Typography>
                      </Box>
                    </Stack>
                  )}

                  {catDetails.categoryRating && (
                    <Stack direction={"row"} spacing={1}>
                      <Avatar
                        sx={{
                          bgcolor: "transparent",
                          width: 34,
                          height: 34,
                          fontSize: "16px",
                          border: "2px solid #ffcf33",
                        }}
                      >
                        <StarRateRoundedIcon
                          sx={{
                            color: "#ffcf33",
                          }}
                        />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">
                          RATING
                        </Typography>
                        <Typography variant="body">
                          {catDetails.categoryRating}
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                </Stack>
                <Divider></Divider>
                <Stack sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography>
                      Comments ({catDetails.categoryComments.length})
                    </Typography>
                    <Typography
                      sx={{
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setCommentsShow(!commentsShow);
                      }}
                    >
                      {commentsShow ? `Hide Comments` : `Show Comments`}
                    </Typography>
                  </Box>

                  {!commentsShow && (
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Avatar
                        sx={{
                          bgcolor: deepPurple[500],
                          width: 34,
                          height: 34,
                          fontSize: "16px",
                        }}
                      >
                        Ti
                      </Avatar>
                      <Box>
                        <Typography variant="body">
                          {catDetails.categoryComments[0].commentor} :
                          {catDetails.categoryComments[0].commentedAt}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          {catDetails.categoryComments[0].comment}
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  {/* Chat Section */}
                  {commentsShow && (
                    <>
                      <Divider
                        sx={{
                          border: "1px dashed #a8adba",
                        }}
                      ></Divider>
                      <Timeline
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                            height: 100,
                          },
                        }}
                      >
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot>
                              <Avatar
                                sx={{
                                  bgcolor: deepPurple[500],
                                  width: 34,
                                  height: 34,
                                  fontSize: "16px",
                                }}
                              >
                                Em
                              </Avatar>
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography variant="body">
                              {catDetails.categoryComments[0].commentor} :
                              {catDetails.categoryComments[0].commentedAt}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {catDetails.categoryComments[0].comment}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot>
                              <Avatar
                                sx={{
                                  bgcolor: deepPurple[500],
                                  width: 34,
                                  height: 34,
                                  fontSize: "16px",
                                }}
                              >
                                Ti
                              </Avatar>
                            </TimelineDot>
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography variant="body">
                              {catDetails.categoryComments[1].commentor} :
                              {catDetails.categoryComments[1].commentedAt}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {catDetails.categoryComments[0].comment}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        {/* {newComment && (
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineDot>
                                <Avatar
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    width: 34,
                                    height: 34,
                                    fontSize: "16px",
                                  }}
                                >
                                  Ti
                                </Avatar>
                              </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                              <Typography variant="body">
                                {catDetails.categoryComments[1].commentor} :
                                {catDetails.categoryComments[1].commentedAt}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                {catDetails.categoryComments[0].comment}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        )} */}
                      </Timeline>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          pl: 3,
                          gap: 2,
                        }}
                      >
                        <TextField
                          sx={{
                            width: "600px",
                          }}
                          size="small"
                          label="Add comment here!"
                          onChange={(e) => {
                            setNewComment(e.target.value);
                          }}
                        ></TextField>
                        <Button variant="contained" onClick={() => {}}>
                          Add Comment
                        </Button>
                      </Box>
                      {/* <Stack spacing={2}>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Avatar
                            sx={{
                              bgcolor: deepPurple[500],
                              width: 34,
                              height: 34,
                              fontSize: "16px",
                            }}
                          >
                            Ti
                          </Avatar>
                          <Box>
                            <Typography variant="body">
                              {catDetails.categoryComments[0].commentor} :
                              {catDetails.categoryComments[0].commentedAt}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {catDetails.categoryComments[0].comment}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Avatar
                            sx={{
                              bgcolor: deepPurple[500],
                              width: 34,
                              height: 34,
                              fontSize: "16px",
                            }}
                          >
                            Ti
                          </Avatar>
                          <Box>
                            <Typography variant="body">
                              {catDetails.categoryComments[0].commentor} :
                              {catDetails.categoryComments[0].commentedAt}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {catDetails.categoryComments[0].comment}
                            </Typography>
                          </Box>
                        </Stack>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            sx={{
                              width: "700px",
                            }}
                            size="small"
                          ></TextField>
                          <Button variant="contained">Add Comment</Button>
                        </Box>
                      </Stack> */}
                    </>
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          );
        })
      }
    </div>
  );
}

// Tabs Section

function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [quarter, setQuarter] = React.useState("Q1");
  const [candidateQuaterDetails, setCandidateQuaterDetails] = React.useState({
    goals: newCandidateGoals["Q1"],
    attributes: newCandidateAttributes["Q1"],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    setQuarter(event.target.value);
    // console.log(`e.tar.value`, event.target.value);
    setCandidateQuaterDetails({
      goals: newCandidateGoals[event.target.value],
      attributes: newCandidateAttributes[event.target.value],
    });
    // console.log(
    //   `candidate Quater Details:`,
    //   newCandidateGoals[event.target.value]
    // );
  };

  return (
    <Box sx={{ width: "930px" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="FEEDFORWARD" {...a11yProps(0)} />
          <Tab label="GOAL(S)" {...a11yProps(1)} />
          <Tab label="ATTRIBUTE(S)" {...a11yProps(2)} />
        </Tabs>

        <FormControl
          sx={{
            position: "absolute",
            right: 40,
            bottom: 4,
            width: 120,
          }}
          size="small"
        >
          <InputLabel id="quarter-select-label">Quarter</InputLabel>
          <Select
            labelId="quater-select-label"
            id="quarter-select"
            value={quarter}
            label="Quarter"
            onChange={handleSelectChange}
          >
            <MenuItem value={"Q1"}>Q1</MenuItem>
            <MenuItem value={"Q2"}>Q2</MenuItem>
            <MenuItem value={"Q3"}>Q3</MenuItem>
            <MenuItem value={"Q4"}>Q4</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Typography
          variant="subtitle1"
          sx={{
            my: 3,
            fontSize: 12,
          }}
        >
          Feedforward provides a comprehensive overview of one's performance
          across various processes. It serves as a holistic input on hw one can
          take a leap forward in their professional development in Aapmor.{" "}
        </Typography>
        <Stack
          direction={"row"}
          spacing={4}
          sx={{
            mb: 3,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box
              sx={{
                height: "22px",
                width: "8px",
                bgcolor: "green",
              }}
            ></Box>
            <Typography variant="subtitle1">Compliant/Complete </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Box
              sx={{
                height: "22px",
                width: "8px",
                bgcolor: "orange",
              }}
            ></Box>
            <Typography variant="subtitle1">In Progress </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Box
              sx={{
                height: "22px",
                width: "8px",
                bgcolor: "red",
              }}
            ></Box>
            <Typography variant="subtitle1">
              Non-Compliant/InComplete{" "}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Box
              sx={{
                height: "22px",
                width: "8px",
                bgcolor: "#004c99",
              }}
            ></Box>

            <Typography variant="subtitle1">Current Count/Status </Typography>
          </Box>
        </Stack>
        <CardsForFeedForward />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {/* Goals Section */}
        <ControlledAccordions
          candidateCategoryDetails={candidateQuaterDetails.goals}
        />
        {/* Note: You should add self rating as well */}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {/* Goals Section */}
        <ControlledAccordions
          candidateCategoryDetails={candidateQuaterDetails.attributes}
        />
      </CustomTabPanel>
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CardsForFeedForward() {
  return (
    <Stack direction={"row"} spacing={2}>
      <Card sx={{ width: 250 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Compliance
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              p: 1.5,
              border: "1px solid #000",
              gap: "10px",
              mb: 2,
            }}
          >
            <Typography component="div">Goal Setting: Appraisee</Typography>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                bgcolor: "green",
              }}
            ></Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              p: 1.5,
              border: "1px solid #000",
              gap: "10px",
              mb: 2,
            }}
          >
            <Typography component="div">Goal Setting: Appraisee</Typography>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                bgcolor: "green",
              }}
            ></Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              p: 1.5,
              border: "1px solid #000",
              gap: "10px",
              mb: 2,
            }}
          >
            <Typography component="div">Goal Setting: Appraisee</Typography>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                bgcolor: "green",
              }}
            ></Box>
          </Stack>
        </CardContent>
      </Card>
      <Card sx={{ width: 250 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Development
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              p: 1.5,
              border: "1px solid #000",
              gap: "10px",
              mb: 2,
            }}
          >
            <Typography component="div">Certifications</Typography>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                bgcolor: "#004c99",
              }}
            ></Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              p: 1.5,
              border: "1px solid #000",
              gap: "10px",
              mb: 2,
            }}
          >
            <Typography component="div">Competencies acuired</Typography>
            <Box
              sx={{
                flexBasis: "20px",
                height: "20px",
                bgcolor: "#004c99",
              }}
            ></Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              p: 1.5,
              border: "1px solid #000",
              gap: "10px",
              mb: 2,
            }}
          >
            <Typography component="div">Learning Points</Typography>
            <Box
              sx={{
                flexBasis: "20px",
                height: "20px",
                p: 0.25,
                bgcolor: "#004c99",
                color: "white",
              }}
            >
              100
            </Box>
          </Stack>
        </CardContent>
      </Card>
      <Card sx={{ width: 250 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Feedback
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              p: 1.5,
              border: "1px solid #000",
              gap: "10px",
              mb: 2,
            }}
          >
            <Typography component="div">Continuous Feedback</Typography>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                bgcolor: "#004c99",
              }}
            ></Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              p: 1.5,
              border: "1px solid #000",
              gap: "10px",
              mb: 2,
            }}
          >
            <Typography component="div">Appreciations</Typography>
            <Box
              sx={{
                flexBasis: "20px",
                height: "20px",
                bgcolor: "#004c99",
              }}
            ></Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              p: 1.5,
              border: "1px solid #000",
              gap: "10px",
              mb: 2,
            }}
          >
            <Typography component="div">Awards</Typography>
            <Box
              sx={{
                flexBasis: "20px",
                height: "20px",
                p: 0.25,
                bgcolor: "#004c99",
                color: "white",
              }}
            >
              3
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}
