import { Grid, IconButton, Stack, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import "./Projects.css";
import ProjectDetailsModal from "../projects/ProjectsModal";
import DeleteIcon from "@mui/icons-material/Delete";
import ProjectDeleteModal from "../projects/ProjectDeleteModal";
import { projectsBaseUrl } from "../../apiCalls/apiCalls";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import { TokenContext } from "../protectedLayout";

//  ===============================================
//                     Projects Table
//  ===============================================

function ProjectsTables() {
  // Set withCredentials to true for all requests
  axios.defaults.withCredentials = true;

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = useContext(TokenContext);
  // data grid states
  const [columns] = useState([
    { field: "id", headerName: "S.No.", width: 98 },
    { field: "project", headerName: "Project", width: 250 },
    { field: "sponsor", headerName: "Sponsorer", width: 250 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          gap={0.2}
          sx={{
            height: "inherit",
          }}
          alignItems={"center"}
        >
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(e) => handleOpenEditModal(params.row)}
          >
            <EditIcon sx={{ fontSize: "19px" }} />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(e) => handleOpenDeleteModal(params.row)}
          >
            <DeleteIcon sx={{ fontSize: "19px", color: "#ff5630" }} />
          </IconButton>
        </Stack>
      ),
    },
  ]);

  // Basic modal states
  const [open, setOpen] = useState(false);
  // Project  - Edit modal states
  const [openEditModal, setOpenEditModal] = useState(false);
  const [tableRowToEdit, setTableRowToEdit] = useState("");

  // Project  - Delete modal states
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  
  // Data-Fetching
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const token = Cookies.get("jwtToken");
        const response = await axios.get(projectsBaseUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const projectsWithIds = response.data.map((proj, i) => {
          return {
            id: i + 1,
            project: proj.projectName,
            sponsor: proj.projectSponsor,
            _id: proj._id,
          };
        });
        setRows(projectsWithIds);
        setIsLoading(false);
      } catch (error) {
        console.log(`error-message`, error);
      }
    };

    fetchData();
  }, [openEditModal, openDeleteModal]);

  // Basic Modal Functions
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Edit Modal Functions
  const handleOpenEditModal = (rowToEdit) => {
    setTableRowToEdit(rowToEdit);
    setOpenEditModal(true);
  };

  const handleEditModalClose = () => setOpenEditModal(false);

  // Edit Submit Handler
  const handleSubmit = async (e, id) => {
    e.preventDefault();

    handleEditModalClose();
    try {
      const presentProjectData = rows.find((e) => e.id === id);

      await axios.put(
        `${projectsBaseUrl}/${presentProjectData._id}`,
        {
          projectName: e.target.projectName.value,
          projectSponsor: e.target.projectSponsor.value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const newRowsData = rows.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            project: e.target.projectName.value,
            sponsor: e.target.projectSponsor.value,
          };
        } else {
          return row;
        }
      });
      setRows(newRowsData);
      toast.success(`Project updated successfully`);
    } catch (error) {
      console.log(`Error while updating the project:`, error);
      toast.error(error.response.data.message);
    }
  };

  // Delete Modal Functions
  const handleOpenDeleteModal = (rowToEdit) => {
    setTableRowToEdit(rowToEdit);
    setOpenDeleteModal(true);
  };

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const handleDelete = async (e, id) => {
    e.preventDefault();
    handleDeleteModalClose();

    try {
      const newRowsData = rows.filter((row) => row.id !== id);
      const singleProject = rows.find((e) => e.id === id);
      await axios.delete(`${projectsBaseUrl}/${singleProject._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRows(newRowsData);
      toast.success("Deleted Project Successfully");
    } catch (error) {
      console.log(`Error while deleting the project:`, error);
      toast.error(error.response.data.message);
    }
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <>
      <Grid
        flex={8}
        item
        container
        xs={12}
        sx={{ p: 1, flexDirection: "column", my: 6, px: 5 }}
      >
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          sx={{ mb: 4 }}
        >
          <Typography variant="h4">Projects</Typography>
          <BasicModal
            handleOpen={handleOpen}
            handleClose={handleClose}
            open={open}
            setOpen={setOpen}
            modalText={"Add Project"}
            rows={rows}
            setRows={setRows}
          />
          <ProjectDetailsModal
            tableRowToEdit={tableRowToEdit}
            openEditModal={openEditModal}
            handleClose={handleClose}
            handleEditModalClose={handleEditModalClose}
            handleSubmit={handleSubmit}
            setTableRowToEdit={setTableRowToEdit}
          />
          <ProjectDeleteModal
            tableRowToEdit={tableRowToEdit}
            openDeleteModal={openDeleteModal}
            handleClose={handleClose}
            handleDeleteModalClose={handleDeleteModalClose}
            handleSubmit={handleDelete}
            setTableRowToEdit={setTableRowToEdit}
          />
        </Stack>
        <Box>
          <DataTable rows={rows} columns={columns} />
        </Box>
      </Grid>
      <ToastContainer />
    </>
  );
}

//  -----------------------------------------------
//                       Modal
//  -----------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "15px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function BasicModal({
  handleOpen,
  handleClose,
  open,
  modalText,
  rows,
  setRows,
}) {
  const [newProjectName, setNewProjectName] = useState("");
  const [newProjectSponsor, setNewProjectSponsor] = useState("");
  const token = useContext(TokenContext)

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        projectsBaseUrl,
        {
          projectName: newProjectName,
          projectSponsor: newProjectSponsor,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success(`New Project successfully added`);
        setRows([
          ...rows,
          {
            id: rows.length + 1,
            project: newProjectName,
            sponsor: newProjectSponsor,
          },
        ]);
      }
    } catch (error) {
      console.log(`error-in post request:`, error.response.data.message);
      toast.error(error.response.data.message);
    }

    handleClose();
  };
  return (
    <div>
      <Button onClick={handleOpen} variant="contained">
        {modalText}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            New Project Form
          </Typography>
          <form
            action=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
            onSubmit={handleSubmit}
          >
            <TextField
              name="projectName"
              label="Project Name"
              onChange={(e) => setNewProjectName(e.target.value)}
              fullWidth
              id="projectName"
              type="text"
              required
              size="small"
            />

            <TextField
              name="projectSponsor"
              label="Project Sponsorer"
              onChange={(e) => setNewProjectSponsor(e.target.value)}
              fullWidth
              id="projectSponsor"
              type="text"
              required
              size="small"
            />
            <Button type="submit" variant="outlined">
              Add
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

//  -----------------------------------------------
//                    Data grid table
//  -----------------------------------------------

function DataTable({ rows, columns }) {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnResize={true}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        style={{
          width: 700,
          backgroundColor: "#fff",
        }}
        sx={{
          ".css-t89xny-MuiDataGrid-columnHeaderTitle": {
            fontWeight: 700,
          },
        }}
        // checkboxSelection
      />
    </div>
  );
}

export default ProjectsTables;
