import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export default function CreateNewGoalTemplate() {
  const [goalsArray, setGoalsArray] = useState([1]);
  const [editTitle, setEditTitle] = useState(false);
  const navigate = useNavigate();
  return (
    <Stack
      // direction={"row"}
      gap={"20px"}
      sx={{
        mx: 6,
        my: 3,
        mb: 5,
      }}
    >
      <Box>
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Paper
        variant="outlined"
        sx={{
          p: 5,
          py: 8,
        }}
      >
        <form>
          <Stack spacing={3}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              {editTitle && <TextField />}
              <Typography variant="h5">
                2024-25 Junior Software Engineer Goal Template
              </Typography>
              <EditIcon
                onClick={() => {
                  setEditTitle(true);
                }}
              />
            </Stack>
            <hr />
            <Button
              sx={{
                alignSelf: "flex-end",
              }}
              variant="outlined"
              onClick={() => {
                setGoalsArray([...goalsArray, 1]);
              }}
            >
              Add Goal
            </Button>

            {goalsArray.map((e, i) => {
              return (
                <>
                  <Typography>Goal - {i + 1}</Typography>
                  <TextField size="small" label="Title"></TextField>
                  <TextField
                    minRows={4}
                    maxRows={5}
                    multiline
                    label="Description"
                  ></TextField>
                </>
              );
            })}
            <Button
              sx={{
                alignSelf: "flex-end",
              }}
              variant="contained"
            >
              Create Template
            </Button>
          </Stack>
        </form>
      </Paper>
    </Stack>
  );
}
