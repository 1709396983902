import {
  Box,
  Grid,
  Pagination,
  Stack,
  Typography,
  CircularProgress,
  Modal,
  Button,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import CardItem from "../card/card";
import SideBar from "../sidebar/sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JobCard from "./JobCard";
import { nanoid } from "nanoid";
import axios from "axios";
import { TokenContext } from "../protectedLayout";
import { jobPostingBaseUrl } from "../../apiCalls/apiCalls";

const Jobs = () => {
  axios.defaults.withCredentials = true; // Ensure credentials are included
  const token = useContext(TokenContext);
  const [jobOpeningData, setJobOpeningData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [jobToDelete, setJobToDelete] = useState("");

  // handle methods for modal that opens when delete button is clicked
  const handleOpenDeleteModal = (jobToDelete) => {
    setJobToDelete(jobToDelete);
    setOpenDeleteModal(true);
  };
  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
    setJobToDelete("");
  };

  const fetchJobOpeningData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${jobPostingBaseUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setJobOpeningData(res?.data);
    } catch (error) {
      console.error("Error fetching job openings:", error);
      setJobOpeningData([]); // Set to empty array on error
    } finally {
      setLoading(false);
    }
  };

  async function handleJobDelete(id) {
    try {
      const response = await axios.delete(`${jobPostingBaseUrl}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Row deleted successfully", response);
      fetchJobOpeningData();
      setOpenDeleteModal(false);
      if (response.status === 200) {
        // toast("Applicant details updated successfully");
        toast.success("Job deleted successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Row delete failed", error);
    }
  }

  useEffect(() => {
    fetchJobOpeningData();
  }, []);

  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const numPages = Math.ceil(jobOpeningData?.length / itemsPerPage);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const jobsToShow = jobOpeningData?.slice(startIndex, endIndex);

  return (
    <Grid
      flex={8}
      item
      container
      sx={{ px: 5, py: 5, flexDirection: "column" }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            mt: 10,
          }}
        >
          <CircularProgress />
        </Box>
      ) : jobOpeningData.length === 0 ? (
        <Typography variant="h5" align="center" sx={{ mt: 10 }}>
          No Jobs Found
        </Typography>
      ) : (
        <Stack gap={3} sx={{ width: "100%", px: 2, py: 1 }}>
          {jobsToShow?.map((job, key) => {
            return (
              <JobCard
                key={key}
                job={job}
                handleOpenDeleteModal={handleOpenDeleteModal}
              />
            );
          })}
          <Pagination
            count={numPages}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
            sx={{ m: "auto" }}
          />
        </Stack>
      )}
      <Modal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            px: 4,
            py: 5,
            maxHeight: 500,
            textAlign: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="body1"
            component="paragraph"
            align={"center"}
            fontWeight={"bold"}
          >
            {`Are you sure you want to delete this job opening?`}
          </Typography>
          <Stack direction={"row"} mt={3} gap={3} justifyContent={"center"}>
            <Button variant="outlined" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{
                bgcolor: "#FF4B4B",
                borderColor: "#FF4B4B",
                color: "white",
                ":hover": {
                  bgcolor: "white",
                  color: "#FF4B4B",
                  borderColor: "#FF4B4B",
                },
              }}
              onClick={(e) => handleJobDelete(jobToDelete)}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
      <ToastContainer />
    </Grid>
  );
};

export default Jobs;
