import {
  Grid,
  Typography,
  Box,
  Card,
  Drawer,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Rating,
  Button,
  IconButton,
  Stack,
  Divider,
  Chip,
  Modal,
} from "@mui/material";
import useSWR from "swr";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

import { Viewer } from "@react-pdf-viewer/core";

import { useNavigate, useParams } from "react-router-dom";

import Loader from "../../../utils/loader";

import axios from "axios";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import CandidateDetails from "./CandidateDetails";
import InterviewRounds from "./InterviewRounds";
import {
  recruitmentDetailsBaseUrl,
  masterDataBaseUrl,
  scheduleInterviewsBaseUrl,
  CandidateInterviewRoundFeedback,
  viewResumeBaseUrl,
  resumeBaseUrl,
} from "../../../apiCalls/apiCalls";
import InterviewDetails from "./InterviewDetails";
import { ToastContainer, toast } from "react-toastify";
import { TokenContext } from "../../protectedLayout";

const spanWidth = {
  width: "200px",
};

const fetcher = (url) => fetch(url).then((res) => res.json());
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const constants = {
  loading: "loading",
  jobSeekerDetails: "jobseekerdetails",
  jobSeekerList: "jobseekerlist",
};
const InterviewStatus = () => {
  axios.defaults.withCredentials = true;

  const { id } = useParams();
  const { data: masterData, isLoading: jobdescLoading } = useSWR(
    `${masterDataBaseUrl}`,
    fetcher
  );
  const [openfeedbackModal, setOpenfeedbackModal] = useState(false);

  const handlefeedbackModalClose = () => setOpenfeedbackModal(false);
  const navigate = useNavigate();
  const [renderView, setRenderView] = useState(constants.loading);
  const [previewResume, setPreviewResume] = useState(false);
  const [userData, setUserData] = useState([]);
  const [value, setValue] = useState(0);
  const [interviewStatus, setInterviewStatus] = useState("Pending");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [isSubmitFeedback, setIsSubmitFeedback] = useState(false);
  const [recruitFeedback, setRecruitFeedback] = useState({
    interviewType: "",
    interviewRound: "",
    interviewer: "",
    interviewMode: "",
    techRating: 0,
    commRating: 0,
    criticalThinkingRating: 0,
    softSkills: 0,
    feedback: "",
    status: "",
  });
  const token = useContext(TokenContext);

  const validateRecruitFeedback = (values) => {
    const errors = {};

    // Validation for interviewType

    // Validation for techRating
    if (values.techRating === 0) {
      errors.techRating = "* Field is required";
    }

    // Validation for commRating
    if (values.commRating === 0) {
      errors.commRating = "* Field is required";
    }

    // Validation for criticalThinkingRating
    if (values.criticalThinkingRating === 0) {
      errors.criticalThinkingRating = "* Field is required";
    }

    // Validation for softSkills
    if (values.softSkills === 0) {
      errors.softSkills = "* Field is required";
    }

    // Validation for feedback
    if (!values.feedback) {
      errors.feedback = "* Field is required";
    }

    // Validation for status
    if (!values.status) {
      errors.status = "* Field is required";
    }

    return errors;
  };

  const handleToggleResume = () => {
    setPreviewResume(!previewResume);
  };

  useEffect(() => {
    getJobSeekerData();
  }, []);
  useEffect(() => {
    if (isSubmit) {
      setFormErrors(validateRecruitFeedback(recruitFeedback));
    }
  }, [recruitFeedback, formErrors, isSubmit]);

  const getJobSeekerData = async () => {
    try {
      // const response = await getJobSeekerDetails(id);
      const response = await axios(
        `${CandidateInterviewRoundFeedback}?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setRenderView(constants.jobSeekerDetails);
      }
      const data = response.data;
      setUserData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  let url = "";
  const candidateId = userData[0]?.candidateDetails[0]?._id;
  const candidateResume = userData[0]?.candidateDetails[0]?.resume;

  const submitRecruitFeedback = async (e) => {
    e.preventDefault();

    setIsSubmit(true);
    if (Object.entries(validateRecruitFeedback(recruitFeedback)).length === 0) {
      try {
        const response = await axios.put(
          `${recruitmentDetailsBaseUrl}${candidateId}`,
          {
            id,
            recruitFeedback,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setOpenfeedbackModal(false);
          toast.success("Feedback Submitted Successfully");
          window.location.reload();
        }
        setIsSubmitFeedback(false);
      } catch (error) {
        console.log(error);
        toast.error("Error in Submitting Feedback");
      }
    }
  };

  const renderJobSeekerDetailsView = () => {
    const filteredMasterdData = masterData?.filter(
      (e) => e.category === "interviewType"
    );

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <>
        {userData.flatMap((e) =>
          e.candidateDetails.map((data) => (
            <Grid
              container
              // sx={{ overflowY: "auto", height: "100vh", p: 1 }}
              spacing={1}
              sx={{ mt: 5, mb: 2 }}
            >
              <Grid item xs={12} md={12}>
                <Stack direction={"row"} gap={2} alignItems={"center"} ml={5}>
                  <IconButton>
                    <KeyboardBackspaceIcon
                      onClick={() => navigate("/recruitment/interview-board")}
                    />
                  </IconButton>
                  <Typography variant="h6" fontWeight={600}>
                    Candidate Overview
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} md={12} mt={3}>
                <Card sx={{ width: "90%", m: "auto" }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          label="Candidate Details"
                          sx={{ textTransform: "capitalize" }}
                          {...a11yProps(0)}
                        />
                        <Tab
                          label="Resume"
                          sx={{ textTransform: "capitalize" }}
                          {...a11yProps(2)}
                          // onClick={handleDisplayPdf(data.resume, "resume")}
                        />
                        <Tab
                          label="Interview Details"
                          sx={{ textTransform: "capitalize" }}
                          {...a11yProps(3)}
                        />
                        <Tab
                          label="Interview Feedback"
                          sx={{ textTransform: "capitalize" }}
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </Stack>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <CandidateDetails data={data} />
                  </CustomTabPanel>
                  <CustomTabPanel index={1} value={value}>
                    {/* <iframe
                      title="PDF Viewer"
                      style={{ width: "100%", height: "500px" }}
                      srcDoc={
                        data.resume
                          ? `<embed src="${data.resume}" type="application/pdf" style="width:100%;height:500px;" />`
                          : "No preview available"
                      }
                    /> */}
                    <iframe
                      src={`${resumeBaseUrl}/view/${candidateResume?.fileName}`}
                      title="PDF Viewer"
                      style={{ width: "100%", height: "500px" }}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <InterviewDetails
                      userData={userData}
                      data={data}
                      submitRecruitFeedback={submitRecruitFeedback}
                      setRecruitFeedback={setRecruitFeedback}
                      recruitFeedback={recruitFeedback}
                      interviewStatus={interviewStatus}
                      handlefeedbackModalClose={handlefeedbackModalClose}
                      openfeedbackModal={openfeedbackModal}
                      setOpenfeedbackModal={setOpenfeedbackModal}
                      formErrors={formErrors}
                      setIsSubmitFeedback={setIsSubmitFeedback}
                      isSubmitFeedback={isSubmitFeedback}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <InterviewRounds
                      data={data}
                      submitRecruitFeedback={submitRecruitFeedback}
                      setRecruitFeedback={setRecruitFeedback}
                      recruitFeedback={recruitFeedback}
                    />
                  </CustomTabPanel>
                </Card>
              </Grid>

              {}
            </Grid>
          ))
        )}
        <ToastContainer />
      </>
    );
  };
  const renderRequiredView = () => {
    switch (renderView) {
      case constants.loading:
        return <Loader />;
      case constants.jobSeekerDetails:
        return renderJobSeekerDetailsView();
      default:
        return null;
    }
  };

  return (
    // <Grid container flexDirection={"row"}>
    //   <Grid item flex={1}>
    //     <SideBar />
    //   </Grid>
    <Grid item flex={8}>
      {renderRequiredView()}
    </Grid>
    // </Grid>
  );
};

export default InterviewStatus;
