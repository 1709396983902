import { Avatar, Divider, Grid, Paper, Stack } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

// BasicTabs - Imports
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import PhonelinkRingTwoToneIcon from "@mui/icons-material/PhonelinkRingTwoTone";
import PinDropTwoToneIcon from "@mui/icons-material/PinDropTwoTone";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import { useSelector } from "react-redux";

// ********************************************************
//                     Main Component
// ********************************************************

function CandidateDetails() {
  const { id } = useParams();
  return (
    <div
      style={{
        backgroundColor: "#eef2f6",
        minHeight: "120vh",
        padding: "20px",
      }}
    >
      <Paper>
        <BasicTabs id={id} />
      </Paper>
    </div>
  );
}

export default CandidateDetails;

// --------------------------------------------------------
//                  Basic Tabs Component
// --------------------------------------------------------

export function BasicTabs({ id }) {
  const [value, setValue] = React.useState(0);
  const [interviewDetailsIndex, setInterviewDetailsIndex] = useState(0);
  const singleCandidateDetails = useSelector(
    (state) => state.newCandidates[id - 1]
  );

  // const [
  //   singleCandidateDetails = allCandidateDetails[0],
  //   setSingleCandidateDetails,
  // ] = useState(allCandidateDetails[id - 1]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Tabs Navigation */}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Profile"
            {...a11yProps(0)}
            iconPosition="start"
            sx={{
              textTransform: "capitalize",
            }}
            icon={<AccountCircleTwoToneIcon />}
          />
          <Tab
            label="Interview Details"
            {...a11yProps(1)}
            iconPosition="start"
            sx={{
              textTransform: "capitalize",
            }}
            icon={<DescriptionTwoToneIcon />}
          />
        </Tabs>
      </Box>
      {/*  
          ==================================== 
                   Profile Tab Content  
          ==================================== 
      */}
      <CustomTabPanel value={value} index={0}>
        <Grid container>
          {/* 
          
          ----------------------------------- 
               Left Side Content Section  
          ------------------------------------

          Candidate Basic info like email, ph.no. etc.  
          
          */}
          <Grid item xs={4}>
            <Paper
              variant="outlined"
              sx={{
                width: "275px",
                paddingBottom: "30px",
              }}
            >
              {/* Candidate Name & Avatar */}
              <Stack direction={"row"} gap={2} p={2}>
                <Avatar />
                {/* Candidate name & Domain */}
                <Stack justifyContent={"center"}>
                  <Typography>
                    {singleCandidateDetails?.profile?.profileInfo?.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color={"textSecondary"}
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    {singleCandidateDetails?.profile?.profileInfo?.domain}
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              {/* Candidate Email & Phone & Loaction */}
              <Stack p={2}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={2}
                  px={1}
                  sx={{
                    ":hover": {
                      backgroundColor: "#ede7f6",
                    },
                  }}
                >
                  <EmailTwoToneIcon />
                  <Typography py={2}>Email</Typography>
                  <Typography
                    variant="subtitle1"
                    color={"textSecondary"}
                    py={2}
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {singleCandidateDetails?.profile?.profileInfo?.email}
                  </Typography>
                </Stack>
                <Divider />
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={2}
                  px={1}
                  sx={{
                    ":hover": {
                      backgroundColor: "#ede7f6",
                    },
                  }}
                >
                  <PhonelinkRingTwoToneIcon />
                  <Typography py={2}>Phone</Typography>
                  <Typography
                    variant="subtitle1"
                    color={"textSecondary"}
                    py={2}
                    sx={{
                      fontSize: "14px",
                      alignSelf: "flex-end",
                    }}
                  >
                    {singleCandidateDetails?.profile?.profileInfo?.phone}
                  </Typography>
                </Stack>
                <Divider />
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  px={1}
                  gap={2}
                  sx={{
                    ":hover": {
                      backgroundColor: "#ede7f6",
                    },
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <PinDropTwoToneIcon />
                    <Typography py={2}>Location</Typography>
                  </Stack>
                  <Typography
                    variant="subtitle1"
                    color={"textSecondary"}
                    py={2}
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {singleCandidateDetails?.profile?.profileInfo?.location}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          {/* 

          ----------------------------------- 
               Right Side Content Section  
          ------------------------------------

          About & Employement & Education etc.  
          
          */}
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            {/* About Section */}
            <Paper
              variant="outlined"
              sx={{
                paddingBottom: "30px",
              }}
            >
              <Stack
                direction={"row"}
                gap={2}
                p={2}
                justifyContent={"space-between"}
              >
                <Typography>About</Typography>
                {/* <ModeEditSharpIcon /> */}
              </Stack>
              <Divider />
              <Grid container px={4} mt={3} rowGap={2}>
                {/* Item-1 */}
                <Grid item xs={4}>
                  <Typography>Full Name</Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7}>
                  <Typography color={"textSecondary"}>
                    {singleCandidateDetails?.profile?.about?.fullName}
                  </Typography>
                </Grid>
                {/* Item-2 */}
                <Grid item xs={4}>
                  <Typography>Fathers Name</Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7}>
                  <Typography color={"textSecondary"}>
                    {singleCandidateDetails?.profile?.about?.fatherName}
                  </Typography>
                </Grid>
                {/* Item-3 */}
                <Grid item xs={4}>
                  <Typography>Address</Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7}>
                  <Typography color={"textSecondary"}>
                    {singleCandidateDetails?.profile?.about?.address}
                  </Typography>
                </Grid>
                {/* Item-4 */}
                <Grid item xs={4}>
                  <Typography>Zip Code</Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7}>
                  <Typography color={"textSecondary"}>
                    {singleCandidateDetails?.profile?.about?.fullName}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            {/* Skills Section */}
            <Paper
              variant="outlined"
              sx={{
                paddingBottom: "30px",
              }}
            >
              <Typography p={2}>Skills</Typography>
              <Divider />
              <Grid container px={4} mt={3} rowGap={2}>
                {singleCandidateDetails?.profile?.skills.map((skill) => {
                  return (
                    //  each item
                    <Grid item xs={6} key={skill}>
                      <Typography>{skill}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>

            {/* Employment Section */}
            <Paper
              variant="outlined"
              sx={{
                paddingBottom: "30px",
              }}
            >
              <Typography p={2}>Employment</Typography>
              <Divider />
              <Grid container px={4} mt={3} rowGap={2}>
                {/* Item-1 */}
                {singleCandidateDetails?.profile?.employmentDetails.map(
                  (employment) => {
                    return (
                      <React.Fragment key={employment.year}>
                        <Grid item xs={5}>
                          <Typography>{employment?.year}</Typography>
                          <Typography
                            color={"textSecondary"}
                            sx={{
                              fontSize: "12.5px",
                            }}
                          >
                            {employment?.position}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography>{employment?.role}</Typography>
                          <Typography
                            color={"textSecondary"}
                            sx={{
                              fontSize: "12.5px",
                            }}
                          >
                            {employment?.jobDescription}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    );
                  }
                )}

                {/* Item-2 */}
                {/* <Grid item xs={5}>
                  <Typography>2017-2019</Typography>
                  <Typography
                    color={"textSecondary"}
                    sx={{
                      fontSize: "12.5px",
                    }}
                  >
                    Junior
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>Trainee cum Project Manager</Typography>
                  <Typography
                    color={"textSecondary"}
                    sx={{
                      fontSize: "12.5px",
                    }}
                  >
                    Microsoft, TX, USA
                  </Typography>
                </Grid> */}
              </Grid>
            </Paper>

            {/* Education Section */}
            <Paper
              variant="outlined"
              sx={{
                paddingBottom: "30px",
              }}
            >
              <Typography p={2}>Education</Typography>
              <Divider />
              <Grid container px={4} mt={3} rowGap={2}>
                {/* Item-1 */}
                {singleCandidateDetails?.profile?.education.map((item) => {
                  return (
                    <React.Fragment key={item.year}>
                      <Grid item xs={5}>
                        <Typography>{item.year}</Typography>
                        <Typography
                          color={"textSecondary"}
                          sx={{
                            fontSize: "12.5px",
                          }}
                        >
                          {item.degree}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography>{item.specification}</Typography>
                        <Typography
                          color={"textSecondary"}
                          sx={{
                            fontSize: "12.5px",
                          }}
                        >
                          {item.university}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  );
                })}

                {/* Item-2 */}
                {/* <Grid item xs={5}>
                  <Typography>2011-2013</Typography>
                  <Typography
                    color={"textSecondary"}
                    sx={{
                      fontSize: "12.5px",
                    }}
                  >
                    Bachelor
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>
                    Bachelor Degree in Computer Engineering
                  </Typography>
                  <Typography
                    color={"textSecondary"}
                    sx={{
                      fontSize: "12.5px",
                    }}
                  >
                    Imperial College London
                  </Typography>
                </Grid> */}
                {/* Item-3 */}
                {/* <Grid item xs={5}>
                  <Typography>2009-2011</Typography>
                  <Typography
                    color={"textSecondary"}
                    sx={{
                      fontSize: "12.5px",
                    }}
                  >
                    School
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>Higher Secondary Education</Typography>
                  <Typography
                    color={"textSecondary"}
                    sx={{
                      fontSize: "12.5px",
                    }}
                  >
                    School of London, England
                  </Typography>
                </Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </CustomTabPanel>
      {/*  
          ======================================= 
               Interview Detials Tab Content  
          ======================================= 
      */}
      <CustomTabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs={12}>
            <Typography p={2}>Interview Details</Typography>
            <Divider></Divider>
          </Grid>
          {/* Side Section */}
          <Grid item xs={4} py={4}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              p={1.5}
              gap={1}
              onClick={() => {
                setInterviewDetailsIndex(0);
              }}
              sx={{
                ":hover": {
                  bgcolor: "#eef2f6",
                  cursor: "pointer",
                },
              }}
            >
              {/* <AccountCircleTwoToneIcon /> */}
              <Box>
                <Typography color={interviewDetailsIndex === 0 && "primary"}>
                  Technical Round (L1)
                </Typography>
                <Typography
                  color={"textSecondary"}
                  sx={{
                    fontSize: "12px",
                  }}
                ></Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              p={1.5}
              gap={1}
              onClick={() => {
                setInterviewDetailsIndex(1);
              }}
              sx={{
                ":hover": {
                  bgcolor: "#eef2f6",
                  cursor: "pointer",
                },
              }}
            >
              {/* <AccountCircleTwoToneIcon /> */}
              <Box>
                <Typography color={interviewDetailsIndex === 1 && "primary"}>
                  HR Round (L2)
                </Typography>
                <Typography
                  color={"textSecondary"}
                  sx={{
                    fontSize: "12px",
                  }}
                ></Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              p={1.5}
              gap={1}
              onClick={() => {
                setInterviewDetailsIndex(2);
              }}
              sx={{
                ":hover": {
                  bgcolor: "#eef2f6",
                  cursor: "pointer",
                },
              }}
            >
              {/* <AccountCircleTwoToneIcon /> */}
              <Box>
                <Typography color={interviewDetailsIndex === 2 && "primary"}>
                  Technical Round 2 (L3)
                </Typography>
                <Typography
                  color={"textSecondary"}
                  sx={{
                    fontSize: "12px",
                  }}
                ></Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              p={1.5}
              gap={1}
              onClick={() => {
                setInterviewDetailsIndex(3);
              }}
              sx={{
                ":hover": {
                  bgcolor: "#eef2f6",
                  cursor: "pointer",
                },
              }}
            >
              {/* <AccountCircleTwoToneIcon /> */}
              <Box>
                <Typography color={interviewDetailsIndex === 3 && "primary"}>
                  Client Round (L4)
                </Typography>
                <Typography
                  color={"textSecondary"}
                  sx={{
                    fontSize: "12px",
                  }}
                ></Typography>
              </Box>
            </Stack>
          </Grid>
          {/* Interview Rounds Details */}
          <Grid
            item
            xs={8}
            sx={{
              borderLeft: "1px solid #e0e0e0",
            }}
          >
            {interviewDetailsIndex === 0 && (
              <Round1
                interviewDetails={singleCandidateDetails?.interviewDetails}
              />
            )}
            {interviewDetailsIndex === 1 && (
              <Round2
                interviewDetails={singleCandidateDetails?.interviewDetails}
              />
            )}
            {interviewDetailsIndex === 2 && (
              <Round3
                interviewDetails={singleCandidateDetails?.interviewDetails}
              />
            )}
            {interviewDetailsIndex === 3 && (
              <Round4
                interviewDetails={singleCandidateDetails?.interviewDetails}
              />
            )}
            {/* <Stack direction={"row"} alignItems={"center"} p={1.5} gap={1}>
              <AccountCircleTwoToneIcon />
              <Box>
                <Typography color={"primary"}>Payment</Typography>
                <Typography
                  color={"textSecondary"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Add & Update Card
                </Typography>
              </Box>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} p={1.5} gap={1}>
              <AccountCircleTwoToneIcon />
              <Box>
                <Typography color={"primary"}>Change Password</Typography>
                <Typography
                  color={"textSecondary"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Update Profile Summary
                </Typography>
              </Box>
            </Stack> */}
          </Grid>
        </Grid>
        <Divider></Divider>
      </CustomTabPanel>
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// ------------------------------------------------------------------------------------
//              Interview Details Components (Tabs Content Components)
// ------------------------------------------------------------------------------------

function Round1({ interviewDetails }) {
  return (
    <>
      <Stack p={3} gap={1}>
        <Typography>{interviewDetails?.l1?.title}</Typography>
        <Typography
          color={"textSecondary"}
          sx={{
            fontSize: "12px",
          }}
        >
          {interviewDetails?.l1?.description}
        </Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} p={3} gap={1}>
        <Box>
          <Typography>Rating</Typography>
          <Typography
            color={"textSecondary"}
            sx={{
              fontSize: "12px",
            }}
          >
            Billing Information
          </Typography>
        </Box>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} p={3} gap={1}>
        <Box>
          <Typography>FeedBack</Typography>
          <Typography
            color={"textSecondary"}
            sx={{
              fontSize: "12px",
            }}
          >
            {interviewDetails?.l1?.feedback}
          </Typography>
        </Box>
      </Stack>
    </>
  );
}

function Round2({ interviewDetails }) {
  return (
    <>
      <Stack p={3} gap={1}>
        <Typography>{interviewDetails?.l2?.title}</Typography>
        <Typography
          color={"textSecondary"}
          sx={{
            fontSize: "12px",
          }}
        >
          {interviewDetails?.l2?.description}
        </Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} p={3} gap={1}>
        <Box>
          <Typography>Rating</Typography>
          <Typography
            color={"textSecondary"}
            sx={{
              fontSize: "12px",
            }}
          >
            Billing Information
          </Typography>
        </Box>
      </Stack>
    </>
  );
}

function Round3({ interviewDetails }) {
  return (
    <>
      <Stack p={3} gap={1}>
        <Typography>{interviewDetails?.l3?.title}</Typography>
        <Typography
          color={"textSecondary"}
          sx={{
            fontSize: "12px",
          }}
        >
          {interviewDetails?.l3?.description}
        </Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} p={3} gap={1}>
        <Box>
          <Typography>Rating</Typography>
          <Typography
            color={"textSecondary"}
            sx={{
              fontSize: "12px",
            }}
          >
            {interviewDetails?.l3?.rating}
          </Typography>
        </Box>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} p={3} gap={1}>
        <Box>
          <Typography>Remarks</Typography>
          <Typography
            color={"textSecondary"}
            sx={{
              fontSize: "12px",
            }}
          >
            {interviewDetails?.l3?.feedback}
          </Typography>
        </Box>
      </Stack>
    </>
  );
}

function Round4({ interviewDetails }) {
  return (
    <>
      <Stack p={3} gap={1}>
        <Typography>{interviewDetails?.l4?.title}</Typography>
        <Typography
          color={"textSecondary"}
          sx={{
            fontSize: "12px",
          }}
        >
          {interviewDetails?.l4?.description}
        </Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} p={3} gap={1}>
        <Box>
          <Typography>Rating</Typography>
          <Typography
            color={"textSecondary"}
            sx={{
              fontSize: "12px",
            }}
          >
            {interviewDetails?.l4?.rating}
          </Typography>
        </Box>
      </Stack>
    </>
  );
}
