import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { certificationsBaseUrl } from "../../../../apiCalls/apiCalls";
import { TokenContext } from "../../../protectedLayout";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  px: 4,
  py: 6,
  overflowY: "scroll",
  maxHeight: 500,
};

// Validation Schema
const validationSchema = Yup.object({
  employeeId: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, "No special characters allowed")
    .required("Employee Id is required"),
  employeeName: Yup.string().required("Employee Name is required"),
  certificationId: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, "No special characters allowed")
    .required("Certification Id is required"),
  certificationName: Yup.string()
    .matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed")
    .required("Certification Name is required"),
  issuedBy: Yup.string()
    .matches(/^[a-zA-Z0-9 ]*$/, "No special characters allowed")
    .required("Issued By is required"),
});

export default function BasicModal({ open, setOpen, setEmployeesData }) {
  const handleClose = () => setOpen(false);
  const token = useContext(TokenContext);

  const formik = useFormik({
    initialValues: {
      employeeId: "",
      employeeName: "",
      certificationId: "",
      certificationName: "",
      issuedBy: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const newEntry = {
        employeeId: values.employeeId,
        employeeName: values.employeeName,
        certificationId: values.certificationId,
        certificationName: values.certificationName,
        issuedBy: values.issuedBy,
      };

      try {
        const res = await axios.post(
          `${certificationsBaseUrl}/createcertification`,
          newEntry,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 201) {
          setEmployeesData(res.data);
          resetForm();
          toast.success("Added successfully");
          handleClose();
        }
      } catch (error) {
        console.error("Error adding certification:", error);
        toast.error("Failed to add certification");
      } finally {
        setSubmitting(false);
      }
    },
  });
  
  // Function to handle change in Employee Id and fetch Employee Name
  const handleChangeEmployeeId = async (event) => {
    const { value } = event.target;
    formik.handleChange(event); // Formik's handleChange
    try {
      const response = await axios.get(
        `${certificationsBaseUrl}/getEmpNameByEmpId/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const employeeName = response.data.employee.fullName;
      formik.setFieldValue("employeeName", employeeName); // Update employeeName in Formik
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New Certification
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Employee Id"
              name="employeeId"
              value={formik.values.employeeId}
              onChange={handleChangeEmployeeId} // Custom handleChange for employeeId
              onBlur={formik.handleBlur}
              error={formik.touched.employeeId && Boolean(formik.errors.employeeId)}
              helperText={formik.touched.employeeId && formik.errors.employeeId}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Employee Name"
              name="employeeName"
              value={formik.values.employeeName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.employeeName && Boolean(formik.errors.employeeName)}
              helperText={formik.touched.employeeName && formik.errors.employeeName}
              disabled
            />
            <TextField
              fullWidth
              margin="normal"
              label="Certification Name"
              name="certificationName"
              value={formik.values.certificationName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.certificationName && Boolean(formik.errors.certificationName)}
              helperText={formik.touched.certificationName && formik.errors.certificationName}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Certification Id"
              name="certificationId"
              value={formik.values.certificationId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.certificationId && Boolean(formik.errors.certificationId)}
              helperText={formik.touched.certificationId && formik.errors.certificationId}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Issued By"
              name="issuedBy"
              value={formik.values.issuedBy}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.issuedBy && Boolean(formik.errors.issuedBy)}
              helperText={formik.touched.issuedBy && formik.errors.issuedBy}
            />
            <Button type="submit" variant="contained" color="primary" disabled={formik.isSubmitting}>
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
