import { createSlice } from "@reduxjs/toolkit";

export const allCandidateDetails = [
  // single candidate info
  {
    profile: {
      profileInfo: {
        name: "Candidate 1",
        domain: "UI/UX Designer",
        email: "demo@gmail.com",
        phone: "+91 9999 9999 99",
        location: "Hyderabad",
      },
      about: {
        fullName: "Candidate 1",
        fatherName: "Candidate 1's Dad Name",
        address: "Street 110-B Kalians Bag, Dewan, M.P. INDIA",
        zipCode: 12345,
      },
      skills: [
        "Junior",
        "UX Researcher",
        "WordPress",
        "Graphic Designer",
        "HTML",
        "PHP",
      ],
      employmentDetails: [
        // recent job data
        {
          year: "Current",
          position: "Senior",
          role: "Senior UI/UX designer",
          jobDescription:
            "Perform task related to project manager with the 100+ team under my observation. Team management is key role in this company.",
        },
        // another job data - 2
        {
          year: "2017-2019",
          position: "Senior",
          role: "Trainee cum Project Manager UI/UX designer",
          jobDescription: "Microsoft, TX, USA",
        },
      ],
      education: [
        // recent degree data
        {
          year: "2014-2017",
          degree: "Master Degree",
          specification: "Master Degree in Computer Application",
          university: "University of Oxford, England",
        },
        // another degree data - 2
        {
          year: "2011-2013",
          degree: "Bachelor",
          specification: "Bachelor Degree in Computer Engineering",
          university: "Imperial College London",
        },
        // another degree data - 3
        {
          year: "2009-2011",
          degree: "School",
          specification: "Higher Secondary Education",
          university: "School of London, England",
        },
      ],
    },
    interviewDetails: {
      l1: {
        title: "Technical Round - 1",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur perferendis error numquam, ad iure voluptatibus modi odio itaque amet cumque cum ullam possimus impedit assumenda, asperiores esse, eaque architecto mollitia rerum illo. Distinctio deleniti soluta id? Rerum, repudiandae nam? Ipsum totam dolorem eum cupiditate quo labore, voluptates exercitationem magni consequatur!",
        rating: 4,
        feedback: "total feedback about the interview",
      },
      l2: {
        title: "HR Round - 1",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur perferendis error numquam, ad iure voluptatibus modi odio itaque amet cumque cum ullam possimus impedit assumenda, asperiores esse, eaque architecto mollitia rerum illo. Distinctio deleniti soluta id? Rerum, repudiandae nam? Ipsum totam dolorem eum cupiditate quo labore, voluptates exercitationem magni consequatur!",
        rating: 4.5,
        feedback: "total feedback about the interview",
      },
      l3: {
        title: "Technical Round - 2",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur perferendis error numquam, ad iure voluptatibus modi odio itaque amet cumque cum ullam possimus impedit assumenda, asperiores esse, eaque architecto mollitia rerum illo. Distinctio deleniti soluta id? Rerum, repudiandae nam? Ipsum totam dolorem eum cupiditate quo labore, voluptates exercitationem magni consequatur!",
        rating: 4.5,
        feedback: "total feedback about the interview",
      },
      l4: {
        title: "Client Round",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur perferendis error numquam, ad iure voluptatibus modi odio itaque amet cumque cum ullam possimus impedit assumenda, asperiores esse, eaque architecto mollitia rerum illo. Distinctio deleniti soluta id? Rerum, repudiandae nam? Ipsum totam dolorem eum cupiditate quo labore, voluptates exercitationem magni consequatur!",
        rating: 3.5,
        feedback: "total feedback about the interview",
      },
    },
  },
  // second candidate info
  {
    profile: {
      profileInfo: {
        name: "Candidate 2",
        domain: "Full Stack",
        email: "candidate2@gmail.com",
        phone: "+91 8888 9999 99",
        location: "Banglore",
      },
      about: {
        fullName: "Candidate 2",
        fatherName: "Candidate 2's Dad Name",
        address: "Street 110-B Kalians Bag, Dewan, M.P. INDIA",
        zipCode: 12345,
      },
      skills: ["Node.js", "React", "WordPress", "Javascript", "HTML", "PHP"],
      employmentDetails: [
        // recent job data
        {
          year: "Current",
          position: "Senior",
          role: "Senior UI/UX designer",
          jobDescription:
            "Perform task related to project manager with the 100+ team under my observation. Team management is key role in this company.",
        },
        // another job data - 2
        {
          year: "2017-2019",
          position: "Senior",
          role: "Trainee cum Project Manager UI/UX designer",
          jobDescription: "Microsoft, TX, USA",
        },
      ],
      education: [
        // recent degree data
        {
          year: "2014-2017",
          degree: "Master Degree",
          specification: "Master Degree in Computer Application",
          university: "University of Oxford, England",
        },
        // another degree data - 2
        {
          year: "2011-2013",
          degree: "Bachelor",
          specification: "Bachelor Degree in Computer Engineering",
          university: "Imperial College London",
        },
        // another degree data - 3
        {
          year: "2009-2011",
          degree: "School",
          specification: "Higher Secondary Education",
          university: "School of London, England",
        },
      ],
    },
    interviewDetails: {
      l1: {
        title: "Technical Round - 1",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur perferendis error numquam, ad iure voluptatibus modi odio itaque amet cumque cum ullam possimus impedit assumenda, asperiores esse, eaque architecto mollitia rerum illo. Distinctio deleniti soluta id? Rerum, repudiandae nam? Ipsum totam dolorem eum cupiditate quo labore, voluptates exercitationem magni consequatur!",
        rating: 4,
        feedback: "total feedback about the interview",
      },
      l2: {
        title: "HR Round - 1",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur perferendis error numquam, ad iure voluptatibus modi odio itaque amet cumque cum ullam possimus impedit assumenda, asperiores esse, eaque architecto mollitia rerum illo. Distinctio deleniti soluta id? Rerum, repudiandae nam? Ipsum totam dolorem eum cupiditate quo labore, voluptates exercitationem magni consequatur!",
        rating: 4.5,
        feedback: "total feedback about the interview",
      },
      l3: {
        title: "Technical Round - 2",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur perferendis error numquam, ad iure voluptatibus modi odio itaque amet cumque cum ullam possimus impedit assumenda, asperiores esse, eaque architecto mollitia rerum illo. Distinctio deleniti soluta id? Rerum, repudiandae nam? Ipsum totam dolorem eum cupiditate quo labore, voluptates exercitationem magni consequatur!",
        rating: 4.5,
        feedback: "total feedback about the interview",
      },
      l4: {
        title: "Client Round",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur perferendis error numquam, ad iure voluptatibus modi odio itaque amet cumque cum ullam possimus impedit assumenda, asperiores esse, eaque architecto mollitia rerum illo. Distinctio deleniti soluta id? Rerum, repudiandae nam? Ipsum totam dolorem eum cupiditate quo labore, voluptates exercitationem magni consequatur!",
        rating: 3.5,
        feedback: "total feedback about the interview",
      },
    },
  },
];

const allCandidateDetailsSlice = createSlice({
  name: "allCandidateDetails",
  initialState: allCandidateDetails,
  reducers: {
    singleCandidateDetails: (state, action) => {
      state = state[action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { singleCandidateDetails } = allCandidateDetailsSlice.actions;

export default allCandidateDetailsSlice.reducer;
