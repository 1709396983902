import { Box, Chip, Divider, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import AapmorLogo from "../../assets/AapmorLogo.png";
// import Aapmor360logo from "../assets/Aapmor360logo.png";
// import useSWR from "swr";

// const fetcher = (url) => fetch(url).then((res) => res.json());

import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function ParticularJobDescription({
  numApplicants,
  jobDetails,
  jobdescLoading,
}) {
  if (jobdescLoading) {
    return (
      <Box>
        <Stack direction={"row"} columnGap={4}>
          <Box width={"100%"}>
            <Skeleton sx={{ width: "10%" }}></Skeleton>
            <Skeleton sx={{ width: "30%" }}></Skeleton>
            <Skeleton sx={{ width: "40%" }}></Skeleton>
          </Box>
          <Skeleton sx={{ width: "20%", height: "100px" }}></Skeleton>
        </Stack>
        <Divider></Divider>
        <Skeleton sx={{ width: "40%" }}></Skeleton>
        <Skeleton sx={{ width: "40%" }}></Skeleton>
        <Skeleton sx={{ width: "100%", height: "300px", mt: -5 }}></Skeleton>
        <Stack direction={"row"} columnGap={3} mt={-5}>
          <Skeleton sx={{ width: "10%", height: "50px" }}></Skeleton>
          <Skeleton sx={{ width: "10%" }}></Skeleton>
          <Skeleton sx={{ width: "10%" }}></Skeleton>
        </Stack>
      </Box>
    );
  }
  const {
    position,
    about,
    createdAt,
    department,
    employmentType,
    experience,
    location,
    mandatorySkills,
    numOpenings,
    minExperience,
    maxExperience,
    minSalary,
    maxSalary,
    requirements,
    salary,
    status,
  } = jobDetails;
  const experienceLevels = {
    ENTRY_LEVEL: "Entry Level",
    MID_SENIOR_LEVEL: `${minExperience}-${maxExperience} Yrs`,
    DIRECTOR: "Director",
    EXECUTIVE: "Executive",
    NOT_APPLICABLE: "N/A",
  };

  // const formattedExperience = experienceLevels[experience] || experience;

  const getLocationCurrencySymbol = (location) => {
    if (location.toLowerCase() === "usa") {
      return (
        <AttachMoneyOutlinedIcon sx={{ fontSize: "13px", color: "#555b76" }} />
      );
    } else if (location.toLowerCase() === "india") {
      return <CurrencyRupeeIcon sx={{ fontSize: "13px", color: "#555b76" }} />;
    } else {
      return <CurrencyRupeeIcon sx={{ fontSize: "13px", color: "#555b76" }} />;
    }
  };

  const locationCurrencySymbol = getLocationCurrencySymbol(location);

  function calculatePostAge(postedDate) {
    const postedDateUpdated = new Date(postedDate);
    const currentDate = new Date();
    // Set both dates to the same time (midnight)
    postedDateUpdated.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    // Calculating the time difference of two dates
    let timeDiff = currentDate.getTime() - postedDateUpdated.getTime();
    // Calculating the no. of days between two dates
    let daysDiff = Math.abs(Math.round(timeDiff / (1000 * 3600 * 24)));

    if (daysDiff === 0) {
      return "Today";
    } else if (daysDiff === 1) {
      return "1 day ago";
    } else if (daysDiff > 29) {
      return `${Math.abs(Math.round(daysDiff / 30))} months ago`;
    } else {
      return `${daysDiff} days ago`;
    }
  }

  const departmentMap = {
    fullStack: "Full Stack",
    testing: "Testing",
    uiUx: "UI/UX",
    dataScience: "Data Science",
    devops: "Devops",
    aiMl: "AI/ML",
    business: "Business",
    hr: "HR",
  };

  const employmentTypemap = {
    FULL_TIME: "Full Time",
    PART_TIME: "Part Time",
    INTERNSHIP: "Internship",
    CONTRACT: "Contract",
  };
  return (
    <>
      <Box
        sx={{
          border: "1px solid rgb(224, 224, 224)",
          borderRadius: 2,
          bgcolor: "white",
        }}
      >
        <Stack
          sx={{
            // margin: 8,
            mt: 2,
            // mb: 2,
            mx: 1,
            padding: 2,
            borderRadius: 3,
          }}
        >
          <Typography fontWeight={700}>{position}</Typography>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} alignItems={"center"} gap={2} pb={1}>
              <Typography
                sx={{ fontSize: 14 }}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <WorkOutlineOutlinedIcon sx={{ fontSize: 14, color: "grey" }} />
                {experienceLevels[experience]}
              </Typography>
              |
              <Typography
                sx={{ fontSize: 14 }}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                {locationCurrencySymbol}
                {salary === "notSpecified"
                  ? "Not Disclosed"
                  : `${minSalary}-${maxSalary} ${
                      location.toLowerCase() === "india"
                        ? "LPA"
                        : location.toLowerCase() === "usa"
                        ? "kPA"
                        : ""
                    }`}
              </Typography>
            </Stack>
            <Box>
              <img
                src={AapmorLogo}
                style={{ width: "120px" }}
                alt="AamporLogo"
              />
            </Box>
          </Stack>
          <Typography>
            <FmdGoodOutlinedIcon sx={{ fontSize: 15, color: "grey" }} />{" "}
            {location}
          </Typography>

          <Divider sx={{ py: 1 }} />
          <Stack direction={"row"} alignItems={"center"} gap={2} my={1}>
            <Typography>
              <span style={{ color: "grey" }}>Posted:</span>{" "}
              {calculatePostAge(createdAt)}
            </Typography>
            <Typography>
              {" "}
              <span style={{ color: "grey" }}>Applicants:</span> {numApplicants}
            </Typography>
          </Stack>
          <Typography>
            <span style={{ color: "grey" }}>No.of.openings:</span> {numOpenings}
          </Typography>
        </Stack>
        <Stack
          sx={{
            // mt: 2,
            mb: 2,
            mx: 1,
            padding: 2,
            borderRadius: 3,
          }}
        >
          <Typography fontSize={"17px"} fontWeight={600} mb={2}>
            Job description
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 13,
              marginBottom: 1,
            }}
          >
            About the Role:
          </Typography>

          <span
            dangerouslySetInnerHTML={{
              __html: about,
            }}
          ></span>
          <Typography
            sx={{
              marginTop: 1.5,
              marginBottom: 1,
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Specific Knowledge & Skills Required:
          </Typography>
          <span
            dangerouslySetInnerHTML={{ __html: requirements }}
            style={{ color: "#353535", fontSize: "15px" }}
          ></span>
          <Typography sx={{ fontSize: 13 }} mt={2}>
            <span style={{ fontWeight: 600 }}>Role:</span> {position}
          </Typography>
          <Typography sx={{ fontSize: 13 }}>
            <span style={{ fontWeight: 600 }}>Department:</span>{" "}
            {departmentMap[department]}
          </Typography>
          <Typography sx={{ fontSize: 13 }}>
            <span style={{ fontWeight: 600 }}>Employment Type:</span>{" "}
            {employmentTypemap[employmentType]}
          </Typography>

          <Box>
            <Typography sx={{ fontSize: 13, fontWeight: 600, my: 1 }}>
              Key Skills:
            </Typography>
            {mandatorySkills.map((value, i) => (
              <Chip
                key={i}
                label={value}
                variant="outlined"
                sx={{
                  mx: 1,
                  bgcolor: "#EEEEEE",
                  px: 1,
                  borderRadius: 0.5,
                  fontSize: "12px",
                  borderColor: "#EEEEEE",
                }}
              />
            ))}
          </Box>

          <Stack direction={"row"} mt={5} alignItems={"center"} gap={2}>
            <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
              Status:
            </Typography>
            <span
              style={{
                backgroundColor:
                  status === "open"
                    ? "rgb(240, 255, 248)"
                    : "rgb(254, 250, 224)",
                color:
                  status === "open" ? "rgb(12, 66, 66)" : "rgb(129, 127, 21)",
                padding: 5,
                fontWeight: 600,
                borderRadius: 2,
                fontSize: 13,
              }}
            >
              {status === "open" ? "Open" : "Closed"}
            </span>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default ParticularJobDescription;
