import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

// ---------------------------------------
//        Goal Setting Form Imports
// ---------------------------------------
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// Date-Pickers
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { Link as RouterLink } from "react-router-dom";
import "./ManagementView.css";

function ManagementView() {
  return (
    <>
      <Stack
        sx={{
          mx: 6,
          my: 4,
        }}
      >
        <Link
          sx={{
            textDecoration: "none",
          }}
          component={RouterLink}
          to="/appraisal/employee-view"
        >
          <Button
            variant="contained"
            sx={{
              float: "right",
            }}
          >
            Employee View
          </Button>
        </Link>
        <Stack
          direction={"row"}
          gap={"20px"}
          sx={{
            mx: 6,
            my: 8,
          }}
        >
          <Link
            sx={{
              textDecoration: "none",
            }}
            component={RouterLink}
            to="/appraisal/view-all-goals"
          >
            <Card
              sx={{
                minWidth: 275,
                minHeight: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ":hover": {
                  bgcolor: "#bdbdbd",
                  boxShadow: "3px 2px 0 #707070",
                },
              }}
            >
              <CardContent>
                <Stack
                  direction={"row"}
                  alignContent={"center"}
                  spacing={2}
                  sx={{
                    height: 30,
                  }}
                >
                  <Typography className="routeLink" sx={{ fontSize: 14 }}>
                    View All Employee Goals
                  </Typography>
                  <Icon>
                    <VisibilityRoundedIcon />
                  </Icon>
                </Stack>
              </CardContent>
            </Card>
          </Link>
          <Link
            sx={{
              textDecoration: "none",
            }}
            component={RouterLink}
            to="/appraisal/create-new-goal"
          >
            <Card
              sx={{
                minWidth: 275,
                minHeight: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ":hover": {
                  bgcolor: "#bdbdbd",
                  boxShadow: "3px 2px 0 #707070",
                },
              }}
            >
              <CardContent>
                <Stack
                  direction={"row"}
                  alignContent={"center"}
                  spacing={2}
                  sx={{
                    height: 30,
                  }}
                >
                  <Typography className="routeLink" sx={{ fontSize: 14 }}>
                    Create a New Template
                  </Typography>
                  <Icon>
                    <AddRoundedIcon />
                  </Icon>
                </Stack>
              </CardContent>
            </Card>
          </Link>
          <Link
            sx={{
              textDecoration: "none",
            }}
            component={RouterLink}
            to="/appraisal/assign-goal"
          >
            <Card
              sx={{
                minWidth: 275,
                minHeight: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ":hover": {
                  bgcolor: "#bdbdbd",
                  boxShadow: "3px 2px 0 #707070",
                },
              }}
            >
              <CardContent>
                <Stack
                  direction={"row"}
                  alignContent={"center"}
                  spacing={2}
                  sx={{
                    height: 30,
                  }}
                >
                  <Typography className="routeLink" sx={{ fontSize: 14 }}>
                    Assign Goals
                  </Typography>
                  <Icon>
                    <AddRoundedIcon />
                  </Icon>
                </Stack>
              </CardContent>
            </Card>
          </Link>
        </Stack>
      </Stack>
    </>
  );
}

export default ManagementView;

function HrView() {
  return (
    <>
      <Grid item flex={8} sx={{ padding: "50px 50px" }}>
        {/* Remove Grid if added in parent component */}
        <Typography
          variant="h4"
          sx={{
            mb: 3,
          }}
        >
          Appraisal
        </Typography>

        <Divider
          sx={{
            mb: 2,
          }}
        ></Divider>
        <Box
          sx={{
            mb: 3,
          }}
        >
          <Button variant="outlined">
            <AddIcon />
            Add Goals
          </Button>
        </Box>
        <Box>
          <Button variant="outlined">
            <AddIcon />
            Create Goal Template
          </Button>
        </Box>
        {/* Goal Setting Form */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            m: 2,
            borderRadius: "10px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant={"h5"}>Goal Setting Form</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider
                sx={{
                  bgcolor: "#a8adba",
                  mb: 2,
                }}
              ></Divider>
            </Grid>
            {/* Employee Details */}
            <Grid item xs={12}>
              <Typography variant={"h6"}>Employee Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider
                sx={{
                  bgcolor: "#a8adba",
                  mb: 2,
                }}
              ></Divider>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"body"}>Emp Name : </Typography>
              <Typography variant={"body"}>Jagadeesh</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"body"}>Department : </Typography>
              <Typography variant={"body"}>Full Stack</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider
                sx={{
                  bgcolor: "#a8adba",
                  mb: 2,
                }}
              ></Divider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-basic-1"
                label="Goal Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-basic-1"
                label="Category"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Priority
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="low"
                    control={<Radio />}
                    label="Low"
                  />
                  <FormControlLabel
                    value="medium"
                    control={<Radio />}
                    label="Medium"
                  />
                  <FormControlLabel
                    value="high"
                    control={<Radio />}
                    label="High"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker label="Due Date" />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="Description"
                multiline
                sx={{
                  width: "100%",
                }}
                minRows={4}
                maxRows={4}
              />
            </Grid>
            <Grid item xs={12} container justifyContent={"center"}>
              <Button
                sx={{
                  px: 5,
                  py: 1,
                }}
                variant="contained"
              >
                Add Goal
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

export { HrView };
