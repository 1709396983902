import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  getJobApplicantswithJobName,
  jobApplicantBaseUrl,
} from "../../apiCalls/apiCalls";
import { ToastContainer, toast } from "react-toastify";
import ViewApplicantDetailsModal from "../jobOpenings/ViewApplicantDetailsModal";
import { useNavigate } from "react-router-dom";
import { TokenContext } from "../protectedLayout";

function CompleteApplicantDetails() {
  // axios.defaults.withCredentials = true;
  const token = useContext(TokenContext);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tableRowToEdit, setTableRowToEdit] = useState("");
  const [loading, setLoading] = useState(true);
  const [tableRowToView, setTableRowToView] = useState("");
  const [openViewModal, setOpenViewModal] = useState(false);

  const fetchRows = async () => {
    try {
      const response = await axios.get(`${getJobApplicantswithJobName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRows(response.data);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error("There was an error fetching the data!", error);
      setLoading(false); // Handle loading state in case of error
    }
  };

  useEffect(() => {
    fetchRows();
  }, []);

  console.log({ rows });

  const columns = [
    {
      field: "position",
      headerName: "Position",
      sortable: true,
      editable: false,
      width: 140,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <Box mt={1.5}>
          <Typography>
            {params.row.firstName || ""} {params.row.lastName || ""}
          </Typography>
        </Box>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      headerName: "Source",
      field: "source",
      editable: false,
      sortable: true,
      width: 130,
      description:
        "Application source (ex: LinkedIn, Naukri, employee reference)",
    },
    {
      field: "resume",
      headerName: "Resume",
      editable: false,
      sortable: false,
      width: 70,
      disableExport: true,
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => {
              console.log(params);
              if (params.row.resume) {
                handleDownloadResume(
                  params.row.resume,
                  `${params.row.firstName}${params.row.lastName}Resume`
                );
              }
            }}
          >
            <Tooltip title="Download Resume">
              <InsertDriveFileIcon sx={{ fontSize: "20px" }} />
            </Tooltip>
          </IconButton>
        </Box>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      resizable: false,
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <Stack direction={"row"} mt={1} gap={1}>
            <IconButton
              aria-label="view"
              size="small"
              onClick={(e) => handleOpenViewModal(params.row)}
            >
              <Tooltip title="View Details">
                <VisibilityOutlinedIcon sx={{ fontSize: "19px" }} />
              </Tooltip>
            </IconButton>
            <Tooltip title="Delete Applicant">
              <IconButton
                sx={{ width: "32px", color: "red" }}
                onClick={(e) => handleOpenDeleteModal(params.row)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
      ),
    },
  ];

  const getRowId = (row) => row._id;

  function handleDownloadResume(base64String, fileName) {
    base64String = base64String.split(",")[1];
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const handleOpenDeleteModal = (rowToDelete) => {
    setTableRowToEdit(rowToDelete);
    setOpenDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
    setTableRowToEdit("");
  };

  const handleOpenViewModal = (rowToView) => {
    setTableRowToView(rowToView);
    setOpenViewModal(true);
  };

  const handleViewModalClose = () => {
    setOpenViewModal(false);
    setTableRowToView("");
  };

  async function handleApplicantDelete(rowId) {
    try {
      const response = await axios.delete(`${jobApplicantBaseUrl}${rowId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Row deleted successfully", response);
      fetchRows();
      setOpenDeleteModal(false);
      if (response.status === 200) {
        toast.success("Applicant details deleted successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Row delete failed", error);
    }
  }

  return (
    <Box px={4}>
      <Stack direction={"row"} alignItems={"center"} mb={4}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Complete Applicants List</Typography>
      </Stack>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="60vh"
        >
          <CircularProgress />
        </Box>
      ) : rows.length === 1 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="60vh"
        >
          <Typography variant="h5" color="textSecondary">
            No Details found
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnResize={true}
          getRowId={(row) => row._id}
          sx={{
            backgroundColor: "white",
            ".css-t89xny-MuiDataGrid-columnHeaderTitle": { fontWeight: 700 },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSize={10}
          pageSizeOptions={[10]}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          disableRowSelectionOnClick
        />
      )}
      <Modal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            px: 4,
            py: 5,
            maxHeight: 500,
            textAlign: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="body1"
            component="paragraph"
            align={"center"}
            fontWeight={"bold"}
          >
            {`Are you sure you want to delete these applicant details?`}
          </Typography>
          <Stack direction={"row"} mt={3} gap={3} justifyContent={"center"}>
            <Button variant="outlined" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{
                bgcolor: "#FF4B4B",
                borderColor: "#FF4B4B",
                color: "white",
                ":hover": {
                  bgcolor: "white",
                  color: "#FF4B4B",
                  borderColor: "#FF4B4B",
                },
              }}
              onClick={(e) => handleApplicantDelete(tableRowToEdit._id)}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
      <ViewApplicantDetailsModal
        tableRowToView={tableRowToView}
        openViewModal={openViewModal}
        handleClose={handleViewModalClose}
        handleDownloadResume={handleDownloadResume}
      />
      <ToastContainer />
    </Box>
  );
}

export default CompleteApplicantDetails;
