import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";

function JobSearch({
  uniqueSkillsAndDepartments,
  uniqueLocations,
  handleSubmit,
  inputKeywordValue,
  inputLocationValue,
  setInputKeywordValue,
  setInputLocationValue,
}) {
  const handleReset = () => {
    window.location.reload();
  };

  return (
    <Box display={"flex"} gap={4} sx={{ pl: 7, mt: 5 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e, inputKeywordValue, inputLocationValue);
        }}
        style={{ display: "flex", gap: "16px", width: "100%" }}
      >
        <Autocomplete
          sx={{ width: "39%" }}
          forcePopupIcon={false}
          inputValue={inputKeywordValue}
          onInputChange={(event, newInputValue) => {
            setInputKeywordValue(newInputValue);
          }}
          options={uniqueSkillsAndDepartments?.map((skill) => ({
            label: skill,
            value: skill,
          }))}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Search by Skills / Job Title"
              InputLabelProps={{ sx: { fontSize: 14 } }}
              sx={{ width: "100%", backgroundColor: "white" }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <AppRegistrationOutlinedIcon />
                  </InputAdornment>
                ),
                style: {
                  ...params.InputProps.style,
                  borderRadius: 0,
                },
              }}
            />
          )}
        />
        <Autocomplete
          sx={{ width: "34%" }}
          forcePopupIcon={false}
          inputValue={inputLocationValue}
          onInputChange={(event, newInputValue) => {
            setInputLocationValue(newInputValue);
          }}
          options={uniqueLocations?.map((location) => ({
            label: location,
            value: location,
          }))}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Search For Location"
              InputLabelProps={{ sx: { fontSize: 14 } }}
              sx={{ width: "100%", backgroundColor: "white" }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <LocationOnOutlinedIcon />
                  </InputAdornment>
                ),
                style: {
                  ...params.InputProps.style,
                  borderRadius: 0,
                },
              }}
            />
          )}
        />
        <Button
          variant="contained"
          sx={{ fontSize: 12, bgcolor: "rgb(49, 38, 228)" }}
          type="submit"
        >
          Find Jobs
        </Button>
        <Button variant="outlined" sx={{ fontSize: 12 }} onClick={handleReset}>
          Reset
        </Button>
      </form>
    </Box>
  );
}

export default JobSearch;
