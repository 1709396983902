import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Rating,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // or "scroll" if you always want the scrollbar to appear
};
const InterviewDetails = ({
  userData,
  submitRecruitFeedback,
  setRecruitFeedback,
  recruitFeedback,
  interviewStatus,
  handlefeedbackModalClose,
  openfeedbackModal,
  setOpenfeedbackModal,
  formErrors,
  setIsSubmitFeedback,
  isSubmitFeedback,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecruitFeedback((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRatingChange = (name, value) => {
    setRecruitFeedback((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const formatInterviewDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatInterviewTime = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  useEffect(() => {
    if (userData.length > 0) {
      // Assuming the first item in userData has the interview details
      const { interviewType, interviewRound, interviewer, interviewMode } =
        userData[0];
      setRecruitFeedback((prevDetails) => ({
        ...prevDetails,
        interviewType,
        interviewRound,
        interviewer,
        interviewMode,
      }));
    }
  }, [userData]);

  // if (userData[0].candidateDetails[0].interviewRound?.length > 0) {
  //   userData[0].candidateDetails[0].interviewRound.filter(
  //     (e) => e.interviewType === userData[0].interviewType
  //   );
  // }
  const filter = userData[0].candidateDetails[0].interviewRounds.filter(
    (e) => e.interviewType === userData[0].interviewType
  );

  return (
    <>
      {userData.map((details) => (
        <Box p={3} width={"100%"} key={details?.id}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            mb={5}
            alignItems={"center"}
          >
            <Stack direction={"row"} gap={2}>
              <Typography sx={{ fontWeight: 600 }}>
                Interview Details
              </Typography>
              <Typography
                sx={{
                  // border: "1px solid",
                  px: 1,
                  // borderRadius: 3,
                  fontWeight: 600,
                  color: filter.length > 0 ? "green" : "red",
                  // fontSize: 15,
                }}
              >
                {!filter.length > 0 ? "Pending" : "Completed"}
              </Typography>
            </Stack>
            {!filter.length > 0 ? (
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <Button
                  variant="contained"
                  onClick={() => setOpenfeedbackModal(true)}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: 12,
                    bgcolor: "rgb(99, 91, 255)",
                  }}
                >
                  <AddIcon />
                  Add Feedback
                </Button>
                <IconButton> {/* <MoreVertIcon /> */}</IconButton>
              </Stack>
            ) : null}
          </Stack>
          <Grid container rowSpacing={3}>
            <Grid item xs={4}>
              <Stack>
                <Typography sx={{ color: "grey" }}>Interview Round</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {details?.interviewRound}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Typography sx={{ color: "grey" }}>Interview Type</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {details?.interviewType}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Typography sx={{ color: "grey" }}>Interview Mode</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {details?.interviewMode}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Typography sx={{ color: "grey" }}>Interview Date</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {formatInterviewDate(details?.interviewDate)}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Typography sx={{ color: "grey" }}>Interview Time</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {formatInterviewTime(details?.interviewStartTime)} to{" "}
                  {formatInterviewTime(details?.interviewEndTime)}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Typography sx={{ color: "grey" }}>Meeting Link</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {details.meetingLink ? details.meetingLink : "NA"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Modal
            open={openfeedbackModal}
            onClose={handlefeedbackModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} textAlign={"center"}>
              <Stack>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  sx={{ textDecoration: "underline", mb: 2 }}
                >
                  Candidate Feedback
                </Typography>
              </Stack>
              <Grid container rowSpacing={5} p={2}>
                <Grid item xs={4}>
                  <Typography sx={{ color: "grey" }}>Interview Type</Typography>
                  <Typography sx={{ fontWeight: 600 }}>
                    {details?.interviewType}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ color: "grey" }}>Interview Mode</Typography>
                  <Typography sx={{ fontWeight: 600 }}>
                    {details.interviewMode}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Interview Status</InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Interview Type"
                      InputLabelProps={{
                        sx: { fontSize: 8 }, // Decrease font size here
                      }}
                      sx={{ textAlign: "left" }}
                      value={recruitFeedback.status}
                      onChange={handleChange}
                      name="status"
                    >
                      <MenuItem value={"On Hold"}>On Hold</MenuItem>
                      <MenuItem value={"Selected"}>Selected</MenuItem>
                      <MenuItem value={"Rejected"}>Rejected</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                      {formErrors.status}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "grey" }}>
                    Technical Skills
                  </Typography>

                  <Rating
                    value={recruitFeedback.techRating}
                    onChange={(event, newValue) =>
                      handleRatingChange("techRating", newValue)
                    }
                    sx={{ color: "gold" }}
                    precision={0.5}
                  />
                  <FormHelperText
                    sx={{ color: "red", fontSize: 12, textAlign: "center" }}
                  >
                    {formErrors.techRating}
                  </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "grey" }}>
                    Communication skills
                  </Typography>
                  <Rating
                    value={recruitFeedback.commRating}
                    onChange={(event, newValue) =>
                      handleRatingChange("commRating", newValue)
                    }
                    sx={{ color: "gold" }}
                    precision={0.5}
                  />
                  <FormHelperText
                    sx={{ color: "red", fontSize: 12, textAlign: "center" }}
                  >
                    {formErrors.commRating}
                  </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "grey" }}>
                    Critical Thinking
                  </Typography>
                  <Rating
                    value={recruitFeedback.criticalThinkingRating}
                    onChange={(event, newValue) =>
                      handleRatingChange("criticalThinkingRating", newValue)
                    }
                    sx={{ color: "gold" }}
                    precision={0.5}
                  />
                  <FormHelperText
                    sx={{ color: "red", fontSize: 12, textAlign: "center" }}
                  >
                    {formErrors.criticalThinkingRating}
                  </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "grey" }}>Soft Skills</Typography>
                  <Rating
                    value={recruitFeedback.softSkills}
                    onChange={(event, newValue) =>
                      handleRatingChange("softSkills", newValue)
                    }
                    sx={{ color: "gold" }}
                    precision={0.5}
                  />
                  <FormHelperText
                    sx={{ color: "red", fontSize: 12, textAlign: "center" }}
                  >
                    {formErrors.softSkills}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: "grey" }}>Feedback</Typography>
                  <TextField
                    id="feedback-text"
                    name="feedback"
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={recruitFeedback.feedback}
                    onChange={handleChange}
                  />
                  <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                    {formErrors.feedback}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    disabled={isSubmitFeedback}
                    onClick={(e) => {
                      setIsSubmitFeedback(true);
                      submitRecruitFeedback(e);
                    }}
                  >
                    Submit Feedback
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Box>
      ))}
    </>
  );
};

export default InterviewDetails;
