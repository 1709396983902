import { Box, Grid } from "@mui/material";
import React from "react";
// import application_form from "../../assets/applicaion_form.png";
import status from "../../assets/status.png";
import onboarding from "../../assets/onboarding.png";
import new_job from "../../assets/new_job.png";
import CardItem from "../card/card";
import board from "../../assets/Board.png";
import interview from "../../assets/interview.png";
// import SideBar from "../sidebar/sidebar";
// import Quotes from "./Quotes";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";

const Recruitment = () => {
  const [cardData, setCardData] = useState([
    {
      heading: "New Job",
      image: new_job,
      cardText: "Add a new job vacancy",
      path: "/recruitment/job-opening-form",
      show: false,
    },
    {
      heading: "Recent Openings",
      image: null,
      cardText: "Check out recent openings from the company",
      path: "/recruitment/job-openings",
      show: false,
    },
    {
      heading: "Recruitment Status",
      image: board,
      cardText: "View status of all applications",
      path: "/recruitment/recruitment-list",
      show: false,
    },
    {
      heading: "Interviewer Board",
      image: interview,
      cardText: "Interview the candidates",
      path: "/recruitment/interview-board",
      show: false,
    },
    {
      heading: "On Boarding",
      image: onboarding,
      cardText: "Send Onboarding link",
      path: "/recruitment/onboarding-form",
      show: false,
    },
    {
      heading: "Careers",
      image: null,
      cardText: "Click to view how jobs are displayed to the public",
      path: "/careers/job-openings",
      show: false,
    },
    {
      heading: "Applicant Details",
      image: null,
      cardText: "Click to view all applicant details",
      path: "/recruitment/applicant-details",
      show: false,
    },
  ]);

  useEffect(() => {
    const userAccessCookie = JSON.parse(Cookies.get("access"));
    if (userAccessCookie) {
      const updatedCardData = cardData.map((card) => {
        switch (card.heading) {
          case "Recruitment Status":
            return {
              ...card,
              show: userAccessCookie.includes(
                "Orgx_Recruitment_Status_View_And_write"
              ),
            };
          case "On Boarding":
            return {
              ...card,
              show: userAccessCookie.includes("Orgx_On_Boarding_View_And_Write"),
            };
          case "New Job":
            return {
              ...card,
              show: userAccessCookie.includes("Orgx_New_Job_View_And_Write"),
            };
          case "Interviewer Board":
            return {
              ...card,
              show: userAccessCookie.includes("Orgx_Interviewer_Board_View"),
            };
          case "Recent Openings":
            return {
              ...card,
              show: userAccessCookie.includes("Orgx_Recent_Openings_View"),
            };
          case "Careers":
            return {
              ...card,
              show: userAccessCookie.includes("Orgx_Careers_View"),
            };
          case "Applicant Details":
            return {
              ...card,
              show: userAccessCookie.includes("Orgx_Careers_View"),
            };
          default:
            return card;
        }
      });
      setCardData(updatedCardData);
    }
  }, []);

  return (
    <Grid flex={8} item container sx={{ p: 1, flexDirection: "column" }}>
      <Grid item>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 3,
            p: 2,
          }}
        >
          {cardData.map(
            (eachCard) =>
              eachCard.show && (
                <CardItem cardDetails={eachCard} key={eachCard.heading} />
              )
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Recruitment;
