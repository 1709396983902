// Common Imports

import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Main Component Imports

import { Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

// Cutomized Stepper Section Imports

import PropTypes from "prop-types";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

// View All Employees Imports

import { DataGrid, gridClasses } from "@mui/x-data-grid";
import "./ViewAllEmployeeGoals.css";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

// ----------------------------------xxxxxxxxxxx------------------------------------------

// data - variables
const steps = ["Select Employee's", "Select Goal Template", "Verify Details"];

const templates = {
  "Junior-Software-Engg-Template-1": [
    {
      goalTitle: "Title - 1",
      goalDesc: "goal desc - 1 for the junior software engg template - 1 ",
    },
    {
      goalTitle: "Title - 2",
      goalDesc: "goal desc - 2 for the junior software engg template - 1 ",
    },
    {
      goalTitle: "Title - 3",
      goalDesc: "goal desc - 3 for the junior software engg template - 1 ",
    },
  ],
  "Senior-Software-Engg-Template-1": [
    {
      goalTitle: "Title - 1",
      goalDesc: "goal desc - 1 for the senior software engg template - 1 ",
    },
    {
      goalTitle: "Title - 2",
      goalDesc: "goal desc - 2 for the senior software engg template - 1 ",
    },
    {
      goalTitle: "Title - 3",
      goalDesc: "goal desc - 3 for the senior software engg template - 1 ",
    },
  ],
  "Hr-Template-1": [
    {
      goalTitle: "Title - 1",
      goalDesc: "goal desc - 1 for the Hr template - 1 ",
    },
    {
      goalTitle: "Title - 2",
      goalDesc: "goal desc - 2 for the Hr template - 1 ",
    },
    {
      goalTitle: "Title - 3",
      goalDesc: "goal desc - 3 for the Hr template - 1 ",
    },
  ],
};

// *******************************************************************
//                       Main Component
// *******************************************************************

function AssignGoal() {
  const [activeStepNum, setActiveStepNum] = useState(0);
  const [activeCard, setActiveCard] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedEmp, setSelectedEmp] = React.useState([]);

  // variables

  const availableTemplates = [
    "Junior Software Engg.",
    "Senior Software Engg.",
    "HR",
  ];

  function handleSelect(id) {
    setActiveCard(availableTemplates.find((e) => e === id));
  }

  return (
    <Stack
      gap={"20px"}
      sx={{
        mx: 6,
        my: 3,
        mb: 5,
      }}
    >
      <CustomizedSteppers activeStepNum={activeStepNum} />

      {/*      Select Employees from the Table      */}

      {activeStepNum === 0 && (
        <ViewAllEmployeeGoals
          activeStepNum={activeStepNum}
          setActiveStepNum={setActiveStepNum}
          selectedEmp={selectedEmp}
          setSelectedEmp={setSelectedEmp}
        />
      )}

      {/*          Select a Goal Template           */}

      {activeStepNum === 1 && (
        <>
          {/* Navigation Buttons */}
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button
              variant="outlined"
              onClick={() => {
                setActiveStepNum(activeStepNum - 1);
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setActiveStepNum(activeStepNum + 1);
              }}
              disabled={selectedTemplate ? false : true}
            >
              Next
            </Button>
          </Stack>
          {/* Goal Template Cards */}
          <Paper
            sx={{
              p: 10,
              pb: 5,
              px: 6,
            }}
          >
            <Stack direction={"row"} spacing={3}>
              {availableTemplates.map((e) => (
                <Card
                  key={e}
                  sx={{
                    minWidth: 250,
                    minHeight: 210,
                    ":hover": {
                      cursor: "pointer",
                      bgcolor:
                        activeCard === e ? "#a9a9b3" : "rgb(63, 68, 136, 0.2)",
                    },
                    bgcolor: activeCard === e && "#a9a9b3",
                    // color: activeCard === e && "#fff",
                  }}
                  variant="outlined"
                  onClick={() => {
                    handleSelect(e);

                    const xyz = Object.keys(templates).find((value) => {
                      if (e === "Junior Software Engg.") {
                        return value === "Junior-Software-Engg-Template-1";
                      } else if (e === "Senior Software Engg.") {
                        return value === "Senior-Software-Engg-Template-1";
                      } else {
                        return value === "Hr-Template-1";
                      }
                    });
                    console.log(`selected template:`, templates[xyz]);
                    setSelectedTemplate(templates[xyz]);
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color={!activeCard === e ? "text.secondary" : "white"}
                      gutterBottom
                    >
                      2024-25
                    </Typography>
                    <Typography variant="h5" component="div">
                      {e}
                    </Typography>
                    <Typography
                      sx={{ mb: 1.5 }}
                      color={!activeCard === e ? "text.secondary" : "white"}
                    >
                      Template - 1
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small">View</Button>
                  </CardActions>
                </Card>
              ))}
            </Stack>
          </Paper>
        </>
      )}

      {/*         Summary or Verify Details         */}

      {activeStepNum === 2 && (
        <>
          {/* Navigation Buttons */}
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button
              variant="outlined"
              onClick={() => {
                setActiveStepNum(activeStepNum - 1);
              }}
            >
              Back
            </Button>
          </Stack>
          {/* Goals Details of Selected Template -- Section */}
          <Paper
            variant="outlined"
            sx={{
              p: 5,
            }}
          >
            <Typography
              sx={{
                mb: 3,
              }}
            >
              Goals
            </Typography>
            <Typography
              sx={{
                mb: 3,
              }}
            >
              Goal Template : 2024 {activeCard} Template-1
            </Typography>
            <Stack direction={"row"} flexWrap={"wrap"} spacing={3}>
              {selectedTemplate.map((e) => (
                <Card
                  key={e.goalDesc}
                  variant="outlined"
                  sx={{
                    bgcolor: "transparent",
                    borderRadius: 6,
                    "&.MuiPaper-root.MuiCard-root": {
                      m: 1,
                    },
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.75 }}
                    >
                      <Box
                        sx={{
                          p: 0,
                        }}
                      >
                        <Typography>{e.goalTitle}</Typography>
                        <Typography color={"textSecondary"}>
                          Goal Desc : {e.goalDesc}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          </Paper>
          {/* Selected Employee Cards Section */}
          <Paper
            variant="outlined"
            sx={{
              p: 5,
            }}
          >
            <Typography
              sx={{
                mb: 3,
              }}
            >
              Employees ({selectedEmp.length})
            </Typography>
            <Stack direction={"row"} flexWrap={"wrap"} spacing={3}>
              {selectedEmp.map((e) => (
                <Card
                  key={e.email}
                  variant="outlined"
                  sx={{
                    bgcolor: "transparent",
                    borderRadius: 6,
                    "&.MuiPaper-root.MuiCard-root": {
                      m: 1,
                    },
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.75 }}
                    >
                      <Avatar sx={{ width: 30, height: 30 }}></Avatar>
                      <Box
                        sx={{
                          p: 0,
                        }}
                      >
                        <Typography>
                          {e.firstName}
                          {e.lastName}
                        </Typography>
                        <Typography color={"textSecondary"}>
                          {e.department}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          </Paper>
        </>
      )}
    </Stack>
  );
}

export default AssignGoal;

// ====================================================================
//                    Customized Stepper Section
// ====================================================================

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <GroupAddIcon />,
    2: <SettingsIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

// --------------------------------------------
//               Stepper Component
// --------------------------------------------

function CustomizedSteppers({ activeStepNum }) {
  const navigate = useNavigate();
  return (
    <Stack
      sx={{ width: "100%" }}
      // spacing={4}
      direction={"row"}
      alignItems={"center"}
    >
      <IconButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Stepper
        alternativeLabel
        activeStep={activeStepNum}
        connector={<ColorlibConnector />}
        sx={{
          flexBasis: "95%",
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

// ====================================================================
//                        ViewAllEmployees
// ====================================================================

function ViewAllEmployeeGoals({
  setActiveStepNum,
  activeStepNum,
  selectedEmp,
  setSelectedEmp,
}) {
  const [filterString, setFilterString] = useState("All");
  const [currRows, setCurrRows] = useState(rows);

  const fullStackDeptLength = rows.filter(
    (e) => e.department === "Full-Stack"
  ).length;

  const dataAnalystDeptLength = rows.filter(
    (e) => e.department === "Data-Analyst"
  ).length;

  function handleChange(filterString) {
    setFilterString(filterString);
    const newCurrRows = rows.filter((e) => {
      if (filterString !== "All") {
        console.log(`filter:`, e.department === filterString);
        return e.department === filterString;
      }
      return e;
    });
    setCurrRows(newCurrRows);
  }

  return (
    <Stack
      gap={"10px"}
      sx={{
        mx: 0,
        mb: 5,
      }}
    >
      {/* ----------    Navigation Buttons   --------------- */}
      <Box
        sx={{
          p: 0,
          m: 0,
        }}
      >
        <Button
          sx={{
            float: "right",
          }}
          variant="contained"
          onClick={() => {
            setActiveStepNum(activeStepNum + 1);
          }}
          disabled={selectedEmp.length === 0 ? true : false}
        >
          Next
        </Button>
      </Box>
      {/* ----------         Table         ----------------- */}
      <Paper
        variant="outlined"
        sx={{
          borderRadius: "10px",
          bgcolor: "white",
        }}
      >
        {/*  Filter Tabs - All, FullStack, DataAnalyst */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
          }}
        >
          <Stack direction={"row"} spacing={1}>
            {/* All - Tab */}
            <Stack>
              <Button
                sx={
                  filterString === "All"
                    ? {
                        color: "#635bff",
                        ":hover": {
                          color: "black",
                        },
                      }
                    : {
                        color: "#8a8d90",
                        display: "flex",
                        alignItems: "center",
                        ":hover": {
                          color: "black",
                        },
                      }
                }
                onClick={() => handleChange("All")}
              >
                All
                <Box
                  sx={{
                    display: "inline-block",
                    width: 20,
                    height: 20,
                    color: "#121621",
                    bgcolor: "#e3e6ea",
                    borderRadius: "50%",
                    ml: 1,
                    lineHeight: 1.5,
                  }}
                >
                  {rows.length}
                </Box>
              </Button>
              <Box
                sx={
                  filterString === "All"
                    ? {
                        height: 6,
                        borderTop: "3.5px solid #635bff",
                        borderRadius: 4.5,
                      }
                    : {
                        height: 5,
                      }
                }
              ></Box>
            </Stack>
            {/* Full-Stack - Tab */}
            <Stack>
              <Button
                sx={
                  filterString === "Full-Stack"
                    ? {
                        color: "#635bff",
                        ":hover": {
                          color: "black",
                        },
                      }
                    : {
                        color: "#8a8d90",
                        display: "flex",
                        alignItems: "center",
                        ":hover": {
                          color: "black",
                        },
                      }
                }
                onClick={() => handleChange("Full-Stack")}
              >
                Full-Stack
                <Box
                  sx={{
                    display: "inline-block",
                    width: 20,
                    height: 20,
                    color: "#121621",
                    bgcolor: "#e3e6ea",
                    borderRadius: "50%",
                    ml: 1,
                    lineHeight: 1.5,
                  }}
                >
                  {fullStackDeptLength}
                </Box>
              </Button>
              <Box
                sx={
                  filterString === "Full-Stack"
                    ? {
                        height: 6,
                        borderTop: "3.5px solid #635bff",
                        borderRadius: 4.5,
                      }
                    : {
                        height: 5,
                      }
                }
              ></Box>
            </Stack>
            {/* Data-Analyst - Tab */}
            <Stack>
              <Button
                sx={
                  filterString === "Data-Analyst"
                    ? {
                        color: "#635bff",
                        ":hover": {
                          color: "black",
                        },
                      }
                    : {
                        color: "#8a8d90",
                        display: "flex",
                        alignItems: "center",
                        ":hover": {
                          color: "black",
                        },
                      }
                }
                onClick={() => handleChange("Data-Analyst")}
              >
                Data-Analyst
                <Box
                  sx={{
                    display: "inline-block",
                    width: 20,
                    height: 20,
                    color: "#121621",
                    bgcolor: "#e3e6ea",
                    borderRadius: "50%",
                    ml: 1,
                    lineHeight: 1.5,
                  }}
                >
                  {dataAnalystDeptLength}
                </Box>
              </Button>
              <Box
                sx={
                  filterString === "Data-Analyst"
                    ? {
                        height: 6,
                        borderTop: "3.5px solid #635bff",
                        borderRadius: 4.5,
                      }
                    : {
                        height: 5,
                      }
                }
              ></Box>
            </Stack>
          </Stack>
        </Box>

        <DataTable
          rows={currRows}
          selectedEmp={selectedEmp}
          setSelectedEmp={setSelectedEmp}
        />
      </Paper>
    </Stack>
  );
}

// --------------------------------------------
//                Data Grid Table
// --------------------------------------------

const columns = [
  { field: "id", headerName: "Emp Id", width: 100 },
  {
    field: "fullName",
    headerName: "Full name",
    // headerAlign: "center",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 420,
    // valueGetter: (value, row) => ,
    renderCell: (params) => {
      const { firstName, lastName, email } = params.row;
      return (
        <>
          <Typography
            sx={{
              m: 0,
              p: 0,
            }}
          >
            {firstName} {lastName}
          </Typography>
          <Typography
            color={"textSecondary"}
            sx={{
              m: 0,
              p: 0,
            }}
          >
            {email}
          </Typography>
        </>
      );
    },
  },
  {
    field: "department",
    headerName: "Department",
    type: "string",
    width: 340,
  },
];

const rows = [
  {
    id: 1,
    lastName: "Snow",
    firstName: "Jon",
    email: "JonSnow@gmail.com",
    goals: 35,
    department: "Data-Analyst",
    status: 100,
  },
  {
    id: 2,
    lastName: "Lannister",
    firstName: "Cersei",
    email: "CerseiLannister@gmail.com",
    goals: 42,
    department: "Management",
    status: 20,
  },
  {
    id: 3,
    lastName: "Lannister",
    firstName: "Jaime",
    email: "JaimeLannister@gmail.com",
    goals: 45,
    department: "Full-Stack",
    status: 80,
  },
  {
    id: 4,
    lastName: "Stark",
    firstName: "Arya",
    goals: 16,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 5,
    lastName: "Targaryen",
    firstName: "Daenerys",
    goals: null,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 6,
    lastName: "Melisandre",
    firstName: null,
    goals: 150,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 7,
    lastName: "Clifford",
    firstName: "Ferrara",
    goals: 44,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 8,
    lastName: "Frances",
    firstName: "Rossini",
    goals: 36,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 9,
    lastName: "Roxie",
    firstName: "Harvey",
    goals: 65,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 10,
    lastName: "Frances",
    firstName: "Rossini",
    goals: 36,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 11,
    lastName: "Roxie",
    firstName: "Harvey",
    goals: 65,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 12,
    lastName: "Roxie",
    firstName: "Harvey",
    goals: 65,
    department: "Full-Stack",
    status: 45,
  },
];

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}`]: {
    margin: "auto",
  },
}));

function DataTable({ rows, selectedEmp, setSelectedEmp }) {
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    const employeeIdPath = params.row.id;
    setSelectedEmp([...selectedEmp, params.row]);
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <StyledDataGrid
        rowHeight={60}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        getRowClassName={(params) => params}
        onRowClick={handleRowClick}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectedEmp(
            newRowSelectionModel.map((checkBoxIndex) =>
              rows.find((rdata) => rdata.id === checkBoxIndex)
            )
          );
          setRowSelectionModel(newRowSelectionModel);
          newRowSelectionModel.map((checkBoxIndex) =>
            rows.find((rdata) => rdata.id === checkBoxIndex)
          );
        }}
        rowSelectionModel={rowSelectionModel}
      />
    </div>
  );
}
