import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import "./ViewAllEmployeeGoals.css";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function ViewAllEmployeeGoals() {
  const [filterString, setFilterString] = useState("All");
  const [currRows, setCurrRows] = useState(rows);
  const navigate = useNavigate();

  const fullStackDeptLength = rows.filter(
    (e) => e.department === "Full-Stack"
  ).length;
  const dataAnalystDeptLength = rows.filter(
    (e) => e.department === "Data-Analyst"
  ).length;

  function handleChange(filterString) {
    setFilterString(filterString);
    const newCurrRows = rows.filter((e) => {
      if (filterString !== "All") {
        console.log(`filter:`, e.department === filterString);
        return e.department === filterString;
      }
      return e;
    });
    setCurrRows(newCurrRows);
  }

  return (
    <Stack
      gap={"10px"}
      sx={{
        mx: 6,
        my: 3,
        mb: 5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <h3>ViewAllEmployeeGoals</h3>
      </Box>
      <div>
        <hr />
      </div>
      <Paper
        variant="outlined"
        sx={{
          borderRadius: "10px",
          bgcolor: "white",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Stack direction={"row"} spacing={1}>
            {/* All */}
            <Stack>
              <Button
                sx={
                  filterString === "All"
                    ? {
                        color: "#635bff",
                        ":hover": {
                          color: "black",
                        },
                      }
                    : {
                        color: "#8a8d90",
                        display: "flex",
                        alignItems: "center",
                        ":hover": {
                          color: "black",
                        },
                      }
                }
                onClick={() => handleChange("All")}
              >
                All
                <Box
                  sx={{
                    display: "inline-block",
                    width: 20,
                    height: 20,
                    color: "#121621",
                    bgcolor: "#e3e6ea",
                    borderRadius: "50%",
                    ml: 1,
                    lineHeight: 1.5,
                  }}
                >
                  {rows.length}
                </Box>
              </Button>
              <Box
                sx={
                  filterString === "All"
                    ? {
                        height: 6,
                        borderTop: "3.5px solid #635bff",
                        borderRadius: 4.5,
                      }
                    : {
                        height: 5,
                      }
                }
              ></Box>
            </Stack>
            {/* Full-Stack */}
            <Stack>
              <Button
                sx={
                  filterString === "Full-Stack"
                    ? {
                        color: "#635bff",
                        ":hover": {
                          color: "black",
                        },
                      }
                    : {
                        color: "#8a8d90",
                        display: "flex",
                        alignItems: "center",
                        ":hover": {
                          color: "black",
                        },
                      }
                }
                onClick={() => handleChange("Full-Stack")}
              >
                Full-Stack
                <Box
                  sx={{
                    display: "inline-block",
                    width: 20,
                    height: 20,
                    color: "#121621",
                    bgcolor: "#e3e6ea",
                    borderRadius: "50%",
                    ml: 1,
                    lineHeight: 1.5,
                  }}
                >
                  {fullStackDeptLength}
                </Box>
              </Button>
              <Box
                sx={
                  filterString === "Full-Stack"
                    ? {
                        height: 6,
                        borderTop: "3.5px solid #635bff",
                        borderRadius: 4.5,
                      }
                    : {
                        height: 5,
                      }
                }
              ></Box>
            </Stack>
            {/* Data-Analyst */}
            <Stack>
              <Button
                sx={
                  filterString === "Data-Analyst"
                    ? {
                        color: "#635bff",
                        ":hover": {
                          color: "black",
                        },
                      }
                    : {
                        color: "#8a8d90",
                        display: "flex",
                        alignItems: "center",
                        ":hover": {
                          color: "black",
                        },
                      }
                }
                onClick={() => handleChange("Data-Analyst")}
              >
                Data-Analyst
                <Box
                  sx={{
                    display: "inline-block",
                    width: 20,
                    height: 20,
                    color: "#121621",
                    bgcolor: "#e3e6ea",
                    borderRadius: "50%",
                    ml: 1,
                    lineHeight: 1.5,
                  }}
                >
                  {dataAnalystDeptLength}
                </Box>
              </Button>
              <Box
                sx={
                  filterString === "Data-Analyst"
                    ? {
                        height: 6,
                        borderTop: "3.5px solid #635bff",
                        borderRadius: 4.5,
                      }
                    : {
                        height: 5,
                      }
                }
              ></Box>
            </Stack>
          </Stack>
        </Box>
        <DataTable rows={currRows} />
      </Paper>
    </Stack>
  );
}

const columns = [
  { field: "id", headerName: "Emp Id", width: 100 },
  {
    field: "fullName",
    headerName: "Full name",
    headerAlign: "center",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 220,
    // valueGetter: (value, row) => ,
    renderCell: (params) => {
      const { firstName, lastName, email } = params.row;
      return (
        <>
          <Typography
            sx={{
              m: 0,
              p: 0,
            }}
          >
            {firstName} {lastName}
          </Typography>
          <Typography
            color={"textSecondary"}
            sx={{
              m: 0,
              p: 0,
            }}
          >
            {email}
          </Typography>
        </>
      );
    },
  },
  {
    field: "department",
    headerName: "Department",
    type: "number",
    width: 200,
  },
  {
    field: "goals",
    headerName: "Goals",
    type: "number",
    width: 90,
  },
  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    type: "number",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Box sx={{ display: "flex", alignItems: "center", height: 50 }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={params.row.status}
                color={
                  params.row.status >= 45
                    ? params.row.status >= 80
                      ? "success"
                      : "warning"
                    : "error"
                }
                sx={{
                  borderRadius: "10px",
                }}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                params.row.status
              )}%`}</Typography>
            </Box>
          </Box>
        </>
      );
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    headerAlign: "center",
    type: "string",
    width: 120,
  },
];

const rows = [
  {
    id: 1,
    lastName: "Snow",
    firstName: "Jon",
    email: "JonSnow@gmail.com",
    goals: 35,
    department: "Data-Analyst",
    status: 100,
  },
  {
    id: 2,
    lastName: "Lannister",
    firstName: "Cersei",
    email: "CerseiLannister@gmail.com",
    goals: 42,
    department: "Management",
    status: 20,
  },
  {
    id: 3,
    lastName: "Lannister",
    firstName: "Jaime",
    email: "JaimeLannister@gmail.com",
    goals: 45,
    department: "Full-Stack",
    status: 80,
  },
  {
    id: 4,
    lastName: "Stark",
    firstName: "Arya",
    goals: 16,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 5,
    lastName: "Targaryen",
    firstName: "Daenerys",
    goals: null,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 6,
    lastName: "Melisandre",
    firstName: null,
    goals: 150,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 7,
    lastName: "Clifford",
    firstName: "Ferrara",
    goals: 44,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 8,
    lastName: "Frances",
    firstName: "Rossini",
    goals: 36,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 9,
    lastName: "Roxie",
    firstName: "Harvey",
    goals: 65,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 10,
    lastName: "Frances",
    firstName: "Rossini",
    goals: 36,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 11,
    lastName: "Roxie",
    firstName: "Harvey",
    goals: 65,
    department: "Full-Stack",
    status: 45,
  },
  {
    id: 12,
    lastName: "Roxie",
    firstName: "Harvey",
    goals: 65,
    department: "Full-Stack",
    status: 45,
  },
];

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}`]: {
    margin: "auto",
  },
}));

function DataTable({ rows }) {
  // currRows.filter((e) => {
  //   if (filterString !== "all") {
  //     return e === filterString;
  //   }
  //   return e;
  // });

  const navigate = useNavigate();
  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    const employeeIdPath = params.row.id;
    // console.log(`params: `, params);
    // console.log(`event: `, event);
    // console.log(`details: `, details);
    navigate(`/appraisal/management-view/${employeeIdPath}`);
  };

  return (
    <div style={{ height: 720, width: "100%" }}>
      <StyledDataGrid
        rowHeight={60}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        getRowClassName={(params) => params}
        onRowClick={handleRowClick}
      />
    </div>
  );
}

export default ViewAllEmployeeGoals;
