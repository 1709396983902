import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

function HiringReports() {
  return (
    <Grid item flex={8} sx={{ padding: "50px 50px" }}>
      <Typography
        variant="h4"
        sx={{
          mb: 3,
        }}
      >
        Hiring Reports
      </Typography>

      <Divider></Divider>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi rerum
        assumenda nam doloremque ad, dolorum cumque quis nihil quod quas,
        voluptatem corrupti amet, odit magnam facilis voluptates earum
        aspernatur esse quo delectus sunt nisi. Ipsa possimus nemo nesciunt
        asperiores debitis beatae obcaecati assumenda, eveniet id animi, magni
        autem iste consequuntur et aliquam, vero laborum eaque. Molestiae
        voluptatem dolorum facere, impedit distinctio dolorem accusantium maxime
        voluptas nobis officia quae? Ducimus corrupti placeat aliquid dolore,
        facere rem deserunt, dicta accusantium accusamus ratione nihil
        voluptatem! Velit atque quos rerum unde quo eos, veritatis, ut odio
        necessitatibus omnis officiis. Quis fugit aliquam nulla vitae expedita
        esse consequatur hic explicabo, veritatis fugiat provident labore nihil
        odio, saepe inventore? Expedita, eius numquam possimus perferendis sint
        necessitatibus suscipit maiores. Saepe, voluptatum minima asperiores
        quibusdam nulla obcaecati iusto amet a aliquid, repellat eos, fugiat
        unde ad. Id corporis vel dolore, ex facere possimus fugiat autem
        recusandae accusantium voluptas sapiente non eius perspiciatis saepe
        iusto, pariatur quasi vitae ullam error molestias voluptatibus, sunt
        quam! Amet dignissimos molestias culpa, quos fugit possimus obcaecati
        eius beatae. Magnam laboriosam adipisci, maxime similique cupiditate
        obcaecati ipsam labore odio impedit ipsa. Maxime dolorum voluptas dicta,
        aliquam, deserunt facilis doloribus voluptatem exercitationem
        perferendis hic, soluta amet laborum pariatur minus similique nisi ea
        rerum. Officia natus rem vel ipsum ut perspiciatis veritatis distinctio
        illum consequuntur corporis quod, nulla excepturi, aperiam eum pariatur
        nam deleniti, cupiditate sunt aliquid. Placeat blanditiis possimus
        quibusdam atque? Suscipit ad alias commodi ea, a molestias nesciunt,
        eius voluptate rem ducimus at quaerat? Distinctio tenetur rem nulla
        reprehenderit laboriosam. Debitis veritatis, libero accusamus ullam
        possimus velit, natus ea laudantium iste enim excepturi eos ipsa nemo
        similique voluptas fuga corrupti sunt nulla temporibus a tempore.
        Repellendus, laboriosam error velit, quam assumenda libero sequi nam
        exercitationem totam corrupti sapiente debitis. Odit eaque nulla ipsa
        illum fugiat perferendis odio, sed explicabo culpa dolorum sit? A
        ratione culpa voluptatum? Qui pariatur accusantium rem voluptas natus
        sit fuga officiis maxime animi dicta est accusamus commodi fugit quaerat
        facere porro numquam cupiditate velit, necessitatibus itaque culpa
        deserunt sed quos dolorem? Quam architecto itaque error distinctio fugit
        aut adipisci minima est recusandae voluptatibus a esse cumque excepturi
        et asperiores ullam nobis laudantium sit, nemo non harum dolorem.
        Laboriosam corporis, saepe ipsa impedit minima, veniam distinctio hic,
        similique animi nemo quos quo obcaecati tenetur laborum? Iusto error id
        nam sint autem labore ipsa eius, quas officiis deleniti tenetur, ea
        ipsam soluta nesciunt maiores accusantium atque a eligendi molestiae
        repellendus harum laborum amet voluptate. Reiciendis temporibus maiores
        accusamus rerum soluta. Vel tempora et expedita facere dolorum
        repellendus tempore rem sunt unde sit maxime consectetur, vitae
        explicabo minima natus accusantium id aut reprehenderit ipsum voluptatem
        consequuntur! Autem sint nobis ab nostrum, laboriosam laudantium ea
        vitae fugit quibusdam dicta porro rerum tempora a accusantium qui,
        provident libero eius deleniti itaque, dolor mollitia? Voluptatum rem
        animi iure, nulla autem tempore fugit architecto officiis accusantium
        cum rerum. Illo dicta alias id temporibus! Alias, inventore. Tempora
        nostrum praesentium nulla cupiditate perspiciatis quidem autem aliquam
        assumenda sapiente illo.
      </p>
    </Grid>
  );
}

export default HiringReports;
