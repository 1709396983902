import {
  Typography,
  Grid,
  Box,
  Tab,
  Tabs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Stack,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportIcon from "@mui/icons-material/Report";
import EngineeringIcon from "@mui/icons-material/Engineering";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import React, { useState, useEffect } from "react";
import OrgXLogo2 from "../../assets/OrgXLogo2.png";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useTheme } from "@emotion/react";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const SideBar = (props) => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [userAccess, setUserAccess] = useState([]);

  useEffect(() => {
    const userAccessCookie = Cookies.get("access");
    if (userAccessCookie) {
      try {
        const parsedAccess = JSON.parse(userAccessCookie);
        // console.log("Parsed access from cookie:", parsedAccess);
        setUserAccess(parsedAccess);
      } catch (error) {
        console.error("Error parsing access cookie:", error);
      }
    }
  }, []);

  // console.log(userAccess);

  const tanStyles = {
    color: "#a8adba",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    pl: "8px",
    borderRadius: "12px",
    textTransform: "none",
    fontSize: "16px",
    minHeight: "12px",
    width: "220px",
    mb: 1,
    "&.Mui-selected": {
      bgcolor: "#635bff",
      color: "#ffffff",
      "& .MuiTab-iconWrapper": {
        color: "#ffffff",
      },
    },
    "&.Mui-selected:hover": { bgcolor: "#635bff", color: "#ffffff" },
    ":hover": {
      borderRadius: "12px",
      bgcolor: "rgba(99, 91, 255, 0.12)",
      color: "#fff",
    },
  };

  const hasAccess = (accessType) =>
    userAccess && userAccess.includes(accessType);

  return (
    <Grid
      sx={{
        flexWrap: "nowrap",
        maxHeight: "100vh",
        position: "sticky",
        top: 0,
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          width: "18vw",
          boxSizing: "border-box",
          height: "auto",
          backgroundColor: theme.palette.primary.main,
          color: "#a8adba",
        }}
      >
        <img
          src={OrgXLogo2}
          alt="aapmor-logo"
          style={{
            width: "80%",
            alignSelf: "center",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        />
        {/* <Divider
          sx={{
            bgcolor: "#a8adba",
            mb: 2,
          }}
        ></Divider> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "82vh",
            mb: 2,
            overflowY: "scroll",
            "::-webkit-scrollbar": {
              width: "7px",
            },
            "::-webkit-scrollbar-thumb": {
              borderRadius: "20px",
              backgroundColor: "#a8adba",
            },
          }}
        >
          <Box>
            <Tabs
              orientation="vertical"
              value={false} // Remove value state, let URL drive selected tab
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                padding: "0px",
              }}
            >
              {hasAccess("Orgx_Recent_Openings_View") && (
                <Tab
                  label="Dashboard"
                  icon={<DashboardIcon sx={{ marginRight: "10px" }} />}
                  sx={{
                    ...tanStyles,
                    bgcolor: location.pathname === "/" ? "#635bff" : null,
                    color: location.pathname === "/" ? "#ffffff" : "#a8adba",
                  }}
                  {...a11yProps(0)}
                  onClick={() => navigate("/")}
                />
              )}
              {hasAccess("Orgx_Employees_View") && (
                <Tab
                  label="Employees"
                  icon={<AccountBoxIcon sx={{ marginRight: "10px" }} />}
                  sx={{
                    ...tanStyles,
                    bgcolor: location.pathname.includes("employees")
                      ? "#635bff"
                      : null,
                    color: location.pathname.includes("employees")
                      ? "#ffffff"
                      : "#a8adba",
                  }}
                  {...a11yProps(1)}
                  onClick={() => navigate("/employees")}
                />
              )}
              {hasAccess("Orgx_Recruitment_View") && (
                <Tab
                  label="Recruitment"
                  icon={<GroupAddIcon sx={{ marginRight: "10px" }} />}
                  sx={{
                    ...tanStyles,
                    bgcolor: location.pathname.includes("/recruitment")
                      ? "#635bff"
                      : null,
                    color: location.pathname.includes("/recruitment")
                      ? "#ffffff"
                      : "#a8adba",
                  }}
                  {...a11yProps(2)}
                  onClick={() => navigate("/recruitment")}
                />
              )}
              {hasAccess("Orgx_Blogs_View_And_Write") && (
                <Tab
                  label="Blogs"
                  icon={<EditNoteIcon sx={{ marginRight: "10px" }} />}
                  sx={{
                    ...tanStyles,
                    bgcolor: location.pathname.includes("blogs")
                      ? "#635bff"
                      : null,
                    color: location.pathname.includes("blogs")
                      ? "#ffffff"
                      : "#a8adba",
                  }}
                  {...a11yProps(3)}
                  onClick={() => navigate("/blogs")}
                />
              )}
              {hasAccess("Orgx_Write_FeedBack_View_And_Write") && (
                <Tab
                  label="Feedback"
                  icon={<ReportIcon sx={{ marginRight: "10px" }} />}
                  sx={{
                    ...tanStyles,
                    bgcolor: location.pathname.includes("feedback")
                      ? "#635bff"
                      : null,
                    color: location.pathname.includes("feedback")
                      ? "#ffffff"
                      : "#a8adba",
                  }}
                  {...a11yProps(4)}
                  onClick={() => navigate("/feedback")}
                />
              )}
              {hasAccess("Orgx_Projects_View_And_Write") && (
                <Tab
                  label="Projects"
                  icon={<EngineeringIcon sx={{ marginRight: "10px" }} />}
                  sx={{
                    ...tanStyles,
                    bgcolor: location.pathname.includes("projects")
                      ? "#635bff"
                      : null,
                    color: location.pathname.includes("projects")
                      ? "#ffffff"
                      : "#a8adba",
                  }}
                  {...a11yProps(5)}
                  onClick={() => navigate("/projects")}
                />
              )}
              {hasAccess("Orgx_Appraisal_View_And_Write") && (
                <Tab
                  label="Appraisal"
                  icon={<TrendingUpIcon sx={{ marginRight: "10px" }} />}
                  sx={{
                    ...tanStyles,
                    bgcolor: location.pathname.includes("appraisal")
                      ? "#635bff"
                      : null,
                    color: location.pathname.includes("appraisal")
                      ? "#ffffff"
                      : "#a8adba",
                  }}
                  {...a11yProps(5)}
                  onClick={() => navigate("/appraisal")}
                />
              )}
              {hasAccess("Orgx_Reports_View_And_Write") && (
                <Accordion sx={{ backgroundColor: "#121621", mr: 4.5 }}>
                  <AccordionSummary
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: "#a8adba",
                      px: 1,
                    }}
                    expandIcon={<ExpandMoreIcon sx={{ color: "#a8adba" }} />}
                  >
                    <AssessmentOutlinedIcon sx={{ mr: 2 }} />
                    Reports
                  </AccordionSummary>
                  <Stack direction={"row"}>
                    <Divider
                      orientation="vertical"
                      sx={{
                        bgcolor: "#a8adba",
                        ml: 2.5,
                      }}
                      flexItem
                    ></Divider>
                    <div>
                      <AccordionDetails
                        sx={
                          location.pathname.includes("employee-engagement")
                            ? {
                                ...tanStyles,
                                cursor: "pointer",
                                fontSize: 13,
                                pl: 5,
                                color: "#fff",
                                backgroundColor: "#635bff",
                                ":hover": {
                                  bgcolor: "#635bff",
                                  color: "#ffffff",
                                },
                              }
                            : {
                                ...tanStyles,
                                cursor: "pointer",
                                fontSize: 13,
                                pl: 5,
                                color: "#a8adba",
                                backgroundColor: null,
                              }
                        }
                        {...a11yProps(5)}
                        onClick={() => navigate("/reports/employee-engagement")}
                      >
                        Employee Engagement
                      </AccordionDetails>
                      <AccordionDetails
                        sx={
                          location.pathname.includes("hiring-tracker")
                            ? {
                                ...tanStyles,
                                cursor: "pointer",
                                fontSize: 13,
                                pl: 5,
                                color: "#fff",
                                backgroundColor: "#635bff",
                                ":hover": {
                                  bgcolor: "#635bff",
                                  color: "#ffffff",
                                },
                              }
                            : {
                                ...tanStyles,
                                cursor: "pointer",
                                fontSize: 13,
                                pl: 5,
                                color: "#a8adba",
                                backgroundColor: null,
                              }
                        }
                        {...a11yProps(6)}
                        onClick={() => navigate("/reports/hiring-tracker")}
                      >
                        Hiring Tracker
                      </AccordionDetails>
                      <AccordionDetails
                        sx={
                          location.pathname.includes("hiring-reports")
                            ? {
                                ...tanStyles,
                                cursor: "pointer",
                                fontSize: 13,
                                pl: 5,
                                color: "#fff",
                                backgroundColor: "#635bff",
                                ":hover": {
                                  bgcolor: "#635bff",
                                  color: "#ffffff",
                                },
                              }
                            : {
                                ...tanStyles,
                                cursor: "pointer",
                                fontSize: 13,
                                pl: 5,
                                color: "#a8adba",
                                backgroundColor: null,
                              }
                        }
                        {...a11yProps(7)}
                        onClick={() => navigate("/reports/hiring-reports")}
                      >
                        Hiring reports
                      </AccordionDetails>
                      <AccordionDetails
                        sx={
                          location.pathname.includes("certifications")
                            ? {
                                ...tanStyles,
                                cursor: "pointer",
                                fontSize: 13,
                                pl: 5,
                                color: "#fff",
                                backgroundColor: "#635bff",
                                ":hover": {
                                  bgcolor: "#635bff",
                                  color: "#ffffff",
                                },
                              }
                            : {
                                ...tanStyles,
                                cursor: "pointer",
                                fontSize: 13,
                                pl: 5,
                                color: "#a8adba",
                                backgroundColor: null,
                              }
                        }
                        {...a11yProps(8)}
                        onClick={() => navigate("/reports/certifications")}
                      >
                        Certifications
                      </AccordionDetails>
                    </div>
                  </Stack>
                </Accordion>
              )}
            </Tabs>
          </Box>
          <Box>
            <Typography variant="caption" fontSize={11}>
              Copyright @ Aapmor Technologies {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SideBar;
