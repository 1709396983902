import { Grid } from "@mui/material";
import React from "react";
import SideBar from "../sidebar/sidebar";
import CardItem from "../card/card";
import Quotes from "./Quotes";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";

// const feedbackCards = [
//   {
//     heading: "Write Feedback",
//     image: null,
//     cardText:
//       "Have any suggestions, complaints or feedback? Drop here, we'll look into it",
//     path: "/post-feedback",
//   },
//   {
//     heading: "View Feedbacks",
//     image: null,
//     cardText: "View what employees think about us",
//     path: "/view-feedback",
//   },
// ];

const Feedback = () => {
  const [feedbackCards, setFeedbackCards] = useState([
    {
      heading: "Write Feedback",
      image: null,
      cardText:
        "Have any suggestions, complaints or feedback? Drop here, we'll look into it",
      path: "/feedback/post-feedback",
      show: false,
    },
    {
      heading: "View Feedbacks",
      image: null,
      cardText: "View what employees think about us",
      path: "/feedback/view-feedback",
      show: false,
    },
  ]);

  useEffect(() => {
    const userAccessCookie = JSON.parse(Cookies.get("access"));
    if (userAccessCookie) {
      const updatedCards = feedbackCards.map((card) => {
        switch (card.heading) {
          case "Write Feedback":
            return {
              ...card,
              show: userAccessCookie.includes(
                "Orgx_Write_FeedBack_View_And_Write"
              ),
            };
          case "View Feedbacks":
            return {
              ...card,
              show: userAccessCookie.includes("Orgx_View_FeedBack_View"),
            };
          default:
            return card;
        }
      });
      setFeedbackCards(updatedCards);
    }
  }, []);

  return (
    // <Grid container flexDirection={"row"} justifyContent={"flex-start"}>
    //   <Grid item flex={1}>
    //     <SideBar />
    //   </Grid>

    <Grid item container flex={8} sx={{ p: 1, flexDirection: "column" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 2,
          p: 2,
        }}
      >
        {feedbackCards.map((eachFeedbackCard) =>
          eachFeedbackCard.show ? (
            <CardItem
              cardDetails={eachFeedbackCard}
              key={eachFeedbackCard.heading}
            />
          ) : null
        )}
      </Grid>
    </Grid>
    // </Grid>
  );
};

export default Feedback;
