import Cookies from "js-cookie";
import { Navigate, Outlet } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import SideBar from "./sidebar/sidebar";
import Header from "./header/Header";
import { createContext } from "react";

export const TokenContext = createContext();

const ProtectedRoute = () => {
  // Main Application: Initiate Login
  // function handleLogin() {
  //   const authAppUrl = "http://localhost:3000/";
  //   const redirectUri = encodeURIComponent("http://localhost:3001/");
  //   window.location.href = `${authAppUrl}?redirect_uri=${redirectUri}&app=orgx`;
  // }

  function handleLogin() {
    const authAppUri = process.env.REACT_APP_AUTH_URI;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
    window.location.href = `${authAppUri}?redirect_uri=${redirectUri}&app=orgx`;
  }

  const token = Cookies.get("jwtToken");
  // console.log(token);
  // return token === undefined ? <Navigate to="/login" /> : <Home />;
  return token === undefined ? (
    handleLogin()
  ) : (
    <TokenContext.Provider value={token}>
      <Home />
    </TokenContext.Provider>
  );
};
export default ProtectedRoute;

const Home = () => {
  return (
    <Grid
      container
      columnGap={2}
      flexDirection={"row"}
      justifyContent={"flex-start"}
    >
      <Grid item xs={2} flex={1}>
        <SideBar />
      </Grid>
      <Grid className="contentBgColor" item xs={9.83} id="top" px={1} py={1}>
        <Header />
        <Outlet />
      </Grid>
    </Grid>
  );
};
