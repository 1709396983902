import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Box,
  Stack,
  TextField,
  Typography,
  FormLabel,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Modal,
} from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  jobPostingBaseUrl,
  jobApplicantPublicBaseUrl,
  resumeBaseUrl,
  jobApplicantBaseUrl,
} from "../../apiCalls/apiCalls";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DoneIcon from "@mui/icons-material/Done";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TokenContext } from "../protectedLayout";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxHeight: 550,
  minHeight: 550,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const AddApplicantModal = ({
  open,
  setOpen,
  handleClose,
  fetchApplicantsData,
}) => {
  // Set withCredentials to true for all requests
  axios.defaults.withCredentials = true;
  const navigate = useNavigate();
  let { id: jobId } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileConfirmed, setFileConfirmed] = useState(false);
  const [resumeError, setResumeError] = useState("");
  const [applySuccess, setApplySuccess] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);

  const token = useContext(TokenContext);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setFileConfirmed(false);
  };

  const handleUpload = () => {
    if (selectedFile) {
      // onFileSelect(selectedFile);
      console.log(selectedFile);
      setFileName(selectedFile.name);
      setFileConfirmed(true);
      setResumeError(false);
    } else {
      alert("Please select a file");
    }
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  async function handleFormSubmit(values) {
    setDisableSubmit(true);
    console.log(values);
    if (!selectedFile || !fileConfirmed) {
      setResumeError(true);
      return false;
    }

    // const base64Resume = await convertToBase64(selectedFile);
    let applicant = {};
    applicant.firstName = capitalizeFirstLetter(values.firstName);
    applicant.lastName = capitalizeFirstLetter(values.lastName);
    // applicant["resume"] = base64Resume;
    applicant["jobPostingId"] = jobId;
    applicant["source"] = "selfApply";
    applicant["status"] = "yetToInitiate";
    applicant["phoneNumber"] = values.phoneNumber;
    applicant["email"] = values.email;

    if (values.gender) {
      applicant["gender"] = values.gender;
    }

    let formData = new FormData();
    formData.append("resume", selectedFile);

    try {
      const response = await axios.post(
        `${jobApplicantPublicBaseUrl}`,
        applicant,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // if applicant created successfully
      if (response.status === 201) {
        // upload resume
        const metadataRes = await axios.post(
          `${resumeBaseUrl}/${response.data._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Content-Disposition": `attachment; filename=${fileName}`,
              "x-file-caption": "resume", // Optional header for caption
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // if resume uploaded successfully
        if (metadataRes.status === 201) {
          console.log("metadata", metadataRes.data);
          // update user details with resume
          const updatedRes = await axios.put(
            `${jobApplicantBaseUrl}${response.data._id}`,
            {
              resume: metadataRes.data,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("updated", updatedRes.data);
        }
      }

      console.log("Posted successfully", response);

      setApplySuccess("success");
      setDisableSubmit(false);
    } catch (error) {
      console.log("Application failed", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Applicant already exists"
      ) {
        console.log("Application failed", error);
        setApplySuccess("alreadyExists");
      } else {
        setApplySuccess("fail");
        setDisableSubmit(false);
      }
    }
  }

  // fetch data of this job
  const fetchJobData = async () => {
    try {
      const res = await axios.get(`${jobPostingBaseUrl}${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data === null) {
        navigate("/not-found");
      }
    } catch (error) {
      console.error("Error fetching job data: ", error);
    }
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First Name is required")
      .max(20, "max 20 characters allowed")
      .matches(/^[A-Za-z]+(?:\s[A-Za-z]+)*$/, "Only alphabets allowed"),
    lastName: Yup.string()
      .required("Last Name is required")
      .max(20, "max 20 characters allowed")
      .matches(/^[A-Za-z]+(?:\s[A-Za-z]+)*$/, "Only alphabets allowed"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email"
      ),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .matches(/^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/, "Invalid phone number"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      gender: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      handleFormSubmit(values);
    },
  });

  const labelStyling = {
    "& .MuiFormLabel-asterisk": {
      color: "#cd4949",
    },
    fontSize: "12px",
    fontWeight: "bold",
    color: "black",
  };

  const textFieldStyling = {
    fontSize: "12.5px",
    mt: 0.5,
  };

  useEffect(() => {
    setSelectedFile(null);
    setFileConfirmed(false);
    fetchJobData();
  }, []);

  const handleClosemodal = () => {
    handleClose();
    setApplySuccess("");
    setSelectedFile(null);
    setFileName("");
    setFileConfirmed(false);
    setResumeError("");
    formik.resetForm();
    fetchApplicantsData();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* {jobTitle && ( */}
        {/* <Box width="100%" sx={{ px: 4, py: 2 }}> */}
        <Box sx={style}>
          {!applySuccess ? (
            <form onSubmit={formik.handleSubmit}>
              <Typography
                paragraph={true}
                // gutterBottom={false}
                // marginBottom={2}
                sx={{
                  fontSize: 28,
                  fontWeight: 600,
                }}
              >
                {/* {jobTitle} &gt; Apply */}
                Apply
              </Typography>
              <Stack gap={2} py={3}>
                {/* FN */}
                <Box>
                  <FormLabel required htmlFor="firstName" sx={labelStyling}>
                    FIRST NAME
                  </FormLabel>
                  <TextField
                    fullWidth
                    autoComplete="given-name"
                    id="firstName"
                    InputProps={{
                      sx: textFieldStyling,
                      placeholder: "First Name",
                    }}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Box>
                {/* LN */}
                <Box>
                  <FormLabel required htmlFor="lastName" sx={labelStyling}>
                    LAST NAME
                  </FormLabel>
                  <TextField
                    fullWidth
                    autoComplete="family-name"
                    id="lastName"
                    InputProps={{
                      sx: textFieldStyling,
                      placeholder: "Last Name",
                    }}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Box>
                {/* EMAIL */}
                <Box>
                  <FormLabel required htmlFor="email" sx={labelStyling}>
                    EMAIL
                  </FormLabel>
                  <TextField
                    fullWidth
                    autoComplete="email"
                    id="email"
                    name="email"
                    InputProps={{
                      sx: textFieldStyling,
                      placeholder: "Email",
                    }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Box>
                {/* PN */}
                <Box>
                  <FormLabel required htmlFor="phoneNumber" sx={labelStyling}>
                    PHONE NUMBER
                  </FormLabel>
                  <TextField
                    fullWidth
                    autoComplete="tel-local"
                    id="phoneNumber"
                    name="phoneNumber"
                    InputProps={{
                      sx: textFieldStyling,
                      placeholder: "Phone Number",
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography fontSize={"12.5px"}>+91</Typography>
                        </InputAdornment>
                      ),
                    }}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </Box>
                {/* GENDER */}
                <Box>
                  {/* <FormLabel required htmlFor="gender" sx={labelStyling}>
                    GENDER
                  </FormLabel> */}
                  <FormControl fullWidth mt={0.5}>
                    <Select
                      id="gender"
                      name="gender"
                      size="small"
                      sx={{
                        fontSize: "14px",
                        p: 0.8,
                      }}
                      value={formik.values.gender}
                      // label="Gender"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                      helperText={formik.touched.gender && formik.errors.gender}
                      displayEmpty
                    >
                      <MenuItem value="" disabled hidden>
                        Select Gender
                      </MenuItem>
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {/* RESUME */}
                <Stack my={2}>
                  <Stack direction={"row"} gap={3}>
                    {selectedFile === null || !fileConfirmed ? (
                      <Box>
                        <input
                          type="file"
                          accept=".pdf"
                          style={{ display: "none" }}
                          name="resume"
                          id="fileInput"
                          onChange={handleFileChange}
                        />
                        <label htmlFor="fileInput">
                          <Button
                            variant="outlined"
                            component="span"
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload Resume
                          </Button>
                        </label>
                      </Box>
                    ) : (
                      <Button
                        variant="outlined"
                        component="span"
                        endIcon={<DoneIcon />}
                      >
                        Resume Uploaded
                      </Button>
                    )}
                    {selectedFile !== null && fileConfirmed ? (
                      <Button
                        variant="contained"
                        sx={{
                          width: "fit-content",
                          fontSize: "11px",
                          p: 1,
                        }}
                        onClick={() => {
                          setFileConfirmed(false);
                          setSelectedFile(false);
                          setFileName("");
                          setResumeError(true);
                        }}
                        disabled={!selectedFile}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          width: "fit-content",
                          fontSize: "11px",
                          p: 1,
                        }}
                        onClick={handleUpload}
                        disabled={!selectedFile}
                      >
                        Confirm
                      </Button>
                    )}
                  </Stack>
                  {fileName && (
                    <Typography
                      variant="caption"
                      component={"span"}
                      display="block"
                      mt={1}
                      sx={{ color: "green" }}
                    >
                      {fileName}
                    </Typography>
                  )}
                  {resumeError && (
                    <Typography
                      variant="caption"
                      component={"span"}
                      display="block"
                      mt={1}
                      sx={{ color: "#cd4949" }}
                    >
                      Please upload your resume
                    </Typography>
                  )}
                </Stack>

                <Button
                  variant="contained"
                  sx={{
                    width: "fit-content",
                  }}
                  type="submit"
                  disabled={
                    !formik.values.firstName ||
                    !formik.values.lastName ||
                    !formik.values.phoneNumber ||
                    !formik.values.email ||
                    !(selectedFile && fileConfirmed) ||
                    disableSubmit
                  }
                  onClick={() => {
                    if (!selectedFile || !fileConfirmed) {
                      setResumeError(true);
                    }
                  }}
                >
                  APPLY
                </Button>
              </Stack>
            </form>
          ) : (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                // bgcolor: "#E9E6E6",
                px: 5,
                py: 10,
                width: "70%",
                borderRadius: 2,
              }}
            >
              {applySuccess === "success" ? (
                <Box>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    gap={2}
                    justifyContent={"center"}
                  >
                    <Typography variant={"h4"}>
                      Applicant Added Succefully
                    </Typography>
                    <CheckCircleRoundedIcon
                      sx={{ color: "green", fontSize: "2.5em" }}
                    />
                  </Stack>
                </Box>
              ) : applySuccess === "alreadyExists" ? (
                <Box>
                  <Typography variant={"h4"}>
                    Candidate details are already available.
                  </Typography>
                </Box>
              ) : (
                <Box sx={style}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    gap={1}
                    justifyContent={"center"}
                  >
                    <Typography variant={"h4"}>
                      There was a problem with your application
                    </Typography>
                    <CancelRoundedIcon
                      sx={{ color: "#cd4949", fontSize: "2.5em" }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    gap={1}
                    justifyContent={"center"}
                  >
                    <Typography variant={"h6"}>Please</Typography>
                    {/* <Typography
                      variant="h6"
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                      // onClick={refreshPage}
                    >
                      try again
                    </Typography> */}
                  </Stack>
                  {/* () => navigate(`/job-openings/${_id}`) */}
                  {/* window.location.reload(); */}
                </Box>
              )}
              <Button
                variant="contained"
                sx={{ mt: 10 }}
                onClick={handleClosemodal}
              >
                OK
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default AddApplicantModal;
