import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  Drawer,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Rating,
  Button,
  IconButton,
  Stack,
  Divider,
  Chip,
  Modal,
  Popover,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
const CustomNoRowsOverlay = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "200px",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiTypography-root": {
          fontSize: 18,
        },
      }}
    >
      <Typography>No feedback available</Typography>
    </Box>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // or "scroll" if you always want the scrollbar to appear
};
function InterviewRounds({
  data,
  submitRecruitFeedback,
  setRecruitFeedback,
  recruitFeedback,
}) {
  const [open, setOpen] = React.useState(false);
  const [openViewMode, setOpenViewMode] = React.useState(false);
  const [viewModeData, setviewModeData] = useState({});
  const [average, setAverage] = useState({
    techRating: 0,
    commRating: 0,
    criticalThinkingRating: 0,
    softSkills: 0,
  });
  const handleAddFeedback = () => {
    setRecruitFeedback({});
    setOpen(true);
  };
  const handleOpen = (rowData) => {
    setRecruitFeedback({
      interviewType: rowData.interviewType,
      interviewMode: rowData.interviewMode,
      interviewStatus: rowData.status,
      technicalSkills: rowData.techRating,
      communicationSkills: rowData.commRating,
      criticalThinking: rowData.criticalThinking,
      softSkills: rowData.softSkills,
      feedback: rowData.feedback,
    });
    setOpen(true);
  };
  const handleOpenViewMode = (rowData) => {
    setviewModeData(rowData);
    setOpenViewMode(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseViewMode = () => setOpenViewMode(false);
  const [interviewRounds, setInterviewRounds] = useState(false);

  let avg = "";

  const filteredData = data?.interviewRounds?.map((round) => {
    // Destructure the properties from each interview round object
    const { _id, techRating, commRating, criticalThinkingRating, softSkills } =
      round;

    // Parse the ratings to float
    const parsedTechRating = parseFloat(techRating);
    const parsedCommRating = parseFloat(commRating);
    const parsedCriticalThinkingRating = parseFloat(criticalThinkingRating);
    const parsedSoftSkills = parseFloat(softSkills);

    // Calculate the average rating
    const avg = (
      (parsedTechRating +
        parsedCommRating +
        parsedCriticalThinkingRating +
        parsedSoftSkills) /
      4
    ).toFixed(2);

    // Return the new object with the necessary properties
    return {
      _id,
      avg,
      commRating,
      techRating,
      criticalThinkingRating,
      softSkills,
    };
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event, average) => {
    setAverage({
      techRating: average.techRating,
      commRating: average.commRating,
      criticalThinkingRating: average.criticalThinkingRating,
      softSkills: average.softSkills,
    });

    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  console.log("filterdData", filteredData);

  // console.log(filterdInterviewRoundData);

  const columns = [
    {
      field: "interviewType",
      headerName: "Interview Type",
      width: 150,
      editable: true,
    },
    // {
    //   field: "interviewRound",
    //   headerName: "Interview Round",
    //   width: 150,
    //   editable: true,
    // },
    {
      field: "interviewer",
      headerName: "Interviewer",
      width: 150,
      editable: true,
    },
    {
      field: "interviewMode",
      headerName: "Interview Mode",
      width: 150,
      editable: true,
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 150,
      editable: true,
      renderCell: (params) => {
        const rowData = params.row;
        console.log("rowData", rowData);

        const avg = filteredData.find((e) => e._id === rowData._id);

        console.log(avg);
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{ fontSize: "10px" }}
            >
              <Typography>{avg ? `${avg.avg}/5` : ""}</Typography>
              <InfoOutlinedIcon
                sx={{ fontSize: 14, color: "grey" }}
                onMouseEnter={(e) => handlePopoverOpen(e, avg)}
                onMouseLeave={handlePopoverClose}
              />
            </Stack>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={openPopOver}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Box px={2} width={400} py={2}>
                <Typography variant="h6" mb={2} textAlign={"center"}>
                  Ratings
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography>Technical Skills</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Rating
                      readOnly
                      name="technical-rating"
                      value={average.techRating}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Communication Skills</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Rating
                      readOnly
                      name="communication-rating"
                      value={average.commRating}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Critical Thinking</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Rating
                      name="critical-thinking-rating"
                      value={average?.criticalThinkingRating}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Soft Skills</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Rating
                      name="soft-skills-rating"
                      value={average?.softSkills}
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Box>
            </Popover>
          </Box>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 140,
      editable: true,
    },

    {
      field: "edit",
      headerName: "Actions",

      width: 150,
      renderCell: (params) => (
        <Box>
          <Stack
            direction={"row"}
            // gap={1}
            alignItems={"center"}
            // justifyContent={"center"}
            mt={1}
          >
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleOpenViewMode(params.row)}
            >
              <VisibilityOutlinedIcon sx={{ fontSize: "19px" }} handle />
            </IconButton>
          </Stack>
        </Box>
      ),
    },
  ];
  const getRowId = (row) => row._id;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecruitFeedback((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleRatingChange = (name, value) => {
    setRecruitFeedback((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  console.log({ data });
  return (
    <Box>
      {" "}
      {data?.interviewRounds?.length > 0 ? (
        <DataGrid
          rows={data?.interviewRounds}
          getRowId={getRowId}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      ) : (
        <Typography variant="h6" fontWeight={600} textAlign={"center"} my={5}>
          No Feedback Available
        </Typography>
      )}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} textAlign={"center"}>
          <Stack>
            <Typography
              variant="h6"
              fontWeight={600}
              sx={{ textDecoration: "underline", mb: 2 }}
            >
              Candidate Feedback
            </Typography>
          </Stack>
          <Grid container rowSpacing={5} p={2}>
            <Grid item xs={4}>
              <Typography sx={{ color: "grey" }}>Interview Type</Typography>
              <Typography sx={{ fontWeight: 600 }}>{}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ color: "grey" }}>Interview Mode</Typography>
              <Typography sx={{ fontWeight: 600 }}>Online</Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth required>
                <InputLabel>Interview Status</InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Interview Type"
                  InputLabelProps={{
                    sx: { fontSize: 8 }, // Decrease font size here
                  }}
                  sx={{ textAlign: "left" }}
                  value={recruitFeedback.interviewStatus}
                  onChange={handleChange}
                  name="interviewStatus"
                >
                  <MenuItem value={"On Hold"}>On Hold</MenuItem>
                  <MenuItem value={"Selected"}>Selected</MenuItem>
                  <MenuItem value={"Rejected"}>Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: "grey" }}>Technical Skills</Typography>
              <Rating
                value={recruitFeedback.technicalSkills}
                onChange={(event, newValue) =>
                  handleRatingChange("technicalSkills", newValue)
                }
                sx={{ color: "gold" }}
                precision={0.5}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: "grey" }}>
                Communication skills
              </Typography>
              <Rating
                value={recruitFeedback.communicationSkills}
                onChange={(event, newValue) =>
                  handleRatingChange("communicationSkills", newValue)
                }
                sx={{ color: "gold" }}
                precision={0.5}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: "grey" }}>Critical Thinking</Typography>
              <Rating
                value={recruitFeedback.criticalThinking}
                onChange={(event, newValue) =>
                  handleRatingChange("criticalThinking", newValue)
                }
                sx={{ color: "gold" }}
                precision={0.5}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: "grey" }}>Soft Skills</Typography>
              <Rating
                value={recruitFeedback.softSkills}
                onChange={(event, newValue) =>
                  handleRatingChange("softSkills", newValue)
                }
                sx={{ color: "gold" }}
                precision={0.5}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: "grey" }}>Feedback</Typography>
              <TextField
                id="feedback-text"
                name="feedback"
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                margin="normal"
                value={recruitFeedback.feedback}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={submitRecruitFeedback}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal> */}
      <Modal
        open={openViewMode}
        onClose={handleCloseViewMode}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} textAlign={"center"}>
          <Stack>
            <Typography
              variant="h6"
              fontWeight={600}
              sx={{ textDecoration: "underline", mb: 2 }}
            >
              Candidate Feedback
            </Typography>
          </Stack>
          <Grid container rowSpacing={5} p={2}>
            <Grid item xs={3}>
              <Typography sx={{ color: "grey" }}>Interviewer Name</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {viewModeData.interviewer}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ color: "grey" }}>Interview Type</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {viewModeData.interviewType}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ color: "grey" }}>Interview Mode</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {viewModeData.interviewMode}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ color: "grey" }}>Interview Status</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {viewModeData.status}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: "grey" }}>Technical Skills</Typography>
              <Rating
                sx={{ color: "gold" }}
                name="read-only"
                value={viewModeData.techRating}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: "grey" }}>
                Communication skills
              </Typography>
              <Rating
                sx={{ color: "gold" }}
                name="read-only"
                value={viewModeData.commRating}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: "grey" }}>Critical Thinking</Typography>
              <Rating
                sx={{ color: "gold" }}
                name="read-only"
                value={viewModeData.criticalThinkingRating}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: "grey" }}>Soft Skills</Typography>
              <Rating
                sx={{ color: "gold" }}
                name="read-only"
                value={viewModeData.softSkills}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: "grey" }}>Feedback</Typography>
              <Typography sx={{ textAlign: "justify" }}>
                {viewModeData.feedback}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}

export default InterviewRounds;
