import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  Modal,
  TextField,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Jobs from "../jobOpenings/Jobs";
import JobPublicView from "./JobPublicView";
import JobSearch from "./JobSearch";
import Header from "./Header";
import useSWR from "swr";
import { set } from "lodash";
import axios from "axios";
import { jobPostingBaseUrl } from "../../apiCalls/apiCalls";

const fetcher = (url) => fetch(url).then((res) => res.json());

function JobOpeningsPublicView() {
  // axios.defaults.withCredentia1s = true;
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [inputKeywordValue, setInputKeywordValue] = useState("");
  const [inputLocationValue, setInputLocationValue] = useState("");
  const [allKeyWords, setAllKeyWords] = useState(null);
  const [keywordsLoading, setKeywordsLoading] = useState(true);
  const [jobData, setJobData] = useState(null);
  const [searchValue, setSearchValue] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const response = await axios.get(`${jobPostingBaseUrl}search/options?public=true`);
        setAllKeyWords(response.data);
      } catch (error) {
        console.error("Error fetching keywords:", error);
      } finally {
        setKeywordsLoading(false);
      }
    };

    fetchKeywords();
  }, []);

  const allLocations = allKeyWords?.flatMap((value) => [value.location]);
  const uniqueLocations = [...new Set(allLocations)];

  const allSkillsAndDepartments = allKeyWords?.flatMap((item) => [
    item.department,
    ...item.mandatorySkills,
    ...item.technicalSkills,
    item.position,
    item.employmentType,
    item.experience,
  ]);

  const uniqueSkillsAndDepartments = [...new Set(allSkillsAndDepartments)];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function fetchJobData() {
      setIsLoading(true);
      try {
        let response = "";
        if (searchValue) {
          response = await axios.get(
            `${jobPostingBaseUrl}jobs/search?public=true`,
            {
              params: searchValue,
            }
          );
        } else {
          response = await axios.get(`${jobPostingBaseUrl}jobs?public=true`);
        }

        const openJobs = response.data.filter((job) => job.status === "open");
        setJobData(openJobs);
      } catch (error) {
        console.error("Error fetching job data:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchJobData();
  }, [searchValue]);

  if (keywordsLoading) {
    return (
      <Box sx={{ p: 2 }}>
        <Skeleton sx={{ width: 150, height: 100, pl: 2 }}></Skeleton>
        <Stack justifyContent={"center"}>
          <Stack direction={"row"} gap={2} justifyContent={"center"}>
            <Skeleton width={"30%"} height={80}></Skeleton>
            <Skeleton width={"30%"} height={80}></Skeleton>
            <Skeleton width={"10%"} height={80}></Skeleton>
          </Stack>
          <Stack ml={21} mt={-5}>
            <Skeleton width={"85%"} height={300}></Skeleton>
          </Stack>
        </Stack>
      </Box>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const params = {};

    if (inputKeywordValue) {
      params.keyword = inputKeywordValue;
    }

    if (inputLocationValue) {
      params.location = inputLocationValue;
    }
    setSearchValue(params);
  };

  return (
    <Box sx={{ pb: 2, backgroundColor: "#FCFBFB", minHeight: "100vh" }}>
      <Header />
      <Stack width={"100%"} sx={{ px: 12 }}>
        <Stack direction={"row"} gap={2} alignItems={"center"} ml={6}>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            JOB OPENINGS
          </Typography>
        </Stack>
        <JobSearch
          uniqueSkillsAndDepartments={uniqueSkillsAndDepartments}
          uniqueLocations={uniqueLocations}
          handleSubmit={handleSubmit}
          inputKeywordValue={inputKeywordValue}
          inputLocationValue={inputLocationValue}
          keyword={keyword}
          setInputKeywordValue={setInputKeywordValue}
          setInputLocationValue={setInputLocationValue}
        />
        {isLoading ? (
          <Box>
            <Stack mx={5}>
              <Skeleton width={"100%"} height={300}></Skeleton>
            </Stack>
          </Box>
        ) : jobData?.length > 0 ? (
          <JobPublicView jobData={jobData} />
        ) : (
          <Typography variant="h6" sx={{ margin: "auto", pt: 10 }}>
            No Jobs Found
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

export default JobOpeningsPublicView;
