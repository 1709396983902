import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#121621",
      // light: "#1c1e3c",
      // dark: "#1c1e3c",
      // contrastText: "#fff",
    },
    secondary: {
      main: "#121621",
    },
    text: {
      primary: "#000",
      secondary: "#a8adba",
    },
    // action: {
    //   // active:"",
    //   // hover: "",
    //   selected: "#635bff",
    // },
    // background: {
    //   // default: ""
    // },
    // divider: {},
    // contrastThreshold: 4.5,
    mode: "light",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// const a = [
//   {
//     "candidateName": "Jagadeesh",
//     "candidateDepartment": "FullStack",
//     "interviewRound": "L1",
//     "interviewMode": "Offline",
//     "interviewType": "Technical Interaction",
//     "interviewDate": "17-05-2024",
//     "interviewStartTime": "11:18",
//     "interviewEndTime": "12:18",
//   },
// ];
