import {
  Grid,
  Typography,
  Box,
  Card,
  Drawer,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Rating,
  Button,
  IconButton,
  Stack,
  Divider,
  Chip,
  Modal,
} from "@mui/material";
import useSWR from "swr";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

import { Viewer } from "@react-pdf-viewer/core";

import { useNavigate, useParams } from "react-router-dom";

import Loader from "../../utils/loader";

import axios from "axios";
import { TokenContext } from "../protectedLayout";

import CandidateDetails from "../employeeAccessiblePages/interviewBoard/CandidateDetails";
import InterviewRounds from "../employeeAccessiblePages/interviewBoard/InterviewRounds";
import {
  recruitmentDetailsBaseUrl,
  masterDataBaseUrl,
  scheduleInterviewsBaseUrl,
  viewResumeBaseUrl,
  resumeBaseUrl,
} from "../../apiCalls/apiCalls";

import { ToastContainer, toast } from "react-toastify";
const spanWidth = {
  width: "200px",
};

const fetcher = (url) => fetch(url).then((res) => res.json());
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const constants = {
  loading: "loading",
  jobSeekerDetails: "jobseekerdetails",
  jobSeekerList: "jobseekerlist",
};
const ApplicantDetails = () => {
  // axios.defaults.withCredentials = true;
  const { id } = useParams();
  const token = useContext(TokenContext);
  const { data: masterData, isLoading: jobdescLoading } = useSWR(
    `${masterDataBaseUrl}`,
    fetcher
  );
  const [openfeedbackModal, setOpenfeedbackModal] = useState(false);

  const handlefeedbackModalClose = () => setOpenfeedbackModal(false);
  const navigate = useNavigate();
  const [renderView, setRenderView] = useState(constants.loading);
  const [previewResume, setPreviewResume] = useState(false);
  const [userData, setUserData] = useState([]);
  const [value, setValue] = useState(0);
  const [interviewStatus, setInterviewStatus] = useState("Pending");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleToggleResume = () => {
    setPreviewResume(!previewResume);
  };

  useEffect(() => {
    getJobSeekerData();
  }, []);

  const getJobSeekerData = async () => {
    try {
      // const response = await getJobSeekerDetails(id);
      const response = await axios(`${recruitmentDetailsBaseUrl}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setRenderView(constants.jobSeekerDetails);
      }
      const data = response.data;
      setUserData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  let url = "";
  // console.log("first", userData[0]);
  // function handleDisplayPdf(base64String, fileName) {
  //   if (base64String) {
  //     // Convert Base64 string to Blob
  //     base64String = base64String?.split(",")[1];
  //     // console.log("b64: ", base64String)
  //     const byteCharacters = atob(base64String);
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: "application/pdf" });

  //     url = URL.createObjectURL(blob);

  //     URL.revokeObjectURL(url);
  //   }
  // }
  const candidateId = userData?._id;

  const renderJobSeekerDetailsView = () => {
    const filteredMasterdData = masterData?.filter(
      (e) => e.category === "interviewType"
    );

    console.log("userData", userData);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const candidateData = userData[0];
    console.log({ candidateData });
    return (
      <>
        <Grid
          container
          // sx={{ overflowY: "auto", height: "100vh", p: 1 }}
          spacing={1}
          sx={{ mt: 5, mb: 2 }}
        >
          <Grid item xs={12} md={12}>
            <Stack direction={"row"} gap={2} alignItems={"center"} ml={5}>
              <IconButton>
                <KeyboardBackspaceIcon
                  onClick={() => navigate("/recruitment/recruitment-list")}
                />
              </IconButton>
              <Typography variant="h6" fontWeight={600}>
                Candidate Overview
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={12} mt={3}>
            <Card sx={{ width: "90%", m: "auto" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Candidate Details"
                      sx={{ textTransform: "capitalize" }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Resume"
                      sx={{ textTransform: "capitalize" }}
                      {...a11yProps(2)}
                      // onClick={handleDisplayPdf(candidateData.resume, "resume")}
                    />

                    <Tab
                      label="Interview Feedback"
                      sx={{ textTransform: "capitalize" }}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Stack>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <CandidateDetails data={candidateData} />
              </CustomTabPanel>
              <CustomTabPanel index={1} value={value}>
                <iframe
                  src={`${resumeBaseUrl}/view/${candidateData?.resume?.fileName}`}
                  title="PDF Viewer"
                  style={{ width: "100%", height: "500px" }}
                />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2}>
                <InterviewRounds data={candidateData} />
              </CustomTabPanel>
            </Card>
          </Grid>
        </Grid>

        <ToastContainer />
      </>
    );
  };
  const renderRequiredView = () => {
    switch (renderView) {
      case constants.loading:
        return <Loader />;
      case constants.jobSeekerDetails:
        return renderJobSeekerDetailsView();
      default:
        return null;
    }
  };

  return (
    // <Grid container flexDirection={"row"}>
    //   <Grid item flex={1}>
    //     <SideBar />
    //   </Grid>
    <Grid item flex={8}>
      {renderRequiredView()}
    </Grid>
    // </Grid>
  );
};

export default ApplicantDetails;
