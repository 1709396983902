import React from "react";
import {
  Stack,
  Typography,
  Divider,
  Box,
  Button,
  Chip,
  Grid,
} from "@mui/material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

function JobCard({ job, handleOpenDeleteModal }) {
  const {
    _id,
    position,
    about,
    createdAt,
    department,
    employmentType,
    experience,
    location,
    mandatorySkills,
    numOpenings,
    minExperience,
    maxExperience,
    minSalary,
    maxSalary,
    requirements,
    salary,
    status,
    technicalSkills,
    updatedAt,
  } = job;

  const experienceLevels = {
    ENTRY_LEVEL: "Entry Level",
    MID_SENIOR_LEVEL: `${minExperience}-${maxExperience} Yrs`,
    DIRECTOR: "Director",
    EXECUTIVE: "Executive",
    NOT_APPLICABLE: "N/A",
  };

  const formattedExperience = experienceLevels[experience] || experience;

  const navigate = useNavigate();
  // console.log("JobDescription", JobDescription);
  function calculatePostAge(postedDate) {
    const postedDateUpdated = new Date(postedDate);
    const currentDate = new Date();
    // Set both dates to the same time (midnight)
    postedDateUpdated.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    // Calculating the time difference of two dates
    let timeDiff = currentDate.getTime() - postedDateUpdated.getTime();
    // Calculating the no. of days between two dates
    let daysDiff = Math.abs(Math.round(timeDiff / (1000 * 3600 * 24)));

    if (daysDiff === 0) {
      return "Posted Today";
    } else if (daysDiff === 1) {
      return "Posted 1 day ago";
    } else if (daysDiff > 29) {
      return `Posted ${Math.abs(Math.round(daysDiff / 30))} months ago`;
    } else {
      return `Posted ${daysDiff} days ago`;
    }
  }

  // Format the average salary as "X-Y lacs P.A." string

  const [userAccess, setUserAccess] = useState([]);

  useEffect(() => {
    const userAccessCookie = Cookies.get("access");
    if (userAccessCookie) {
      try {
        const parsedAccess = JSON.parse(userAccessCookie);
        console.log("Parsed access from cookie:", parsedAccess);
        setUserAccess(parsedAccess);
      } catch (error) {
        console.error("Error parsing access cookie:", error);
      }
    }
  }, []);

  const hasAccess = (accessType) =>
    userAccess && userAccess.includes(accessType);

  const getLocationCurrencySymbol = (location) => {
    if (location.toLowerCase() === "usa") {
      return (
        <AttachMoneyOutlinedIcon sx={{ fontSize: "13px", color: "#555b76" }} />
      );
    } else if (location.toLowerCase() === "india") {
      return <CurrencyRupeeIcon sx={{ fontSize: "13px", color: "#555b76" }} />;
    } else {
      return <CurrencyRupeeIcon sx={{ fontSize: "13px", color: "#555b76" }} />;
    }
  };

  const locationCurrencySymbol = getLocationCurrencySymbol(location);

  return (
    <Box
      sx={{
        bgcolor: "white",
        px: 2.5,
        py: 2,
        borderRadius: 1,
        boxShadow: 1,
        // position: "relative",
        ":hover": {
          // bgcolor: "#FAFAFA",
          cursor: "pointer",
          // boxShadow: 2,
          transition: "transform 0.3s ease",
          transform: "scale(1.01)",
        },
      }}
    >
      <Stack onClick={() => navigate(`/recruitment/job-openings/${_id}`)}>
        <Typography variant="subtitle1" component={"h3"}>
          {position}
        </Typography>
        {/* work exp, salary, location */}
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          // width="90%"
          marginTop={0.5}
        >
          {/* work exp */}
          <Grid item xs={1.5}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <WorkOutlineIcon sx={{ fontSize: "13px", color: "#555b76" }} />

              <Typography component="paragraph" sx={{ fontSize: "11px" }}>
                {formattedExperience}
              </Typography>
            </Stack>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "black", opacity: "0.2" }}
          />
          {/* salary */}
          <Grid item xs={salary === "notSpecified" ? 2 : 1.5}>
            {/* <Grid container alignItems="center" spacing={0.5}>
              <Grid item>
                <CurrencyRupeeIcon
                  sx={{ fontSize: "13px", color: "#555b76" }}
                />
              </Grid>
              <Grid item>
                <Typography component="paragraph" sx={{ fontSize: "11px" }}>
                  {salary === "notSpecified"
                    ? "Not Disclosed"
                    : `${minSalary}-${maxSalary} LPA`}
                </Typography>
              </Grid>
            </Grid> */}
            <Stack direction="row" alignItems="center" gap={1}>
              {locationCurrencySymbol}
              <Typography component="paragraph" sx={{ fontSize: "11px" }}>
                {salary === "notSpecified"
                  ? "Not Disclosed"
                  : `${minSalary}-${maxSalary} LPA`}
              </Typography>
            </Stack>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "black", opacity: "0.2" }}
          />
          {/* location */}
          <Grid item xs={4}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <LocationOnOutlinedIcon
                sx={{ fontSize: "13px", color: "#555b76" }}
              />

              <Typography component="paragraph" sx={{ fontSize: "11px" }}>
                {location}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        {/* job desc */}
        <Typography
          component="paragraph"
          sx={{ fontSize: "12px" }}
          width="90%"
          my={1}
          dangerouslySetInnerHTML={{
            __html:
              about.length > 400 ? `${about.substring(0, 400)}....` : about,
          }}
        >
          {/* {about.length > 400 ? about.substring(0, 400) + "...." : about} */}
        </Typography>
        {/* key skills */}
        <Stack direction={"row"} gap={2} my={0.5}>
          {mandatorySkills.map((skill, j) => (
            <Box key={j} sx={{ bgcolor: "#EEEEEE", px: 1, borderRadius: 0.5 }}>
              <Typography component="paragraph" sx={{ fontSize: "11.5px" }}>
                {skill}
              </Typography>
              {/* <Chip
                component="paragraph"
                sx={{ fontSize: "11.5px" }}
                key={nanoid()}
                label={skill}
              ></Chip> */}
            </Box>
          ))}
        </Stack>
        {/* post date, edit and view applicant buttons */}
        <Stack
          direction={"row"}
          gap={3}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={1}
        >
          {/* post date */}
          <Stack direction={"row"} alignItems={"center"} gap={5}>
            <Typography
              component="paragraph"
              sx={{ fontSize: "11px", color: "#555b76" }}
              // width="90%"
              my={1}
            >
              {calculatePostAge(createdAt)}
            </Typography>
            <Typography fontSize="11px">
              <span style={{ color: "#555b76" }}>Status:</span>
              <span
                style={{
                  backgroundColor:
                    status === "open"
                      ? "rgb(240, 255, 248)"
                      : "rgb(254, 250, 224)",
                  color:
                    status === "open" ? "rgb(12, 66, 66)" : "rgb(129, 127, 21)",
                  padding: 5,
                  fontWeight: 600,
                  borderRadius: 2,
                  marginLeft: 1,
                }}
              >
                {status === "open" ? "Open" : "Closed"}
              </span>
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={2}>
            {hasAccess("Orgx_Recent_Openings_JobOpenings_Write") && (
              <Button
                sx={{
                  // position: "absolute",
                  // right: 10,
                  // bottom: 10,
                  // gap: 1,
                  color: "white",
                  bgcolor: "#ED4118",
                  fontSize: "11.5px",
                  ":hover": {
                    color: "white",
                    bgcolor: "#ED4118",
                  },
                }}
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenDeleteModal(_id);
                }}
                startIcon={<DeleteIcon sx={{ fontSize: 10 }} />}
              >
                Delete
              </Button>
            )}

            {hasAccess("Orgx_Recent_Openings_JobOpenings_Write") && (
              <Button
                // sx={{ position: "absolute", right: 10, bottom: 10, gap: 1 }}
                sx={{ fontSize: "11.5px" }}
                startIcon={<EditIcon sx={{ fontSize: 10 }} />}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/recruitment/job-opening-form/${_id}`);
                }}
              >
                Edit
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default JobCard;

// <Stack direction={"row"} width="90%" gap={1} my={0.5}>
// {/* work exp */}
// <Stack
//   direction={"row"}
//   width={experience === "MID_SENIOR_LEVEL" ? "10%" : "12%"}
//   alignItems={"center"}
//   justifyContent={"flex-start"}
//   gap={0.5}
// >
//   <WorkOutlineIcon sx={{ fontSize: "13px", color: "#555b76" }} />
//   <Typography component={"paragraph"} sx={{ fontSize: "11px" }}>
//     {experience === "MID_SENIOR_LEVEL"
//       ? minExperience + "-" + maxExperience + " Yrs"
//       : experience}
//   </Typography>
// </Stack>
// <Divider
//   orientation="vertical"
//   flexItem
//   sx={{ borderColor: "black", opacity: "0.2" }}
// />
// {/* salary */}
// <Stack
//   direction={"row"}
//   width={salary === "notSpecified" ? "12%" : "10%"}
//   alignItems={"center"}
//   justifyContent={"flex-start"}
// >
//   <CurrencyRupeeIcon sx={{ fontSize: "13px", color: "#555b76" }} />
//   <Typography component={"paragraph"} sx={{ fontSize: "11px" }}>
//     {salary === "notSpecified"
//       ? "Not Disclosed"
//       : `${minSalary}-${maxSalary} LPA`}
//   </Typography>
// </Stack>
// <Divider
//   orientation="vertical"
//   flexItem
//   sx={{ borderColor: "black", opacity: "0.2" }}
// />
// {/* location */}
// <Stack
//   direction={"row"}
//   width="40%"
//   alignItems={"center"}
//   justifyContent={"flex-start"}
// >
//   <LocationOnOutlinedIcon
//     sx={{ fontSize: "13px", color: "#555b76" }}
//   />
//   <Typography component={"paragraph"} sx={{ fontSize: "11px" }}>
//     {location}
//   </Typography>
// </Stack>
// </Stack>
