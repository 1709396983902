import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  CandidateInterviewRoundFeedback,
  recruitmentDetailsBaseUrl,
  scheduleInterviewsBaseUrl,
} from "../../../apiCalls/apiCalls";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { TokenContext } from "../../protectedLayout";

function InterviewBoard() {
  axios.defaults.withCredentials = true;
  const [jobSeekersList, setJobSeekersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [candidatesList, setCandidatesList] = useState([]);
  const navigate = useNavigate();
  const interviewerName = "Jyothi";
  const employeeEmail = Cookies.get("userEmail");
  const token = useContext(TokenContext);

  const getJobSeekersList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${CandidateInterviewRoundFeedback}?interviewer=${employeeEmail}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setJobSeekersList(response.data);
      } else {
        console.log("Failed to fetch job seekers list");
      }
    } catch (error) {
      console.error("Error fetching job seekers list:", error);
      console.log("Please check your network connectivity");
    } finally {
      setLoading(false);
    }
  };
  const fetchRecruitmentDetails = async (ids) => {
    const candidateId = ids.map((e) => e.candidateId);

    try {
      const res = await axios.get(
        `${recruitmentDetailsBaseUrl}${candidateId}`,
        { ids },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCandidatesList(res.data);
    } catch (error) {
      console.error("Error fetching recruitment details:", error);
    }
  };
  console.log("first", jobSeekersList);
  useEffect(() => {
    getJobSeekersList();
  }, []);
  function checkInterviewStatus(candidates) {
    if (candidates) {
      return candidates.map((candidate) => {
        const interviewType = candidate?.interviewType;

        const candidateDetails = candidate?.candidateDetails[0];
        const interviewRounds = candidateDetails?.interviewRounds;

        let status = "Pending";

        for (let round of interviewRounds) {
          if (round.interviewType === interviewType) {
            status = "Completed";
            break;
          }
        }

        return {
          ...candidate,
          status: status,
        };
      });
    } else {
      return toast.error("Cannot Load the page");
    }
  }

  const InterviewDetails = checkInterviewStatus(jobSeekersList);
  console.log(InterviewDetails);

  // useEffect(() => {
  //   if (jobSeekersList.length > 0) {
  //     const filteredApplicantDetails = jobSeekersList.filter(
  //       (e) => e.interviewer === "Jyothi"
  //     );
  //     const filterApplicantDataIds = filteredApplicantDetails.map((e) => ({
  //       candidateId: e.candidateId,
  //       id: e._id,
  //     }));

  //     if (filterApplicantDataIds.length > 0) {
  //       fetchRecruitmentDetails(filterApplicantDataIds);
  //     }
  //   }
  // }, [jobSeekersList]);

  return (
    <Box sx={{ p: 5 }}>
      <Stack direction={"row"} gap={2} mb={3}>
        <IconButton
          aria-label="back"
          size="small"
          onClick={() => navigate("/recruitment")}
        >
          <ArrowBackIcon sx={{ fontSize: "20px" }} />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: "700" }}>
          Candidate List
        </Typography>
      </Stack>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F0F0F0" }}>
                <TableCell sx={{ fontWeight: "700" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "700" }}>Role</TableCell>
                <TableCell sx={{ fontWeight: "700" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "700" }}>Status</TableCell>
                <TableCell sx={{ fontWeight: "700" }}>View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {InterviewDetails.length > 0 ? (
                InterviewDetails.flatMap((e) =>
                  e.candidateDetails.map((value) => (
                    <TableRow key={value._id}>
                      <TableCell>{value.firstName}</TableCell>
                      <TableCell>{value.role}</TableCell>
                      <TableCell>{value.email}</TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            // textAlign: "center",
                            fontWeight: 600,
                            borderRadius: 2,
                            // bgcolor:
                            //   e.status != "Completed"
                            //     ? "rgb(254, 250, 224)"
                            //     : "rgb(98, 255, 231)",
                            color: e.status != "Completed" ? "red" : "green",
                          }}
                        >
                          {" "}
                          {e.status}
                        </Typography>
                      </TableCell>
                      <TableCell
                        onClick={() => navigate(`/interview-status/${e._id}`)}
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                      >
                        View Details
                      </TableCell>
                    </TableRow>
                  ))
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                    No Applicants Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default InterviewBoard;
