import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableContainer,
  TableBody,
  TextField,
  IconButton,
  Alert,
  CircularProgress,
  Tooltip,
  FormHelperText,
} from "@mui/material";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ical from "ical-generator";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { ToastContainer, toast } from "react-toastify";

import {
  fetchAllEmployeeDetials,
  masterDataBaseUrl,
  recruitmentDetailsBaseUrl,
  scheduleInterviewsBaseUrl,
} from "../../apiCalls/apiCalls";
import { TokenContext } from "../protectedLayout";
import { SlCalender } from "react-icons/sl";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Kolkata");

const JobSeekersList = () => {
  axios.defaults.withCredentials = true;
  const token = useContext(TokenContext);
  const navigate = useNavigate();
  const [jobSeekersList, setJobSeekersList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [modalState, setModalState] = useState(true);

  const getJobSeekersList = async () => {
    try {
      // const response = await getRecruitmentDetails();
      const response = await axios.get(`${recruitmentDetailsBaseUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.data;
      // console.log("data", data);
      setIsLoading(true);
      if (response.status === 200) {
        // setRenderThisTab(constants.jobSeekerList);
        setJobSeekersList(data);
        setIsLoading(false);
      }
    } catch (error) {
      return (
        <Alert variant="outlined" severity="warning">
          Please check your network connectivity
        </Alert>
      );
    }
  };
  useEffect(() => {
    getJobSeekersList();
  }, []);

  useEffect(() => {
    if (!modalState) {
      toast.success("Interview got scheduled successfully");
    }
  }, [modalState]);

  const renderJobSeekerList = () => (
    <>
      <Grid item flex={8} px={3}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Grid item xs={4}>
            <Stack direction={"row"} alignItems={"center"} mb={3}>
              <IconButton onClick={() => navigate("/recruitment")}>
                <KeyboardBackspaceIcon sx={{ cursor: "pointer" }} />
              </IconButton>
              <Typography
                variant="h5"
                // gutterBottom
                sx={{
                  fontWeight: "bold",
                  // textAlign: "center",
                  // marginBottom: "15px",
                }}
              >
                Candidates List
              </Typography>
            </Stack>
          </Grid>
          {/* <Grid item xs={4}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              // textAlign: "center",
              marginBottom: "15px",
            }}
          >
            Candidates List
          </Typography>
        </Grid> */}
        </Box>
        <TableContainer component={Paper}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-head": {
                    backgroundColor: "#26262630",
                    backdropFilter: "blur(100px)",
                    fontWeight: "600",
                  },
                }}
              >
                <TableCell>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                jobSeekersList.length > 0 ? (
                  jobSeekersList?.map((row, index) => (
                    <TableRow
                      key={row._id}
                      scope="row"
                      component="th"
                      sx={{
                        outline: "none",
                      }}
                    >
                      <TableCell sx={{ fontSize: "16px", height: "60px" }}>
                        <Typography variant="p">{row.firstName}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="p">{row.role}</Typography>
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "16px" }}>
                        {row.email}
                      </TableCell>

                      <TableCell
                      // align="left"
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            padding: 5,
                            borderRadius: 2,
                            backgroundColor:
                              row.interviewRounds.length > 0
                                ? row.interviewRounds[
                                    row.interviewRounds.length - 1
                                  ].status === "Selected"
                                  ? "rgb(240, 255, 248)" // Background color for "Selected"
                                  : "rgb(254, 250, 224)" // Background color for other statuses
                                : "rgb(254, 250, 224)", // Default background color if no interview rounds
                            color:
                              row.interviewRounds.length > 0
                                ? row.interviewRounds[
                                    row.interviewRounds.length - 1
                                  ].status === "Selected"
                                  ? "rgb(12, 66, 66)"
                                  : "rgb(129, 127, 21)"
                                : "rgb(129, 127, 21)",
                          }}
                        >
                          {row.interviewRounds.length > 0
                            ? row.interviewRounds[
                                row.interviewRounds.length - 1
                              ].status
                            : "Pending for Interview"}
                        </span>
                      </TableCell>

                      <TableCell align="left" sx={{ fontSize: "16px" }}>
                        <Stack direction={"row"} alignItems={"center"}>
                          <BasicModal
                            cname={row.firstName}
                            crole={row.role}
                            cid={row._id}
                            cemail={row.email}
                            setModalState={setModalState}
                          />
                          <Tooltip title="View Details">
                            <IconButton
                              onClick={() =>
                                navigate(`/applicant-details/${row._id}`)
                              }
                            >
                              <RemoveRedEyeOutlinedIcon
                                sx={{ fontSize: 20, color: "grey" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      rowSpan={2}
                      align="center"
                      sx={{ fontSize: 20, fontWeight: 300 }}
                    >
                      No Appilicants
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "50vh",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <ToastContainer autoClose={1500} />
    </>
  );

  return (
    <Grid item flex={8}>
      {renderJobSeekerList()}
    </Grid>
    // </Grid>
  );
};

//  -----------------------------------------------
//                       Modal
//  -----------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  maxHeight: 550,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflow: "auto",
};

function BasicModal({ cname, crole, cid, cemail }) {
  const token = useContext(TokenContext);
  axios.defaults.withCredentials = true;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  // Form Data Object State
  const [totalFormData, setTotalFormData] = useState({
    candidateId: cid,
    candidateName: cname,
    candidateDepartment: crole,
    interviewRound: "",
    interviewMode: "",
    interviewType: "",
    interviewDate: "",
    interviewStartTime: "",
    interviewEndTime: "",
    interviewer: "",
    interviewerEmail: "",
    candidateEmail: cemail,
    file: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [btnState, setBtnState] = useState(true);

  // form select
  const [option, setOption] = useState({
    round: "",
    employee: "",
  });
  // menuItems for InterviewRounds
  const [, setInterviewRounds] = useState([]);
  // menuItems for Employee
  const [employeesData, setEmployeesData] = useState([]);

  // validation
  const [formErrors, setFormErrors] = useState({
    interviewRound: "",
    interviewMode: "",
    interviewType: "",
    interviewDate: "",
    interviewStartTime: "",
    interviewEndTime: "",
    interviewer: "",
  });
  const validate = (values) => {
    const errors = {};
    // const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const emojiRegex =
      /[\u{1F600}-\u{1F6FF}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{FE00}-\u{FE0F}\u{1F900}-\u{1F9FF}]/u;
    // const leadingTrailingSpacesRegex = /^\s+|\s+$/g;

    const validateTextField = (fieldName, value) => {
      if (!value) {
        errors[fieldName] = "* Field is required";
      } else if (
        specialCharsRegex.test(value) ||
        emojiRegex.test(value)
        // || leadingTrailingSpacesRegex.test(value)
      ) {
        errors[fieldName] =
          // "Field should not contain special characters, emojis, or leading/trailing spaces";
          "Field should not contain special characters, emojis";
      }
    };

    validateTextField("interviewRound", values.interviewRound);
    validateTextField("interviewType", values.interviewType);

    if (!values.interviewMode) {
      errors.interviewMode = "* Field is required";
    }
    if (!values.interviewDate) {
      errors.interviewDate = "* Field is required";
    }
    if (!values.interviewStartTime) {
      errors.interviewStartTime = "* Field is required";
    }
    if (!values.interviewEndTime) {
      errors.interviewEndTime = "* Field is required";
    }
    if (!values.interviewer) {
      errors.interviewer = "* Field is required";
    }

    return errors;
  };

  const handleChange = (event, name) => {
    setBtnState(false);
    setOption({ ...option, [name]: event.target.value });
    // Form Data
    setTotalFormData({ ...totalFormData, [name]: event.target.value });
  };

  useEffect(() => {
    // console.log(formErrors);
    if (isSubmit) {
      setFormErrors(validate(totalFormData));
    }
  }, [totalFormData, isSubmit]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmit(true);

    if (Object.entries(validate(totalFormData)).length === 0) {
      try {
        const cal = ical({ name: "Call Scheduling" });
        const cal2 = ical({ name: "Call Scheduling" });

        // Create the event
        cal.createEvent({
          start:
            totalFormData.interviewDate.toDate().toString().slice(0, 16) +
            totalFormData.interviewStartTime.toDate().toString().slice(16),
          end:
            totalFormData.interviewDate.toDate().toString().slice(0, 16) +
            totalFormData.interviewEndTime.toDate().toString().slice(16),
          summary: "You are scheduled to take an Interview for a Candidate", // Event title from form
          description: "Scheduled Interview", // Event description from form
        });

        cal2.createEvent({
          start:
            totalFormData.interviewDate.toDate().toString().slice(0, 16) +
            totalFormData.interviewStartTime.toDate().toString().slice(16), // Use the formatted start date
          end:
            totalFormData.interviewDate.toDate().toString().slice(0, 16) +
            totalFormData.interviewEndTime.toDate().toString().slice(16), // Use the formatted end date
          summary: "You are scheduled to attend an Interview with Aapmor", // Event title from form
          description: "Scheduled Interview", // Event description from form
        });

        const currentInterviewerEmail = employeesData.filter(
          (emp) => emp.fullName === totalFormData.interviewer
        )[0].employeeEmail;

        const response = await axios.post(
          scheduleInterviewsBaseUrl,

          {
            ...totalFormData,
            interviewerEmail: currentInterviewerEmail,
            file: cal.toString(),
            candidateFile: cal2.toString(),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }

          // {
          //   candidateId: cid,
          //   candidateName: "Jagadeesh",
          //   candidateDepartment: "FullStack",
          //   interviewRound: "L1",
          //   interviewMode: "Offline",
          //   interviewType: "Technical Interaction",
          //   interviewDate: "23-05-2023",
          //   interviewStartTime: "11:18",
          //   interviewEndTime: "12:18",
          //   interviewerEmail: "testdavose@gmail.com",
          //   candidateEmail: "srinivasch@aapmor.com",
          //   employeeId: "E0009",
          //   interviewer: "testDavose",
          //   file: cal.toString(),
          //   candidateFile: cal2.toString(),
          // }
        );
        if (response.status === 201) {
          toast.success("Interview Got Scheduled Successfully", {
            onClose: () => handleClose(),
          });
        }
        setBtnState(true);
        const res = await axios.post(
          scheduleInterviewsBaseUrl + "sendEmail",
          {
            ...totalFormData,
            interviewerEmail: currentInterviewerEmail,
            file: cal.toString(),
            candidateFile: cal2.toString(),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.log(`Error Occured while scheduling an interview`, error);
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    async function fetchInterviewRounds() {
      try {
        const response = await axios.get(masterDataBaseUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedInterviewRounds = response.data.filter(
          (e) => e.category === "interviewType"
        );
        setInterviewRounds(fetchedInterviewRounds);
      } catch (error) {
        console.log(`Error at fetching Interview Rounds`, error.message);
      }
    }
    async function fetchEmployeeDetails() {
      try {
        const response = await axios.get(fetchAllEmployeeDetials, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(`employeeDetails`, response.data.data);

        const totalEmployeesData = response.data.data.filter(
          (e) => e.department === "Fullstack"
        );
        // console.log(`Filtered Employees Data`, totalEmployeesData);
        setEmployeesData(totalEmployeesData);
      } catch (error) {
        console.log(`Error at fetching Employee Rounds`, error.message);
      }
    }

    fetchEmployeeDetails();
    fetchInterviewRounds();
  }, []);

  return (
    <div>
      <Tooltip title="Schedule Interview">
        <IconButton onClick={handleOpen} sx={{ fontSize: 15 }}>
          <SlCalender />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={style}>
              <Grid container rowGap={3.5}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      mb: 3,
                      textAlign: "center",
                      textDecoration: "underline",
                      textUnderlineOffset: "10px",
                    }}
                    id="modal-modal-title"
                    variant="h5"
                    component="h1"
                    color={"primary"}
                  >
                    Schedule An Interview
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Candidate Name</Typography>
                  <Typography>{cname}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography id="modal-modal-description">
                    Department
                  </Typography>
                  <Typography id="modal-modal-description">{crole}</Typography>
                </Grid>
                {/* Interview Mode */}
                <Grid item xs={4}>
                  <FormControl
                    style={{
                      width: "200px",
                      marginTop: "20px",
                    }}
                    error={formErrors.interviewMode ? true : false}
                  >
                    <InputLabel id="intervie-mode">Interview Mode</InputLabel>
                    <Select
                      labelId="interview-mode"
                      id="intervie-mode-1"
                      label="Interview Mode"
                      name="interviewMode"
                      value={totalFormData.interviewMode}
                      onChange={(e) => {
                        handleChange(e, "interviewMode");
                      }}
                    >
                      <MenuItem value="Online">Online</MenuItem>
                      <MenuItem value="Offline">Offline</MenuItem>
                    </Select>
                    <FormHelperText>{formErrors.interviewMode}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Interview Round */}
                <Grid item xs={4}>
                  <TextField
                    style={{
                      width: "200px",
                      marginTop: "20px",
                    }}
                    label="Interview-Round"
                    name="interviewRound"
                    helperText={formErrors.interviewRound}
                    FormHelperTextProps={{ style: { color: "red" } }}
                    onChange={(e) => {
                      handleChange(e, "interviewRound");
                    }}
                    error={formErrors.interviewRound ? true : false}
                  ></TextField>
                </Grid>
                {/* Interview Type */}
                <Grid item xs={4}>
                  <TextField
                    style={{
                      width: "200px",
                      marginTop: "20px",
                    }}
                    label="Interview-Type"
                    helperText={formErrors.interviewType}
                    FormHelperTextProps={{ style: { color: "red" } }}
                    name="interviewType"
                    onChange={(e) => {
                      handleChange(e, "interviewType");
                    }}
                    error={formErrors.interviewType ? true : false}
                  ></TextField>
                </Grid>
                {/* Interviewer */}
                <Grid item xs={4}>
                  <FormControl
                    style={{
                      width: "200px",
                      marginTop: "20px",
                    }}
                    error={formErrors.interviewer ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label-2">
                      Interviewer
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-2"
                      id="demo-simple-select-2"
                      value={totalFormData.interviewer}
                      label="Interviewer"
                      name="interviewer"
                      onChange={(e) => {
                        handleChange(e, "interviewer");
                      }}
                    >
                      {employeesData?.map((emp) => (
                        <MenuItem key={emp.email} value={emp.fullName}>
                          {emp.fullName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{formErrors.interviewer}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* date time pickers */}
                <Grid item xs={4}>
                  <DemoContainer
                    sx={{ maxWidth: 200 }}
                    components={[
                      "DatePicker",
                      "MobileDatePicker",
                      "DesktopDatePicker",
                      "StaticDatePicker",
                    ]}
                  >
                    {/* <DemoItem> */}
                    <DatePicker
                      // orientation="portrait"
                      popperPlacement="top-left"
                      minDate={dayjs()}
                      onChange={(newValue) => {
                        setTotalFormData({
                          ...totalFormData,
                          interviewDate: newValue,
                        });
                      }}
                      slotProps={{
                        textField: {
                          helperText: formErrors.interviewDate,
                        },
                      }}
                    />
                    {/* </DemoItem> */}
                  </DemoContainer>
                </Grid>
                <Grid item xs={4}>
                  <DemoContainer
                    sx={{
                      maxWidth: 200,
                    }}
                    components={["TimePicker"]}
                  >
                    <TimePicker
                      label="Start Time"
                      onChange={(newValue) => {
                        setTotalFormData({
                          ...totalFormData,
                          interviewStartTime: newValue,
                        });
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          helperText: formErrors.interviewStartTime,
                        },
                      }}
                    />
                    {/* {formErrors.interviewStartTime && (
                      <FormHelperText error>
                        {formErrors.interviewStartTime}
                      </FormHelperText>
                    )} */}
                  </DemoContainer>
                </Grid>
                <Grid item xs={4}>
                  <DemoContainer
                    sx={{ maxWidth: 200 }}
                    components={["TimePicker"]}
                  >
                    <TimePicker
                      label="End Time"
                      onChange={(newValue) => {
                        setTotalFormData({
                          ...totalFormData,
                          interviewEndTime: newValue,
                        });
                      }}
                      slotProps={{
                        textField: {
                          helperText: formErrors.interviewEndTime,
                        },
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />
                  </DemoContainer>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    sx={{ mb: 4 }}
                  >
                    <Button
                      sx={{
                        mt: 4,
                      }}
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        mt: 4,
                      }}
                      type="submit"
                      variant="contained"
                      disabled={btnState}
                    >
                      Schedule
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </LocalizationProvider>
        </form>
      </Modal>
    </div>
  );
}

export default JobSeekersList;
